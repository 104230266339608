import React, { Suspense, lazy, useState } from 'react';
const QrCode = lazy(() => import("./qrCode"))
const CustomRecharge = lazy(() => import("./customRecharge"))
const SendNotification = lazy(() => import("./sendNotification"))
const CardInfo = lazy(() => import("./cardInfo"))
const PlanInfo = lazy(() => import("./planInfo"))

function Index({ decodedId, edit = false }) {

    const [currentTab, setCurrentTab] = useState('Card Info')

    const header = edit ? ["Card Info", 'plan Info', 'Custom Recharge', 'Send Notification'] : ["Card Info", 'plan Info']

    function getTabComponent(tabName) {
        switch (tabName) {
            case 'Card Info':
                return <CardInfo decodedId={decodedId} />;
            case 'plan Info':
                return <PlanInfo decodedId={decodedId} isEdit={edit} />;
            case 'QrCode':
                return <QrCode decodedId={decodedId} />;
            case 'Custom Recharge':
                return <CustomRecharge decodedId={decodedId} edit={edit} />;
            case 'Send Notification':
                return <SendNotification />;
            default:
                return <div>Select a tab</div>;
        }
    }

    return (
        <div className='w-full bg-white text-black -mt-4'>
            <div className="m-5">

                <div className="flex gap-10 me-5 py-5">
                    {header.map((item, index) => (
                        <div key={index} className={`cursor-pointer text-lg ${currentTab === item ? 'text-brandRed border-b-2 border-b-brandRed font-bold' : 'text-gray-500'}`} onClick={() => setCurrentTab(item)}>{item}</div>
                    ))}
                </div>
                <div className="h-[1px] bg-gray-500 w-full my-5"></div>
                <Suspense fallback={<div>Loading...</div>}>
                    {getTabComponent(currentTab)}
                </Suspense>
            </div>
        </div>
    )
}

export default React.memo(Index)