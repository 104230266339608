
import { auth } from "apis";

export const sendNotifications = async (data) => {
    const res = await auth({
        method: "POST",
        url: `/notifications`,
        data,
    });
    return res;
}
