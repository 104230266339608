import { auth } from "apis";

export const getAppUpdateVersion = async () => {
    const res = await auth({
        method: "GET",
        url: `/general/version`,
    });
    return res;
}

export const updateAppVersion = async (data) => {
    const res = await auth({
        method: "POST",
        url: `/general/version`,
        data
    });
    return res;
}