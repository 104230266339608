import React, { useEffect, useState } from 'react'

import { getUserProfileDetails, updateUserProfileDetails } from 'apis/NEW/users'
import DietAttributes from "./dietAttributes"
import EducationDetails from "./educationDetails"
import FamilyDetails from "./familyDetails"
import GroomsLocation from "./groomsLocation"
import LanguageAndIntrusts from "./languageAndIntrusts"
import PhysicalDetails from "./physicalDetails"
import { toast } from 'react-toastify'

function Index({ decodedId, readOnly = true, submit = false, edit = false }) {

    const [userData, setUserData] = useState()
    const [personalData, setPersonalData] = useState({});
    const [miscData, setMiscData] = useState({});
    const [addressData, setAddressData] = useState({});
    const [familyData, setFamilyData] = useState({});
    const [languagesKnownData, setLanguagesKnownData] = useState({});
    const [completionStatus, setCompletionStatus] = useState({
        personal: false,
        misc: false,
        address: false,
        family: false,
        languagesKnown: false,
    });

    const handleOnSubmit = (value) => {
        console.log('value', value)
        if (value.height || value.education) {
            setPersonalData(prev => ({ ...prev, ...value }));
            setCompletionStatus(prev => ({ ...prev, personal: true }));
        } else if (value.drinking) {
            setMiscData(value);
            setCompletionStatus(prev => ({ ...prev, misc: true }));
        } else if (value.country) {
            setAddressData(value);
            setCompletionStatus(prev => ({ ...prev, address: true }));
        } else if (value.parent_name) {
            setFamilyData(value);
            setCompletionStatus(prev => ({ ...prev, family: true }));
        } else if (value.mother_tongue) {
            setLanguagesKnownData(value);
            setCompletionStatus(prev => ({ ...prev, languagesKnown: true }));
        }
    };

    useEffect(() => {
        const { personal, misc, address, family, languagesKnown } = completionStatus;
        if(personal || misc || family || address || languagesKnown){
            console.log('==== triggered inside if ===')
            const mergedData = {
                personal: personalData,
                misc: miscData,
                address: addressData,
                family: familyData,
                languages_known: languagesKnownData,
            };
            handleUpdateUserProfileDetails(mergedData);
        }else{
            console.log('=== else case ===')
        }
    }, [completionStatus, personalData, miscData, addressData, familyData, languagesKnownData]);

    const handleUpdateUserProfileDetails = async (mergedData) => {
        console.log('=====update user profile=====')
        const res = await updateUserProfileDetails(decodedId, mergedData)
        if (res?.success) {
            toast.success("updated Successfully")
        }
    }

    const fetchUserProfileDetails = async () => {
        const res = await getUserProfileDetails(decodedId)
        setUserData(res?.data[0])
    }

    useEffect(() => {
        fetchUserProfileDetails()
    }, [])


    return (
        <>
            {userData &&

                <>
                    <div className="flex justify-between">
                        <div className="w-[48%]">
                            <PhysicalDetails data={userData?.personal} readOnly={readOnly} handleOnSubmit={handleOnSubmit} submit={submit} edit={edit} />
                        </div>
                        <div className="w-[48%]">
                            <DietAttributes data={userData?.misc} readOnly={readOnly} handleOnSubmit={handleOnSubmit} submit={submit} edit={edit} />
                        </div>
                    </div>
                    <div className="flex justify-between my-4">
                        <div className="w-[48%]">
                            <GroomsLocation data={userData?.address} readOnly={readOnly} handleOnSubmit={handleOnSubmit} submit={submit} edit={edit} />
                        </div>
                        <div className="w-[48%]">
                            <EducationDetails data={userData?.personal} readOnly={readOnly} handleOnSubmit={handleOnSubmit} submit={submit} />
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div className="w-[48%]">
                            <FamilyDetails data={userData?.family} readOnly={readOnly} handleOnSubmit={handleOnSubmit} submit={submit} edit={edit} />
                        </div>
                        <div className="w-[48%]">
                            <LanguageAndIntrusts data={userData?.languages_known} readOnly={readOnly} handleOnSubmit={handleOnSubmit} submit={submit} edit={edit} />
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default React.memo(Index)