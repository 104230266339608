import React, { useState, useEffect } from 'react'
import { InputField, DropDown } from "components"
import { relationWithParent } from '../../../../../../shared/constants'

function Index({ data, readOnly = true, handleOnSubmit, submit = false, edit = false }) {

    const [userData, setUserData] = useState({
        parent_name: data?.parent_name,
        relationship: data?.relationship,
        contact_number: data?.contact_number,
        // contact_mode: data?.contact_mode,
    })

    const content = [
        { label: "Parent Name", value: 'parent_name', isDropdown: false },
        { label: "RelationShip", value: 'relationship', isDropdown: true, options: relationWithParent },
        // { label: "Contact", value: 'contact_mode', isDropdown: true, options: contactModeWithParent },
        { label: "Contact Number", value: 'contact_number', isDropdown: false },
    ]

    const updateState = (key, value) => {
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (submit) {
            handleOnSubmit(userData)
        }
    }, [submit])

    return (
        <div className='bg-white text-black -mt-4'>
            <div className="m-5">
                <h1 className='font-semibold text-xl pt-5'>Family Details</h1>
                <p className='text-gray-500 text-base'>Add time  that highlight what makes it unique <br />Event Time</p>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-[320px]">
                        {content.map((item, index) => {
                            const useDropdown = edit && item.isDropdown
                            return (
                                <div key={index} className="flex items-center justify-between my-4">
                                    <h1 className='font-semibold text-lg'>{item.label}</h1>
                                    <div className={useDropdown ? "h-[56px] w-[70%] flex flex-col justify-center rounded-3xl my-2" : "w-[70%]"}>
                                        {useDropdown ? (
                                            <DropDown
                                                selectedValue={userData[item.value]}
                                                placeholderValue={""}
                                                options={item.options}
                                                readOnly={readOnly}
                                                handleOnChange={(value) => updateState(item.value, value)}
                                            />
                                        ) : (
                                            <InputField
                                                readOnly={readOnly}
                                                value={userData[item.value]}
                                                onChange={(value) => updateState(item.value, value)}
                                                type={item.type}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)