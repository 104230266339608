
import React, { useEffect, useState } from "react";
import AdvancedImageCropping from 'components/NEW/image/imageCropping'

import { toast } from "react-toastify";
import { uploadImage, deleteImage } from "apis/NEW/users";

function Index({ imageData, decodedId, handleImageUploadAndDeleteSuccess }) {
    const [images, setImages] = useState(
        Array.from({ length: 6 }, (_, index) => ({ imageId: `image-${index}`, url: '' }))
    );

    const [isCropping, setIsCropping] = useState(false);
    const [firstImg, setFirstImg] = useState(false);
    const [currentImageForCropping, setCurrentImageForCropping] = useState(null);


    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const fileReader = new FileReader();
            fileReader.onload = function (e) {
                setCurrentImageForCropping(e.target.result);
                setIsCropping(true);
            };
            fileReader.readAsDataURL(event.target.files[0]);
        }
    };

    const onCropComplete = async (croppedFile) => {
        try {
            const toastId = toast.loading("Uploading Image Please wait")
            let res
            if (firstImg) {
                res = await uploadImage(decodedId, { index: 1, aspect_ratio: 16 / 9, file: croppedFile })
            } else {
                const index = images.findIndex(img => img.url === '');
                res = await uploadImage(decodedId, { index: index + 1, aspect_ratio: 16 / 9, file: croppedFile })
            }
            if (res?.success) {
                handleImageUploadAndDeleteSuccess()
                toast.success("Uploaded image successfully")
            }
            setIsCropping(false);
            toast.dismiss(toastId)
        } catch (error) {
            toast.error('Something went wrong while uploading Image')
        }
    };

    const handleStateUpdate = async () => {
        try {
            const defaultImages = Array.from({ length: 6 }, (_, index) => ({
                imageId: `image-${index}`,
                url: ''
            }));
            const updatedImages = defaultImages.map((img, Index) => {
                if (Index < imageData?.length) {
                    return {
                        imageId: `image-${imageData[Index]?.index}/${imageData[Index]?._id}`,
                        url: imageData[Index]?.url
                    };
                }
                return img;
            });
            setImages(updatedImages);

        } catch (error) {
            toast.error('Something went wrong')
        }
    };



    const handleRemoveImage = async (id) => {
        try {
            const toastId = toast.loading("Deleting Image please wait")
            const extractedIndex = id.split('/')[0].split('-')[1];
            const index = parseInt(extractedIndex, 10);
            const res = await deleteImage(decodedId, index)
            if (res?.success) {
                handleImageUploadAndDeleteSuccess()
            }
            // await handleStateUpdate()
            toast.dismiss(toastId)
            if (res?.success) {
                toast.success("Deleted Image Successfully")
            }
        } catch (error) {
            toast.error('Something went wrong while deleting Image')
        }
    };

    useEffect(() => {
        handleStateUpdate()
    }, [imageData])

    return (
        <>
            {isCropping && (
                <div className='absolute top-0 left-0 z-50 flex flex-col items-center justify-center w-full h-full '>
                    <div className="w-full max-w-lg p-4 bg-white rounded-lg">
                        <AdvancedImageCropping
                            image={currentImageForCropping}
                            setPopup={setIsCropping}
                            onCropComplete={onCropComplete}
                            circularCrop={false}
                            ratio={4 / 5}
                        />
                    </div>
                </div>
            )}

            <div className="flex flex-col bg-white">

                <div className="relative flex flex-col items-center justify-center flex-1 w-full h-full mx-auto bg-white text-brandRed ">

                    <div className="w-full mx-auto mb-5 lg:mb-0">

                        <div className="grid grid-cols-3 gap-4 mt-4">
                            {images.map((imgPreview, index) => (
                                <div key={imgPreview.imageId} className="relative flex flex-col items-center justify-center border border-gray-200" style={{ width: '112px', height: '112px' }}>
                                    {imgPreview.url ? (
                                        <>
                                            <img src={imgPreview.url} alt={`preview ${index + 1}`} className="max-w-[112px] max-h-[112px] outline-none border-none" />
                                            {index + 1 === 1 && (
                                                <button onClick={() => document.getElementById(`input-${imgPreview.imageId}`).click()} className="absolute bottom-[-12px] right-[-12px] w-6 h-6 bg-white rounded-full" style={{ width: '25px', height: '25px' }}>
                                                    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.619141" y="0.601562" width="33.7854" height="33.7854" rx="16.8927" fill="#F83758" />
                                                        <path d="M9.5 9.5L25.5 25.5M9.5 25.5L25.5 9.5" stroke="white" strokeWidth="2.25236" strokeLinecap="round" />
                                                    </svg>
                                                </button>
                                            )}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    handleFileChange(e, imgPreview.imageId)
                                                    setFirstImg(true)
                                                }}
                                                id={`input-${imgPreview.imageId}`}
                                                className="hidden"
                                            />

                                            {index + 1 !== 1 && (
                                                <button onClick={() => handleRemoveImage(imgPreview.imageId)} className="absolute bottom-[-12px] right-[-12px] w-6 h-6 bg-white rounded-full" style={{ width: '25px', height: '25px' }}>
                                                    <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="0.619141" y="0.601562" width="33.7854" height="33.7854" rx="16.8927" fill="#F83758" />
                                                        <path d="M9.5 9.5L25.5 25.5M9.5 25.5L25.5 9.5" stroke="white" strokeWidth="2.25236" strokeLinecap="round" />
                                                    </svg>
                                                </button>

                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={(e) => handleFileChange(e, imgPreview.imageId)}
                                                id={imgPreview.imageId}
                                                className="hidden mb-2"
                                            />
                                            <label htmlFor={imgPreview.imageId} className="text-sm text-gray-500 cursor-pointer">Choose an image</label>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div >
        </>

    );
}

export default Index;