/**
 * Encodes data to a Base64 string.
 * @param {string} data - The data to encode.
 * @returns {string} The Base64 encoded string.
 */
export const encodeToBase64 = (data) => {
    try {
        // Convert the data to a string and then to a Base64 string
        return btoa(unescape(encodeURIComponent(data)));
    } catch (e) {
        console.error('Error encoding to Base64:', e);
        return null;
    }
}

/**
 * Decodes data from a Base64 string.
 * @param {string} base64 - The Base64 string to decode.
 * @returns {string} The decoded data.
 */
export const decodeFromBase64 = (base64) => {
    try {
        // Convert from Base64 string to a decoded string
        return decodeURIComponent(escape(atob(base64)));
    } catch (e) {
        console.error('Error decoding from Base64:', e);
        return null;
    }
}
