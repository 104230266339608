import { createNewPlan, getAllPlans, updatePlan } from 'apis/NEW/plans'
import { ManagementSecMainHeader, MainNavbar, MainSidebar, TableWithoutPagination } from 'components'
import React, { Suspense, lazy, useEffect, useState } from 'react'
import { toast } from 'react-toastify'


const CreateAndEditPlan = lazy(() => import("components").then(module => ({ default: module.CreateAndEditPlan })))


function Index() {


    const [plans, setPlans] = useState()
    const [selectedPlanData, setSelectedPlanData] = useState()
    const [showAddAndEditPlan, setShowAddAndEditPlan] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const fetchAllPlans = async () => {
        const res = await getAllPlans()
        setPlans(res?.data)
    }

    useEffect(() => {
        fetchAllPlans()
    }, [])

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Plan Name', accessor: 'name', maxWidth: 100 },

        { Header: 'Duration', accessor: (row) => `${row.duration} months` },
        { Header: 'Amount', accessor: 'amount' },
        { Header: 'Coins', accessor: 'coins' },
        { Header: 'Plan Category', accessor: (row) => row.category ? row.category.name : '', },

        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <span className={`text-white p-2 rounded-3xl ${value ? 'bg-green-500' : 'bg-brandRed'}`}>
                    {value ? 'active' : 'inactive'}
                </span>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                </div>
            )
        }

    ];


    const handleEdit = (row) => {
        setSelectedPlanData(row)
        setShowAddAndEditPlan(true)
        setIsEdit(true)
    }

    const handleSubmit = async (value) => {
        const { id, ...data } = value
        let res
        if (isEdit) {
            res = await updatePlan(id, data)
        } else {
            res = await createNewPlan(data)
        }
        if (res?.success) {
            toast(`${isEdit ? 'updated' : 'Created'} Event Plan`)
            setShowAddAndEditPlan(false)
            setIsEdit(false)
            fetchAllPlans()
        }
    }

    const handleCancel = () => {
        setIsEdit(false)
        setSelectedPlanData('')
        setShowAddAndEditPlan(false)
    }

    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-screen w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <ManagementSecMainHeader
                            mainHeading={"Plan Name"}
                            subHeading={"Manage your events effectively"}
                            btnText={"Create Plan"}
                            handleBtn={() => setShowAddAndEditPlan(true)} />
                    </div>

                    {/* Table */}
                    {showAddAndEditPlan ?
                        <div className="w-[98%] flex items-center justify-center">
                            <Suspense fallback={<div>loading...</div>} >
                                <CreateAndEditPlan data={selectedPlanData} handleCancel={handleCancel} handleSubmit={(value) => handleSubmit(value)} />
                            </Suspense>
                        </div>
                        :
                        <div className="w-full p-8">
                            {plans &&
                                <TableWithoutPagination columns={columns} data={plans} />
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Index