import React, { useEffect, useState, lazy } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getEventById, editEvent } from '../../../../apis/NEW/events';
import { MainSidebar, MainNavbar } from "components";
import { decodeFromBase64 } from '../../../../utils/encodeAndDecode';
import { toast } from "react-toastify"


const CreateAndEditEventsFirstPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsFirstPage })));
const CreateAndEditEventsSecondPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsSecondPage })));
const CreateAndEditEventsThirdPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsThirdPage })));
const CreateAndEditEventsFourthPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsFourthPage })));
const CreateAndEditEventsFifthPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsFifthPage })));
import { EVENTS } from '../../../../common/routes';



function Index() {
    const { id } = useParams();
    const navigate = useNavigate()
    const [eventData, setEventData] = useState()

    const fetchEventById = async () => {
        const decodedId = decodeFromBase64(id)
        const res = await getEventById(decodedId)
        setEventData(res.data)
    }

    useEffect(() => {
        fetchEventById()
    }, [])

    const handleNextPage = async (value) => {
        setEventData(prev => ({ ...prev, ...value }))
    }


    const handleSubmit = async () => {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { sold_out, createdAt, updatedAt, id, enable_connections, ...other } = eventData;
        const structData = {
            ...other,
            categories: other?.categories?.map(item => item?._id || item).filter(item => item !== null),
            co_hosts: other?.co_hosts?.map(item => item?._id || item).filter(item => item !== null),
            tags: other?.tags?.map(item => item?._id || item).filter(item => item !== null),
            location: typeof other?.location === 'object' ? other?.location?._id : other?.location,
            host: typeof other?.host === 'object' ? other?.host?._id : other?.host
        };

        const toastId = toast.loading("Updating Event Please Wait...");
        const res = await editEvent(id, structData);
        toast.dismiss(toastId);

        if (res.success) {
            navigate(EVENTS.EVENTS)
            toast.success("Event updated Successfully...");
        }
    };



    const Pages = [CreateAndEditEventsFifthPage, CreateAndEditEventsFirstPage, CreateAndEditEventsFourthPage, CreateAndEditEventsSecondPage, CreateAndEditEventsThirdPage]

    return (
        <div className='flex flex-col h-full'>

            {/* Navbar */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="h-full w-2/12">
                    <MainSidebar />
                </div>
                <div className="bg-[#f8f8f8] h-full w-10/12">
                    <div className="w-[96%] mx-auto my-10">
                        {eventData &&
                            <>
                                {Pages.map((Page, index) => (
                                    <Page key={index} nextPage={handleNextPage} data={eventData} edit={true} />
                                ))}
                            </>
                        }

                        <div className="flex justify-center">
                            <button className='bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-4 focus:ring-green-300 rounded-3xl p-2 px-4 text-white shadow-lg transition duration-150 ease-in-out' onClick={handleSubmit}>Submit</button>
                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default Index