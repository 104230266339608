import React, { useState, useEffect } from 'react'
import { MainSidebar, ManagementSecMainHeader, Table, MainNavbar, UserDeletedAccount, Popup } from "components"

import { getAllAccountDeletedUsers, updateUserAccountStatus } from 'apis/NEW/users/index.js'
import { formatDateToDDMMYY } from 'shared/dates.js'
import { toast } from 'react-toastify'


function Index() {

    const [userData, setUserData] = useState()
    const [accountStatusPopup, setAccountStatusPopup] = useState({ id: false, status: false })
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })
    const [userFilter, setUserFilter] = useState('requested')

    const columns = [

        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Name', accessor: 'user.name' },
        {
            Header: 'Photo',
            accessor: 'user.photo',
            Cell: ({ value }) => (
                <img src={value} alt="userPhoto" className='w-[66px] h-[70px]' />
            )
        },
        { Header: 'Email', accessor: 'user.email' },
        { Header: 'Phone', accessor: 'user.phone' },
        { Header: 'createdAt', accessor: (row) => formatDateToDDMMYY(row.createdAt) },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <>
                    {value === 'requested' ?
                        <span className='p-2 text-green-500 bg-green-100 rounded-3xl'>
                            requested
                        </span>
                        :
                        <span className='p-2 text-red-500 bg-red-100 rounded-3xl'>
                            {value}
                        </span>

                    }
                </>

            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => {
                const status = row.original.status;
                return (
                    <div>
                        {status === 'requested' ? (
                            <>
                                <button onClick={() => setAccountStatusPopup({ id: row.original.id, status: 'cancelled' })} className='p-2 px-3 mr-1 text-white bg-green-500 rounded-xl'>Cancel</button>
                                <button onClick={() => setAccountStatusPopup({ id: row.original.id, status: 'deleted' })} className='p-2 px-3 mr-1 text-white bg-red-500 rounded-xl'>Delete</button>
                            </>
                        ) : status === 'cancelled' ? (
                            <button className='bg-[#8bbbf8] opacity-[40%] text-white p-2 px-3 rounded-xl' disabled>Cancelled</button>
                        ) : status === 'deleted' ? (
                            <button className='bg-brandRed opacity-[40%] text-white p-2 px-3 rounded-xl' disabled>Deleted</button>
                        ) : null}
                    </div>
                );
            }
        }
    ];


    const handleFilterData = (value) => {
        setUserFilter(value)
        fetchAllAccountDeletedUsers(pageNation.currentPage, value)
    }


    const fetchAllAccountDeletedUsers = async (page, status = 'requested') => {
        setUserData(null)
        const res = await getAllAccountDeletedUsers(page, status)
        setUserData(res?.data)
        const data = res?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    const updateUserAccount = async (data) => {
        const res = await updateUserAccountStatus(data?.id, { status: data?.status })
        if (res?.success) {
            fetchAllAccountDeletedUsers(1, userFilter)
            setAccountStatusPopup(prev => ({ ...prev, id: false }))
        }
    }

    useEffect(() => {
        fetchAllAccountDeletedUsers(1, userFilter)
    }, [])

    const handleBtn = () => {
        toast('coming Soon')
    }

    return (
        <div className='flex flex-col h-screen'>

            {accountStatusPopup.id &&
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <Popup text={'Are you sure ?'} onClose={() => setAccountStatusPopup(prev => ({ ...prev, id: false }))} onConfirm={() => updateUserAccount(accountStatusPopup)} />
                </div>
            }

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-screen w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <ManagementSecMainHeader
                            mainHeading={"Create User"}
                            subHeading={"user Management"}
                            btnText={"Add User"}
                            handleBtn={handleBtn}
                            handleFilterData={handleFilterData}
                            filterOptions={userFilter}
                            FilterComponent={UserDeletedAccount}
                        />
                    </div>
                    {/* Table */}
                    <div className="p-8">
                        {userData &&
                            <Table columns={columns} data={userData} pageNation={pageNation} handlePageNation={(value) => fetchAllAccountDeletedUsers(value, userFilter)} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index