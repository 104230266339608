import React from 'react'

import BasicFilter from "./basicFilter"
import AdvanceFilter from "./advanceFilter"


function Index({ decodedId, readOnly = true, submit = false, edit = false }) {
    return (
        <>
            <div className="flex justify-between">
                <div className="w-[48%]">
                    <BasicFilter decodedId={decodedId} readOnly={readOnly} submit={submit} edit={edit} />
                </div>
                <div className="w-[48%]">
                    <AdvanceFilter decodedId={decodedId} readOnly={readOnly} submit={submit} edit={edit} />
                </div>
            </div>
        </>
    )
}

export default React.memo(Index)