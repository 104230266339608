import React from "react";

function Index({ text, onClose, onConfirm }) {

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 text-center">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full transition-transform transform duration-300 ease-in-out scale-100">
                <h2 className="text-lg font-semibold mb-4">{text}</h2>
                <div className="flex justify-center">
                    <button
                        className="bg-gray-300 text-gray-800 px-4 py-2 rounded mr-2 hover:bg-gray-400"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={onConfirm}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
