import React, { memo, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup';
import { DropDown } from "components"
import { fourthPage } from '../../../../../utils/validations/event';
import { getAllHosts, getAllActiveLocation } from '../../../../../apis/NEW/events';
import { addLabelAndValueInData } from '../../../../../utils/dataConversion';

function Index({ prevPage, nextPage, data, edit = false }) {

    const [hostOptions, setHostOptions] = useState([])
    const [locations, setLocation] = useState([])
    const [eventData, setEventData] = useState({
        host: data?.host?._id || null,
        co_hosts: data?.co_hosts?.map(item => item._id) || [],
        mode: data?.mode || null,
        //online_event_link: data?.online_event_link || null,
        location: data?.location?._id || null,
    })
    const [readOnly, setReadOnly] = useState(edit)


    const handleNextPage = async () => {
        try {
            // await fourthPage.validate(eventData, { abortEarly: false });
            nextPage(eventData, 5);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const handlePrevPage = () => {
        prevPage(eventData, 3);
    }

    const updateState = (key, value) => {
        setEventData(prev => ({ ...prev, [key]: value }))
    }

    const eventType = [
        { label: "Online", value: "online" },
        { label: "Offline", value: "offline" }
    ]

    const fetchAllHosts = async () => {
        const res = await getAllHosts()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setHostOptions(transformedData)
    }

    const fetchAllLocation = async () => {
        const res = await getAllActiveLocation()
        const transformedData = addLabelAndValueInData(res?.data, "name", "id")
        setLocation(transformedData)
    }


    useEffect(() => {
        fetchAllHosts()
        fetchAllLocation()
    }, [])


    const handleEdit = async () => {
        try {
            setReadOnly(!readOnly)
            if (!readOnly) {
                await fourthPage.validate(eventData, { abortEarly: false });
                nextPage(eventData);
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    return (
        <div>

            <div className="flex justify-between mt-16 mb-5">
                <h1 className='mt-16 mb-5 text-lg font-semibold'>Who is the <span className='text-brandRed'> host </span></h1>
                {edit &&
                    <div className="p-1 px-4 border-2 border-gray-700 cursor-pointer h-fit"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit()
                        }}>
                        {!readOnly ? 'submit' : 'Edit'}
                    </div>
                }
            </div>
            <div className="h-[56px] w-[400px] my-8 flex flex-col justify-center rounded-3xl">
                <DropDown
                    readOnly={readOnly}
                    selectedValue={eventData.host}
                    placeholderValue={"Host Name"}
                    options={hostOptions.filter(item => item.status)}
                    handleOnChange={(value) => updateState("host", value)}
                />
            </div>

            <div className="h-[56px] w-[400px] my-8 flex flex-col justify-center rounded-3xl">
                <DropDown
                    readOnly={readOnly}
                    selectedValue={eventData.co_hosts}
                    placeholderValue={"Co Host Name"}
                    options={hostOptions.filter(item => item.status)}
                    handleOnChange={(value) => updateState("co_hosts", value)}
                    mode={"multiple"}
                />
            </div>

            <h1 className='mt-16 mb-5 text-lg font-semibold'>Event is<span className='text-green-500'> Online </span>or Offline</h1>
            <div className="h-[56px] w-[400px] my-8 flex flex-col justify-center rounded-3xl">
                <DropDown
                    readOnly={readOnly}
                    selectedValue={eventData.mode}
                    placeholderValue={"Event Mode"}
                    options={eventType}
                    handleOnChange={(value) => updateState("mode", value)}
                />
            </div>

            {/* {eventData.mode === "online" &&
                <input type='text' placeholder='Online Event Link'
                    readOnly={readOnly}
                    value={eventData.online_event_link}
                    onChange={(e) => updateState("online_event_link", e.target.value)}
                    className="h-[68px] w-[400px] mx-2 my-8 flex flex-col justify-center rounded-full bg-white text-black border-2 border-gray-300" />

            } */}
            <>
                <h1 className='mt-16 mb-5 text-lg font-semibold'>What is the location of the event</h1>
                <div className="h-[56px] w-[400px] my-8 flex flex-col justify-center rounded-3xl">
                    <DropDown
                        readOnly={readOnly}
                        selectedValue={eventData.location}
                        placeholderValue={"Choose location"}
                        options={locations}
                        handleOnChange={(value) => updateState("location", value)}
                    />
                </div>
            </>
            {edit === false &&
                <div className="flex items-center justify-center gap-5 mx-auto mb-20 font-bold text-black bg-white rounded-lg shadow-lg w-fit">
                    <button className='p-2 px-4 border-gray-300 border-e-2 hover:text-gray-700 hover:font-medium hover:border-e-0 hover:border-b-4 hover:border-brandRed' onClick={handlePrevPage}>Back</button>
                    <button className='p-2 px-4 hover:text-gray-700 hover:font-medium hover:border-b-4 hover:border-brandRed' onClick={handleNextPage}>Next</button>
                </div>
            }
        </div>
    )
}

export default memo(Index)