import React, { useState, useEffect, Suspense, lazy } from 'react'
import moment from 'moment'
import { ManagementSecMainHeader, MainNavbar, MainSidebar, Table } from 'components'

import { createCoupon, getAllCoupons, updateCoupon } from 'apis/NEW/coupons'

const AddAndEditCoupons = lazy(() => import("components").then(module => ({ default: module.AddAndEditCoupons })))


function Index() {

    const [isEdit, setIsEdit] = useState(false)
    const [couponData, setCouponData] = useState()
    const [selectedCoupon, setSelectedCoupon] = useState()
    const [addAndEditCoupon, setAddAndEditCoupon] = useState(false)
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Code', accessor: 'code' },
        { Header: 'Discount', accessor: 'discount_percentage' },
        { Header: 'Min amount', accessor: 'min_purchase_amount' },
        { Header: 'Max amount', accessor: 'max_discount_amount' },
        {
            Header: 'Valid from',
            accessor: (data) => moment(data.valid_from).format('YYYY-MM-DD')
        },
        {
            Header: 'Valid to',
            accessor: (data) => moment(data.valid_to).format('YYYY-MM-DD')
        },
        { Header: 'Gender', accessor: 'gender' },
        {
            Header: 'Plan',
            accessor: 'plan.name',
            Cell: ({ value }) => value ? value : 'All Plans',
        },
        { Header: 'Coupon module', accessor: 'module' },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <>
                    {value ?
                        <span className='p-2 text-green-500 bg-green-100 rounded-3xl'>
                            active
                        </span>
                        :
                        <span className='p-2 text-red-500 bg-red-100 rounded-3xl'>
                            Inactive
                        </span>
                    }
                </>

            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                </div>
            )
        }
    ];



    const handleEdit = (row) => {
        setIsEdit(true)
        setSelectedCoupon(row?.original)
        setAddAndEditCoupon(true)
    };



    const fetchAllCoupons = async (page) => {
        const res = await getAllCoupons(page)
        setCouponData(res?.data?.coupons)
        const data = res?.data?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        fetchAllCoupons(1)
    }, [])

    const handleBtn = () => {
        setAddAndEditCoupon(true)
    }

    const handleClose = () => {
        setAddAndEditCoupon(false)
        setIsEdit(false)
        setSelectedCoupon(null)
    }

    const handleSubmit = async (value) => {
        const res = isEdit ? await updateCoupon(selectedCoupon.id, value) : await createCoupon(value);
        if (res.success) {
            if (isEdit) {
                setIsEdit(false);
            }
            setAddAndEditCoupon(false);
            fetchAllCoupons(1);
        }

    };

    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] min-h-screen h-full w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <ManagementSecMainHeader
                            mainHeading={"Coupon"}
                            subHeading={"Manage your events effectively"}
                            btnText={"Add Coupon"}
                            handleBtn={handleBtn} />
                    </div>

                    {addAndEditCoupon ?
                        <div className="w-[98%] flex items-center justify-center">
                            <Suspense fallback={<div>Loading...</div>}>
                                <AddAndEditCoupons handleCancel={handleClose} handleSubmit={handleSubmit} data={selectedCoupon} />
                            </Suspense>
                        </div>
                        :
                        <div className="p-8">
                            {couponData &&
                                <Table columns={columns} data={couponData} pageNation={pageNation} handlePageNation={(value) => fetchAllCoupons(value)} />
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Index