import React, { useState, useEffect } from 'react'
import { InputField, DropDown, UserProfileDetails, UserPartnerPreference } from "components"
import { GENDER, relationshipStatusOptions, singlesMartialStatusOPtions, couplesMartialStatusOPtions, singlesLookingForOptions, couplesLookingForOptionsCouples, interests, contactModeWithParent } from '../../../../../shared/constants'
import { fetchReligions, getCastesByReligion } from '../../../../../apis/religionAndCaste'
import { addLabelAndValueInData, formatDateToDisplayDateField } from '../../../../../utils/dataConversion'
import { getUserBasicDetails, updateUserBasicDetails } from 'apis/NEW/users'
import { toast } from 'react-toastify'
import ImageHandler from './imageHandler'

import avatar from 'assets/icons/avatar.png'
import verified from 'assets/icons/verified.png'
import { downloadGroomsDetails } from './pdfDownloader'


function Index({ decodedId, readOnly = true, submit = false, edit = false }) {


    const [religionAndCaste, setReligionAndCaste] = useState({
        religionOptions: [],
        castOptions: []
    })
    const [userData, setUserData] = useState({})
    const [userDataDetailedData, setUserDetailedData] = useState()
    const [images, setImages] = useState([])
    const [prevImgUrl, setPrevImgUrl] = useState(null)


    const leftContent = [
        { label: "Name", value: 'name', type: "text" },
        { label: "Email", value: 'email', type: "email" },
        { label: "Phone", value: 'phone', type: "number" },
        { label: "DOB", value: 'dob', type: "date" },
        { label: "Gender", value: 'sex', mode: "", options: GENDER, type: 'dropDown' },
        { label: "Relation Ship", value: 'relationship_status', mode: "", options: relationshipStatusOptions, type: 'dropDown' },
        { label: "Martial Status", value: 'marital_status', mode: "", options: userData.relationship_status === 'single' ? singlesMartialStatusOPtions : couplesMartialStatusOPtions, type: 'dropDown' },
    ]

    const rightContent = [
        { label: "Looking For", value: 'looking_for', mode: "", options: userData.relationship_status === 'single' ? singlesLookingForOptions : couplesLookingForOptionsCouples, type: 'dropDown' },
        { label: "Interest", value: 'interests', mode: "multiple", options: interests, type: 'dropDown' },
        { label: "Religion", value: 'religion_id', mode: "", options: religionAndCaste.religionOptions, type: 'dropDown' },
        { label: "Cast", value: 'caste_id', mode: "", options: religionAndCaste.castOptions, type: 'dropDown' },
        // { label: "Contact Number", value: 'contactNumber', type: "number" },
        { label: "Contact Mode", value: 'contact_mode', mode: "multiple", options: contactModeWithParent, type: 'dropDown' },

    ]

    const updateState = (key, value) => {
        if (key === 'religion_id') {
            fetchCasteByReligionId(value)
        }
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    const fetchAllReligions = async () => {
        const res = await fetchReligions()
        const religion = addLabelAndValueInData(res, "name", 'id')
        setReligionAndCaste(prev => ({ ...prev, religionOptions: religion }))
    }

    const fetchCasteByReligionId = async (id) => {
        const res = await getCastesByReligion(id)
        const cast = addLabelAndValueInData(res, "name", 'id')
        setReligionAndCaste(prev => ({ ...prev, castOptions: cast }))
    }


    const fetchUserDetails = async () => {
        const res = await getUserBasicDetails(decodedId)
        const user = res?.data[0]
        const transformedData = {
            name: user?.name,
            email: user?.email,
            phone: user?.phone,
            dob: user?.personal?.dob,
            sex: user?.personal?.sex,
            relationship_status: user?.personal?.relationship_status,
            marital_status: user?.personal?.marital_status,
            looking_for: user?.personal?.looking_for,
            interests: user?.interests,
            religion_id: user?.religion?.id,
            caste_id: user?.caste?.id,
            // contactNumber: user?.phone,
            contact_mode: user?.contact_mode,
        };
        setUserDetailedData(user)
        setImages(user?.images)
        if (edit) {
            setUserData(transformedData)
            if (transformedData?.religion_id) {
                fetchCasteByReligionId(transformedData?.religion_id)
            }
        } else {
            const data = {
                ...transformedData,
                religion_id: user?.religion?.name,
                caste_id: user?.caste?.name
            }
            setUserData(data)
        }
    }

    const handleOnSubmit = async () => {
        if (userData) {
            const res = await updateUserBasicDetails(decodedId, userData)
            if (res?.success) {
                toast.success('updated successfully')
            }
        }
    }

    const downloadPdf = () => {
        downloadGroomsDetails(decodedId)
    };

    useEffect(() => {
        fetchUserDetails()
        fetchAllReligions()
    }, [])

    useEffect(() => {
        if (submit) {
            handleOnSubmit(userData)
        }
    }, [submit])

    return (
        <div className='text-black bg-white'>
            <div className="m-5">
                <div className="flex justify-between">
                    <div className="">
                        <h1 className='flex pt-5 text-xl font-semibold'>Basic Info({userDataDetailedData?.referrer?.name})
                            <h1 onClick={downloadPdf} className='text-base cursor-pointer ms-2 p-2 px-4 bg-brandRed text-white rounded-lg'>Download</h1>
                        </h1>
                        <p className='text-base text-gray-500'>Add time  that highlight what makes it unique <br />Event Time</p>

                    </div>
                    <div className="relative inline-block">
                        <img src={images[0]?.url ? images[0]?.url : avatar} alt="user image" className="w-[70px] h-[80px]" />
                        {userDataDetailedData?.verification?.status === 'verified' &&
                            <img src={verified} alt="verified icon" className="absolute bottom-1 -left-3 w-[30px] h-[30px]" />
                        }
                    </div>

                </div>
                <div className="flex justify-between">
                    {/* Left side Content */}
                    <div className="w-[46%] h-full">
                        {leftContent.map((item, index) => {
                            const useDropdown = edit && item.type === 'dropDown';
                            return (
                                <div key={index} className="flex items-center justify-between my-4">
                                    <h1 className='text-lg font-semibold'>{item.label}</h1>
                                    <div className={useDropdown ? "h-[56px] w-[70%] flex flex-col justify-center rounded-3xl" : "w-[70%]"}>
                                        {useDropdown ? (
                                            <DropDown
                                                selectedValue={userData[item.value]}
                                                placeholderValue={""}
                                                options={item.options}
                                                readOnly={readOnly}
                                                mode={item.mode}
                                                handleOnChange={(value) => updateState(item?.value, value)}
                                            />
                                        ) : (
                                            <InputField
                                                readOnly={readOnly}
                                                value={item?.type === 'date' ? formatDateToDisplayDateField(userData[item.value]) : userData[item.value]}
                                                onChange={(value) => updateState(item?.value, value)}
                                                type={item.type}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* Right Side Contents */}
                    <div className="w-[46%] h-full">
                        {rightContent.map((item, index) => {
                            const useDropdown = edit && item.type === 'dropDown';
                            return (
                                <div key={index} className="flex items-center justify-between my-4">
                                    <h1 className='text-lg font-semibold'>{item.label}</h1>
                                    <div className={useDropdown ? "h-[56px] w-[70%] flex flex-col justify-center rounded-3xl" : "w-[70%]"}>
                                        {useDropdown ? (
                                            <DropDown
                                                selectedValue={userData[item.value]}
                                                placeholderValue={""}
                                                options={item.options}
                                                readOnly={readOnly}
                                                mode={item.mode}
                                                handleOnChange={(value) => updateState(item.value, value)}
                                            />
                                        ) : (
                                            <InputField
                                                readOnly={readOnly}
                                                value={userData[item.value]}
                                                onChange={(value) => updateState(item.value, value)}
                                                type={item.type}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}

                        {/* Image Section */}
                        {edit ?
                            <div className="flex justify-between ">
                                <h1 className='text-lg font-semibold'>Pics</h1>
                                <div className="w-[70%] h-[330px] border flex flex-col justify-center items-center">
                                    <div className='flex flex-wrap items-center justify-center gap-2'>
                                        {images &&
                                            <ImageHandler imageData={images} decodedId={decodedId} handleImageUploadAndDeleteSuccess={() => fetchUserDetails()} />
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div className="flex justify-between ">
                                    <h1 className='text-lg font-semibold'>Pics</h1>
                                    <div className="w-[70%] h-[330px] border flex flex-col justify-center items-center">
                                        {images.length > 0 ?
                                            <div className='flex flex-wrap items-center justify-center gap-2'>
                                                {images?.map((item) => (
                                                    <img key={item?._id} src={item?.url} onClick={() => setPrevImgUrl(item?.url)} alt="" className={` h-[140px] w-[110px] cursor-pointer`} />
                                                ))}
                                            </div>
                                            :
                                            <h1>No Images</h1>
                                        }
                                    </div>
                                </div>
                                {prevImgUrl !== null && (
                                    <div className='fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
                                        <div className='relative'>
                                            <img src={prevImgUrl} alt='selectedimage' className='w-fit h-[550px]' />

                                        </div>
                                        <div className='mb-[500px] ml-[10px]'>
                                            <button onClick={() => setPrevImgUrl(null)} className="m-4 text-xl font-bold text-black">
                                                &#x2715;
                                            </button>
                                        </div>
                                    </div>

                                )}
                            </>
                        }
                    </div>
                </div>
                <UserProfileDetails decodedId={decodedId} readOnly={readOnly} submit={submit} edit={edit} />
                <UserPartnerPreference decodedId={decodedId} readOnly={readOnly} submit={submit} edit={edit} />;
            </div>
        </div>
    )
}

export default React.memo(Index)