import React, { memo } from 'react';

function Index({ text, isSelected, onChange, readOnly = false }) {

    const handleClick = (event) => {
        if (readOnly) {
            event.preventDefault();
            return;
        }
        onChange();
    };

    const borderColor = isSelected ? 'border-brandRed' : 'border-gray-300';
    return (
        <div className={`flex items-center justify-between border ${borderColor} p-[1.7rem] rounded-full w-full h-full cursor-pointer`} onClick={handleClick}>
            <div className="flex items-center ms-2">
                <span className={`mr-2 ${isSelected ? "text-brandRed" : 'text-black'}`}>{text}</span>
            </div>
            {isSelected &&
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.5311 4.80002L8.79995 19.5312L0.46875 11.2L1.59995 10.0688L8.79995 17.2688L22.4 3.66882L23.5311 4.80002Z" fill="#F83758" />
                </svg>
            }
        </div>
    );
}

export default memo(Index)
