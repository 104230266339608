import React, { useState } from 'react';
import { DropDown, InputField } from 'components';

function Index({ data, handleSubmit, handleCancel }) {

  const [planCategoryData, setPlanCategoryData] = useState({
    name: data?.name,
    status: data?.status,
    id: data?.id
  })


  const content = [
    { label: 'Category Name', value: 'name', isDropDown: false, },
    { label: 'Status', value: 'status', isDropDown: true, options: [{ label: 'Active', value: true }, { label: 'Inactive', value: false }] },
  ]

  const updateState = (key, value) => {
    setPlanCategoryData(prev => ({ ...prev, [key]: value }))
  }



  return (
    <div className="w-full mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-bold mb-4">Create Plan name</h2>
      <p className="mb-2">Add time that highlight what makes it unique</p>
      <p className="mb-6">Event Time</p>

      <div className="mt-14">
        {content?.map((item, index) => (
          <div className="w-[60%] px-2 my-6" key={index}>
            <div className='flex items-center justify-between'>
              <label className="text-lg text-gray-700 me-4">{item?.label}</label>
              <div className="w-[450px] h-[56px] -mt-4">
                {item?.isDropDown ?
                  <DropDown selectedValue={planCategoryData[item?.value]} handleOnChange={(value) => updateState(item?.value, value)} options={item?.options} />
                  :
                  <InputField value={planCategoryData[item?.value]} onChange={(value) => updateState(item?.value, value)} />
                }
              </div>
            </div>
          </div>
        ))}
      </div>


      <div className="flex justify-center my-20">
        <button type="button" className="px-4 py-2 bg-gray-200 text-gray-700 w-[120px] h-[50px]" onClick={handleCancel}>Cancel</button>
        <button type="submit" className="px-4 py-2 bg-brandRed text-white w-[120px] h-[50px]" onClick={() => handleSubmit(planCategoryData)}>Submit</button>
      </div>
    </div >
  );
};

export default Index;