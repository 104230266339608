import React, { useState, memo, useEffect } from 'react'
import { toast } from 'react-toastify'
import { ChooseOption } from "components"
import { getAllEventTags } from '../../../../../apis/NEW/events'


function Index({ prevPage, nextPage, data, edit = false }) {

    const [tags, setTags] = useState(() => {
        if (Array.isArray(data?.tags)) {
            const tagData = data?.tags?.map(item => item?._id);
            return tagData.includes(undefined) ? data?.tags : tagData
        }
        return [];
    });
    const [tagsOption, setTagsOption] = useState([])
    const [readOnly, setReadOnly] = useState(edit)


    const handlePrevPage = () => {
        prevPage({ tags }, 2)
    }

    const handleNextPage = () => {
        if (tags.length > 0) {
            nextPage({ tags }, 4)
        } else {
            toast.error("Please Select at least one Tag")
        }
    }

    const updateTag = (tag) => {
        setTags(currentTags => {
            if (currentTags.includes(tag)) {
                return currentTags.filter(value => value !== tag);
            } else {
                return [...currentTags, tag];
            }
        });
    }

    const fetchAllEventTags = async () => {
        const res = await getAllEventTags()
        setTagsOption(res?.data)
    }

    useEffect(() => {
        fetchAllEventTags()
    }, [])

    const handleEdit = () => {
        setReadOnly(!readOnly)
        if (!readOnly) {
            if (tags.length > 0) {
                nextPage({ tags: tags })
            } else {
                toast.error("Please Select at least one Tag")
            }
        }
    }

    return (
        <div>
            <div className="flex justify-between mt-16 mb-5">
                <h1 className='font-semibold text-lg my-5'>What are the tags need to give</h1>
                {edit &&
                    <div className="border-2 border-gray-700 p-1 px-4 cursor-pointer h-fit"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit()
                        }}>
                        {!readOnly ? 'submit' : 'Edit'}
                    </div>
                }
            </div>
            <div className="flex flex-wrap gap-5 w-full mb-10">
                {tagsOption.map((item) => (
                    <div key={item.id} className="w-[30%]">
                        <ChooseOption
                            readOnly={readOnly}
                            text={`# ${item.name}`}
                            isSelected={tags.includes(item.id) ? true : false}
                            onChange={() => updateTag(item.id)}
                        />
                    </div>
                ))}
            </div>

            {edit === false &&
                <div className="w-fit flex items-center justify-center gap-5 bg-white text-black font-bold shadow-lg rounded-lg mb-20 mx-auto">
                    <button className='border-e-2 border-gray-300 p-2 px-4 hover:text-gray-700 hover:font-medium hover:border-e-0 hover:border-b-4 hover:border-brandRed' onClick={handlePrevPage}>Back</button>
                    <button className='p-2 px-4 hover:text-gray-700 hover:font-medium hover:border-b-4 hover:border-brandRed' onClick={handleNextPage}>Next</button>
                </div>
            }

        </div>
    )
}

export default memo(Index)