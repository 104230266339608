import React, { Suspense, lazy, useState } from 'react'
import { createNewEvent } from '../../../../apis/NEW/events'
import { useNavigate } from 'react-router-dom'
import { EVENTS } from '../../../../common/routes'
import { toast } from "react-toastify"

import { CreateEventHeader, MainSidebar, MainNavbar } from 'components'
const CreateAndEditEventsFirstPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsFirstPage })));
const CreateAndEditEventsSecondPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsSecondPage })));
const CreateAndEditEventsThirdPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsThirdPage })));
const CreateAndEditEventsFourthPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsFourthPage })));
const CreateAndEditEventsFifthPage = lazy(() => import("components").then(module => ({ default: module.CreateAndEditEventsFifthPage })));


function Index() {

    const navigate = useNavigate()
    const [eventData, setEventData] = useState({
        offline_event_link: '',
    });
    const [currentPage, setCurrentPage] = useState(1)

    const handlePrevPage = (value, prevPage) => {
        setEventData(prev => ({ ...prev, value }))
        setCurrentPage(prevPage)
    }

    const handleNextPage = (value, nextPage) => {
        setEventData(prev => ({ ...prev, ...value }))
        if (currentPage === 5) {
            const data = { ...eventData, ...value }
            handleCreateEvent(data)
        } else {
            setCurrentPage(nextPage)
        }
    }

    const handleCreateEvent = async (data) => {
        const toastId = toast.loading("Creating Event Please Wait...")
        const res = await createNewEvent(data)
        if (res.success) {
            navigate(EVENTS.EVENTS)
        }
        toast.dismiss(toastId)
    }

    const PageToRender = currentPage === 1 ? CreateAndEditEventsFirstPage
        : (currentPage === 2 ? CreateAndEditEventsSecondPage
            : (currentPage === 3 ? CreateAndEditEventsThirdPage
                : (currentPage === 4 ? CreateAndEditEventsFourthPage
                    : CreateAndEditEventsFifthPage)))

    // const PageToRender = CreateAndEditEventsFifthPage
    return (
        <div className='flex flex-col h-full'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="h-full w-2/12">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-full w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <CreateEventHeader mainHeading={currentPage === 5 ? "Event Information" : "Create an Event"}
                            subHeading={currentPage === 5 ? "Add details that highlight what makes it unique Event Title" : "Manage your events effectively"}
                        />

                        {/* Content To Create the Event */}
                        <Suspense fallback={<div>loading...</div>}>
                            <PageToRender
                                prevPage={(value, prevPage) => handlePrevPage(value, prevPage)}
                                nextPage={(value, nextPage) => handleNextPage(value, nextPage)}
                                data={eventData}
                            />
                        </Suspense>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Index