import { DropDown, InputField } from "components"
import React, { useEffect, useState } from 'react'
import languages from "../../../../../../assets/json/languages.json"
import { addLabelAndValueInData } from '../../../../../../utils/dataConversion'



function Index({ data, readOnly = true, handleOnSubmit, submit = false, edit = false }) {

    const [userData, setUserData] = useState({
        others: data?.others,
        mother_tongue: data?.mother_tongue,
    })
    const languagesOptions = addLabelAndValueInData(languages, "name", "name")

    const content = [
        { label: "Language Known", value: 'others', options: languagesOptions, mode: 'multiple' },
        { label: "Mother Tongue ", value: 'mother_tongue', options: languagesOptions, mode: '' },
    ]


    const updateState = (key, value) => {
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (submit) {
            handleOnSubmit(userData)
        }
    }, [submit])

    return (
        <div className='bg-white text-black -mt-4'>
            <div className="m-5">
                <h1 className='font-semibold text-xl pt-5'>Language Known</h1>
                <p className='text-gray-500 text-base'>Add time  that highlight what makes it unique <br />Event Time</p>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-[320px]">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between my-4">
                                <h1 className='font-semibold text-lg'>{item.label}</h1>
                                <div className={edit ? "h-[56px] w-[70%] flex flex-col justify-center rounded-3xl" : "w-[70%]"}>
                                    {edit ? (
                                        <DropDown
                                            selectedValue={userData[item.value]}
                                            options={item.options}
                                            mode={item.mode}
                                            readOnly={readOnly}
                                            handleOnChange={(value) => updateState(item.value, value)}
                                        />
                                    ) : (
                                        <InputField
                                            readOnly={readOnly}
                                            value={userData[item.value]}
                                            onChange={(value) => updateState(item.value, value)}
                                        />
                                    )}
                                </div>
                            </div>

                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)