import { auth, noAuth } from "../..";

// ====== Event Category Section ======
export const getAllEventCategory = async () => {
    const res = await auth({
        method: "GET",
        url:'/admin/events/categories',
    });
    return res;
}
export const createCategory = async (data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
        }
    }
    const res = await auth({
        method: "POST",
        url: "/admin/events/category",
        data,
        options
    });
    return res;
}
export const updateCategory = async (id, data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
        }
    }
    const res = await auth({
        method: "PUT",
        url: `/admin/events/category/${id}`,
        data,
        options,
    });
    return res;
}

// ====== Event Tags Section ======
export const getAllEventTags = async () => {
    const res = await auth({
        method: "GET",
        url:'/admin/events/tags',
    });
    return res;
}
export const getAllActiveEventTags = async () => {
    const res = await auth({
        method: "GET",
        url:'/admin/events/tags',
    });
    return res;
}
export const createTags = async (data) => {
    const res = await auth({
        method: "POST",
        url: "/admin/events/tags",
        data,
    });
    return res;
}
export const updateTags = async (id, data) => {
    const res = await auth({
        method: "PUT",
        url: `/admin/events/tags/${id}`,
        data,
    });
    return res;
}

// ====== Event Hosts Section ======
export const getAllHosts = async () => {
    const res = await auth({
        method: "GET",
        url:'/admin/events/hosts',
    });
    return res;
}
export const createHost = async (data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
        }
    }
    const res = await auth({
        method: "POST",
        url: "/admin/events/host",
        data,
        options
    });
    return res;
}
export const updateHost = async (id, data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
        }
    }
    const res = await auth({
        method: "PUT",
        url: `/admin/events/host/${id}`,
        data,
        options,
    });
    return res;
}

// ====== Event Location Section ======
export const getAllActiveLocation = async () => {
    const res = await auth({
        method: "GET",
        url:'/events/locations',
    });
    return res;
}
export const getAllLocation = async () => {
    const res = await auth({
        method: "GET",
        url:'/admin/events/locations',
    });
    return res;
}
export const createLocation = async (data) => {
    const res = await auth({
        method: "POST",
        url: "/admin/events/locations",
        data,
    });
    return res;
}
export const updateLocation = async (id, data) => {
    const res = await auth({
        method: "PUT",
        url: `/admin/events/locations/${id}`,
        data,
    });
    return res;
}

// ====== Event Section ======
//  ==== GET ====
export const getAllEvents = async (page) => {
    const res = await auth({
        method: "GET",
        url:`/admin/events?page=${page}&limit=10`,
    });
    return res;
}

export const getUnAuthEventBySlug = async (slug) => {
    const res = await noAuth({
        method: 'GET',
        url: `/events/slug/${slug}`
    });
    return res;
}
export const getEventById = async (id) => {
    const res = await auth({
        method: "GET",
        url:`/admin/events/${id}`,
    });
    return res;
}
export const getEventParticipantsById = async (id,page) => {
    const res = await auth({
        method: "GET",
        url:`/admin/events/participants/${id}?page=${page}&limit=10`,
    });
    return res;
}
export const getEventConfirmedParticipantsById = async (id, page) => {
    const res = await auth({
        method: "GET",
        url:`/admin/events/participants/confirmed/${id}?page=${page}&limit=10`,
    });
    return res;
}
export const getPreBookedParticipantsById = async (id, page) => {
    const res = await auth({
        method: "GET",
        url:`/admin/events/prebookings/${id}?page=${page}&limit=10`,
    });
    return res;
}

// ==== POST ====
export const createNewEvent = async (data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
        }
    }
    const res = await auth({
        method: "POST",
        url: '/admin/events',
        data,
        options,
    });
    return res;
}
export const duplicateEventById = async (id) => {
    const res = await auth({
        method: "POST",
        url:`/admin/events/duplicate/${id}`,
    });
    return res;
}
// ==== DELETE ====
export const deleteEventById = async (id) => {
    const res = await auth({
        method: "DELETE",
        url:`/admin/events/${id}`,
    });
    return res;
}
// ==== PUT ====
export const enableEventConnection = async (id) => {
    const res = await auth({
        method: "PUT",
        url:`/admin/events/connection/${id}`,
    });
    return res;
}

export const editEvent = async (id, data) => {
    const options = {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage?.getItem("adminSession") || ""}`,
        }
    }
    const res = await auth({
        method: "PUT",
        url: `/admin/events/${id}`,
        data,
        options,
    });
    return res;
}
export const updateEventParticipantStatus = async (data, status) => {
    const res = await auth({
        method: "PUT",
        url: `/admin/events/participant?status=${status}`,
        data,
    });
    return res;
}

// Event Comment Management
export const getEventsCommentsByEventId = async (id,page) => {
    const res = await auth({
        method: "GET",
        url: `/admin/events/comments/${id}?page=${page}&limit=10`,
    });
    return res;
}

export const updateEventsCommentStatus = async (id, status) => {
    const res = await auth({
        method: "PUT",
        url: `/admin/events/comment/status/${id}?status=${status}`,
    });
    return res;
}









