import React, { useState, useEffect } from 'react'
import { InputField } from "components"

function Index({ data, readOnly = true, handleOnSubmit, submit = false }) {

    const [userData, setUserData] = useState({
        education: data?.education,
        college: data?.college,
        profession: data?.profession,
    })

    const content = [
        { label: "Education", value: 'education' },
        { label: "Collage", value: 'college' },
        { label: "Profession", value: 'profession' },
    ]

    const updateState = (key, value) => {
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (submit) {
            handleOnSubmit(userData)
        }
    }, [submit])


    return (
        <div className='bg-white text-black -mt-4'>
            <div className="m-5">
                <h1 className='font-semibold text-xl pt-5'>Education Details</h1>
                <p className='text-gray-500 text-base'>Add time  that highlight what makes it unique <br />Event Time</p>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-[420px]">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between ">
                                <h1 className='font-semibold text-lg'>{item.label}</h1>
                                <div className="w-[70%]">
                                    <InputField value={userData[item.value]} readOnly={readOnly} onChange={(value) => updateState(item.value, value)} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)