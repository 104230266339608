import React, { useEffect, useState } from 'react'
import { MainNavbar, MainSidebar, ManagementSecMainHeader, Table } from 'components'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify"
import { EVENTS } from "../../../../common/routes.js"

import { deleteEventById, duplicateEventById, getAllEvents } from '../../../../apis/NEW/events'
import { formatStringToCapitalist } from '../../../../utils/dataConversion'
import { encodeToBase64 } from '../../../../utils/encodeAndDecode'

function Index() {

    const navigate = useNavigate()
    const [duplicate, setDuplicate] = useState(false)
    const [duplicateEventId, setDuplicateEventId] = useState()
    const [eventData, setEventData] = useState()
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Event Name', accessor: 'title', maxWidth: 100 },
        {
            Header: 'Event Category',
            accessor: (data) => data.categories.map(cat => cat.name).join(', ')
        },
        { Header: 'Event Timing', accessor: 'start_time' },
        { Header: 'Total Slots', accessor: 'total_slots' },
        { Header: 'Total Bookings', accessor: 'total_bookings' },
        { Header: 'Total Prebookings', accessor: 'total_prebookings' },
        { Header: 'Event Location', accessor: 'location' },
        {
            Header: 'Date',
            accessor: (data) => moment(data.start_date).format('YYYY-MM-DD')
        },
        {
            Header: 'Event Type',
            accessor: 'mode',
            Cell: ({ value }) => (
                <span className={`${value === 'online' ? 'text-green-500' : 'text-black'}`}>
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                </span>
            )
        },
        { Header: 'Event Status', accessor: 'publish_status' },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                    <button onClick={() => handleDuplicate(row)} className='p-2 mr-1 text-white bg-green-500 rounded-lg'>Duplicate</button>
                    <button onClick={() => handleView(row)} className='bg-[#6161f8] text-white mr-1 p-2 rounded-lg'>View</button>
                    {row.original.publish_status === 'Draft' && (
                        <button onClick={() => handleDelete(row)} className='p-2 mr-1 text-white bg-red-500 rounded-lg'>Delete</button>
                    )}
                </div>
            )
        }
    ];



    const handleEdit = (row) => {
        const id = encodeToBase64(row.original.id)
        navigate(`${EVENTS.EDIT_EVENT}/${id}`)
    };


    const handleDuplicate = (row) => {
        setDuplicate(!duplicate)
        setDuplicateEventId(row.original.id)
    };

    const duplicateEventApi = async () => {
        const res = await duplicateEventById(duplicateEventId)
        if (res?.success) {
            toast.success("Event Duplicated Successfully...")
        }
        fetchAllTheEvents(pageNation.currentPage)
        setDuplicate(!duplicate)
    }

    const handleView = (row) => {
        const slug = encodeToBase64(row.original.slug)
        const comment = false
        navigate(`${EVENTS.VIEW_EVENT}/${slug}/${comment}`)
    };

    const handleDelete = async (row) => {
        const res = await deleteEventById(row.original.id)
        if (res?.success) {
            toast.success("Event Deleted Successfully...")
        }
        fetchAllTheEvents(pageNation.currentPage)
    };

    const fetchAllTheEvents = async (page) => {
        const res = await getAllEvents(page)
        const event = res?.data?.events.map((event, index) => ({
            ...event,
            title: formatStringToCapitalist(event?.title),
            mode: formatStringToCapitalist(event?.mode),
            publish_status: formatStringToCapitalist(event?.publish_status),
            no: index + 1,
            // start_time: timeToHrAndMin(event?.start_time),
            categories: event?.categories?.map(cat => ({
                ...cat,
                name: formatStringToCapitalist(cat.name)
            }))
        }));
        setEventData(event)
        const data = res?.data?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        fetchAllTheEvents(pageNation.currentPage)
    }, [])

    const handleBtn = () => {
        navigate(EVENTS.CREATE_EVENT)
    }

    return (
        <div className='flex flex-col h-screen'>

            {duplicate &&
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="bg-white p-4 rounded-lg shadow-lg h-[290px] w-[454px] flex flex-col justify-center items-center text-center">
                        <h2 className="text-lg font-bold">Duplicate Event Confirmation</h2>
                        <p className="mt-2 mb-4 w-[308px] text-gray-500">Add time to highlight what makes it unique Event Time</p>
                        <div className="flex gap-5">
                            <button className="px-4 py-2 text-black bg-gray-300 rounded" onClick={() => setDuplicate(!duplicate)}>Cancel</button>
                            <button className="px-4 py-2 mr-2 text-white bg-red-500 rounded" onClick={() => duplicateEventApi()}>Confirm</button>
                        </div>
                    </div>
                </div>
            }
            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-screen w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <ManagementSecMainHeader
                            mainHeading={"Event"}
                            subHeading={"Manage your events effectively"}
                            btnText={"Add Event"}
                            handleBtn={handleBtn} />
                    </div>

                    {/* Table */}
                    <div className="p-8">
                        {eventData &&
                            <Table columns={columns} data={eventData} pageNation={pageNation} handlePageNation={(value) => fetchAllTheEvents(value)} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index