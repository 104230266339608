import React, { useEffect, useState } from 'react'
import { MainNavbar, MainSidebar } from 'components'
import { getAppUpdateVersion, updateAppVersion } from 'apis/NEW/appUpdate'
import { toast } from 'react-toastify'



function Index() {

    const [appVersion, setAppVersion] = useState()

    const getOldvertionApp = async () => {
        const res = await getAppUpdateVersion()
        if (res?.success) setAppVersion(res?.data)
    }

    const handleUpdateAppVersion = async () => {
        const res = await updateAppVersion({ version: appVersion })
        if (res?.success) toast.success('updated')
    }

    useEffect(() => {
        getOldvertionApp()
    }, [])

    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] min-h-screen h-full w-10/12">

                    <div className="w-[40%] p-8 mx-auto mt-20 bg-white rounded-lg shadow-lg">
                        <h2 className="mb-2 text-xl font-semibold">UPDATE VERSION</h2>
                        <p className="mb-6 text-sm text-gray-600">
                            Add time that highlight what makes it unique
                            <br />
                            Event Time
                        </p>

                        <div className='mb-5'>
                            <h1 className="block mb-2 text-sm font-bold text-gray-700">Current App Version</h1>
                            <input type="text" value={appVersion} className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                onChange={(e) => setAppVersion(e.target.value)}

                            />
                        </div>



                        <button onClick={handleUpdateAppVersion} className="w-full p-4 font-bold text-white bg-brandRed hover:bg-red-600">
                            Update
                        </button>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Index