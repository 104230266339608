import { Switch } from 'antd';
import { getUserAdvancedFilter, updateAdvancedFilter } from 'apis/NEW/users';
import { DropDown, InputField } from "components";
import { City } from 'country-state-city';
import React, { useEffect, useMemo, useState } from 'react';
import { fetchReligions, getCastesByReligion } from '../../../../../../apis/religionAndCaste';
import { couplesLookingForOptionsCouples, couplesMartialStatusOPtions, drinkingOptions, eatingOptions, educationOptions, singlesLookingForOptions, singlesMartialStatusOPtions, smokingOptions, workoutOptions } from '../../../../../../shared/constants';
import { addLabelAndValueInData } from '../../../../../../utils/dataConversion';


function Index({ decodedId, readOnly = true, submit = false, edit = false }) {

    const [religionAndCaste, setReligionAndCaste] = useState({
        religionOptions: [],
        castOptions: []
    })
    const [userData, setUserData] = useState()
    const cites = City.getCitiesOfCountry('IN')


    const updateState = (key, value) => {
        if (key === 'religion_id') {
            fetchCasteByReligionId(value)
        }
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    const fetchAllReligions = async () => {
        const res = await fetchReligions()
        const religion = addLabelAndValueInData(res, "name", 'id')
        setReligionAndCaste(prev => ({ ...prev, religionOptions: religion }))
    }

    // const fetchCasteByReligionId = async (id) => {
    //     const res = await getCastesByReligion(id)
    //     const cast = addLabelAndValueInData(res, "name", 'id')
    //     setReligionAndCaste(prev => ({ ...prev, castOptions: cast }))
    // }

    const fetchCasteByReligionId = async (ids) => {
        let allCastOptions = [];
        for (const id of ids) {
            const res = await getCastesByReligion(id);
            if (Array.isArray(res)) {
                allCastOptions = [...allCastOptions, ...res];
            }
        }
        const castWithLabel = addLabelAndValueInData(allCastOptions, 'name', 'id')
        setReligionAndCaste(prev => ({
            ...prev,
            castOptions: castWithLabel
        }));
    }


    const fetchAdvanceFilter = async () => {
        const res = await getUserAdvancedFilter(decodedId)

        const religionIds = res?.data?.advanced?.religion_id || [];
        const ids = religionIds?.map(religion => religion._id);
        const ReligionNames = religionIds?.map(religion => religion.name);

        const cast = res?.data?.advanced?.caste_id || [];
        const castIds = cast?.map(caste_id => caste_id._id);
        const castNames = cast?.map(caste_id => caste_id.name);

        setUserData({
            ...res?.data?.advanced,
            religion_id: edit ? ids : ReligionNames,
            caste_id: edit ? castIds : castNames,
            relationship_status: res?.data?.userId?.personal?.relationship_status,
            advanced_preferences_access: res?.advanced_preferences_access
        })
        fetchAllReligions()
        if (ids.length > 0) fetchCasteByReligionId(ids)
    }

    const handleUpdateAdvanceFilter = async (values) => {
        await updateAdvancedFilter(decodedId, values)
    }

    useEffect(() => {
        if (submit) {
            // eslint-disable-next-line unused-imports/no-unused-vars
            const { relationship_status, advanced_preferences_access, ...data } = userData
            handleUpdateAdvanceFilter(data)
        }
    }, [submit])

    useEffect(() => {
        fetchAdvanceFilter()
    }, [])



    const content = [
        { label: "looking For", value: 'looking_for', isDropdown: true, mode: "multiple", selected: userData?.looking_for, options: userData?.relationship_status === 'single' ? singlesLookingForOptions : couplesLookingForOptionsCouples, isVisible: true },
        { label: "Marital Status", value: 'marital_status', isDropdown: true, mode: "multiple", selected: userData?.marital_status, options: userData?.relationship_status === 'single' ? singlesMartialStatusOPtions : couplesMartialStatusOPtions, isVisible: true },
        { label: "Eating", value: 'eating', isDropdown: true, mode: "multiple", selected: userData?.eating, options: eatingOptions, isVisible: true },
        { label: "Smoking", value: 'smoking', isDropdown: true, mode: "multiple", selected: userData?.smoking, options: smokingOptions, isVisible: true },
        { label: "Workout", value: 'workout', isDropdown: true, mode: "multiple", selected: userData?.workout, options: workoutOptions, isVisible: true },
        { label: "Drinking", value: 'drinking', isDropdown: true, mode: "multiple", selected: userData?.drinking, options: drinkingOptions, isVisible: true },
        { label: "Education", value: 'education', isDropdown: true, mode: "", selected: userData?.education, options: educationOptions, isVisible: true },
        { label: "Religion", value: 'religion_id', isDropdown: true, mode: "multiple", selected: userData?.religion_id, options: religionAndCaste.religionOptions, isVisible: userData?.relationship_status === 'single' ? true : false },
        { label: "Cast", value: 'caste_id', isDropdown: true, mode: "multiple", selected: userData?.caste_id, options: religionAndCaste.castOptions, isVisible: userData?.relationship_status === 'single' ? true : false },
        { label: "City", value: 'city', isDropdown: true, mode: "", selected: userData?.city, options: useMemo(() => addLabelAndValueInData(cites, "name", "name"), [cites]), isVisible: true },

        { label: "Verified Profile", value: 'verified_profile', isDropdown: false, selected: userData?.verified_profile, isVisible: true },
        { label: "Show Everyone", value: 'show_everyone', isDropdown: false, selected: userData?.show_everyone, isVisible: userData?.relationship_status === 'single' ? false : true },
        // { label: "TimeLine", value: 'timeLine', options: timeLineOptions },
    ]

    return (
        <div className='-mt-4 text-black bg-white'>
            <div className="m-5">
                <h1 className='pt-5 text-xl font-semibold'>Advance Filter</h1>
                <p className='text-base text-gray-500'>Add time  that highlight what makes it unique <br />Event Time</p>
                <div className="flex justify-between">
                    {/* Content Section */}
                    {userData?.advanced_preferences_access ?

                        <div className="w-full h-[1200x]">
                            {content.map((item, index) => (
                                <div key={index} className="flex items-center justify-between ">
                                    {item.isDropdown ?
                                        <h1 className='text-lg font-semibold'>{item.label}</h1>
                                        : <>
                                            {!item.isDropdown && item.isVisible &&
                                                <h1 className='text-lg font-semibold'>{item.label}</h1>
                                            }
                                        </>
                                    }
                                    <div className={`${edit ? 'w-[70%]' : 'w-full'} flex justify-end items-center`}>
                                        {edit ?
                                            <>
                                                {item.isDropdown && item.isVisible ?
                                                    <DropDown
                                                        selectedValue={item.selected}
                                                        options={item.options}
                                                        readOnly={readOnly}
                                                        mode={item.mode}
                                                        handleOnChange={(value) => updateState(item.value, value)}
                                                    />
                                                    :
                                                    <>
                                                        {item.isVisible &&
                                                            <Switch checked={item.selected} onChange={() => updateState(item.value, !item.selected)} disabled={readOnly} className={true ? 'bg-gray-700 my-5' : 'bg-gray-400 my-5'} />
                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                {item.isDropdown ?
                                                    <InputField readOnly={true} value={item.selected} />
                                                    : <>
                                                        {!item.isDropdown && item.isVisible &&
                                                            <InputField readOnly={true} value={item.selected} />
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div className="text-4xl font-bold">No Access to Advance Filter</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)