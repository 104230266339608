import React, { useState } from 'react'
import CouponContentRender from '../couponManagement/CouponContentRender';

const index = ({ data, handleSubmit, handleCancel, isEdit }) => {

    const [referralData, setReferralData] = useState({
        influencer_name: data?.influencer_name,
        influencer_email: data?.influencer_email,
        influencer_phone: data?.influencer_phone,
        referral_code: data?.referral_code,
        coins: data?.coins,
        coins_validity: data?.coins_validity,
        ...(isEdit && { status: data?.status }),
    });

    const content = [
        { label: 'Affiliate Name', value: 'influencer_name', inputType: 'singleInput', type: 'text' },
        { label: 'Email', value: 'influencer_email', inputType: 'singleInput', type: 'email' },
        { label: 'Phone', value: 'influencer_phone', inputType: 'singleInput', type: 'text' },
        { label: 'Referral Code', value: 'referral_code', inputType: 'singleInput', type: 'text' },
        { label: 'Coins', value: 'coins', inputType: 'singleInput', type: 'number'},
        { label: 'Validity', value: 'coins_validity', inputType: 'singleInput', type: 'number' },
    ];

    if(isEdit){
        content.push(
            { label: 'Status', valueToggleBtn: 'status', inputType: 'toggleBtn', type: 'boolean' },
        )
    }

    const updateState = (key, value) => {
        setReferralData(prev => ({ ...prev, [key]: value }))
    };


  return (
    <div className='w-[96%] mx-auto'> 
        <div className=" w-full p-6 bg-white shadow-md rounded-md">
                <h2 className="text-xl font-bold mb-4">AFFILIATE COUPONS</h2>
                <p className="mb-2">Add time that highlight what makes it unique</p>

                <div className="w-full flex flex-wrap flex-col lg:flex-row justify-center items-start my-2 ">
                    {content && content.length > 0 && (
                        <div className="w-full">
                            <CouponContentRender content={content} updateState={updateState} couponData={referralData}/>
                        </div>
                    )}
                </div>
                <div className="flex flex-col w-full justify-center mt-10 gap-5">
                    <button type="button" className="px-4 py-2 border-2 border-gray-500 text-black w-full h-[50px]" onClick={handleCancel}>Cancel</button>
                    <button type="submit" className="px-4 py-2 bg-brandRed text-white w-full h-[50px]" onClick={() => handleSubmit(referralData)}>{isEdit ? 'Save Changes' : 'Create'}</button>
                </div>
        </div>
        
    </div>
  )
}

export default index