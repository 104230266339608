import React, { useState } from 'react';
import { capitalize } from 'shared/textConverter';

function Index({ handleShowFilter, handleFilterData, filterOptions }) {

    const [selectedCheckbox, setSelectedCheckbox] = useState(filterOptions || null)
    const dropDownContent = ["requested", "deleted", "cancelled"]


    return (
        <div className="w-[95%] h-full flex flex-col items-center mx-auto mb-4">
            <div className="w-full m-2 flex items-center justify-between text-xl font-semibold">
                <h1>Filter</h1>
                <button onClick={handleShowFilter} className="absolute top-0 right-0 mr-2 text-black text-xl leading-none p-2">
                    &#x2715;
                </button>
            </div>

            <div className="flex-grow w-full flex flex-col items-center">
                {dropDownContent.map((item, index) => (
                    <div key={index} className="w-[90%] flex justify-between">
                        <div className="flex items-center my-5 mt-6">
                            <input type="checkbox" className="mr-2 h-[15px] w-[15px]" checked={selectedCheckbox === item}
                                onChange={() => setSelectedCheckbox(item)} />
                            <span className='text-base'>{capitalize(item)}</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="w-full flex items-center justify-center pb-8 mt-auto">
                <button className='w-[110px] h-[40px] border-2 me-3' onClick={() => setSelectedCheckbox(null)}>Reset</button>
                <button className='w-[110px] h-[40px] bg-brandRed text-white' onClick={() => {
                    handleShowFilter()
                    handleFilterData(selectedCheckbox)
                }}>Filter</button>
            </div>
        </div>

    );
}

export default React.memo(Index)
