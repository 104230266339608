import { fetchUserDetails } from "apis/NEW/users";
import jsPDF from "jspdf";
import { capitalize } from "shared/textConverter";

// const getImageBase64FromURL = async (url) => {
//     try {
//         console.log('url',url)
//         const response = await axios.get(url);
//         console.log('response',response)
//       const base64 = Buffer.from(response.data, 'binary').toString('base64');
//       return `data:${response.headers['content-type']};base64,${base64}`;
//     } catch (error) {
//       console.error(`Error fetching image: ${error}`);
//       return null; // Return null if the image cannot be fetched
//     }
//   };

export const downloadGroomsDetails = async (id) => {
  const res = await fetchUserDetails(id);

  const userData = res?.data[0];

  const doc = new jsPDF();
  const pageHeight = doc.internal.pageSize.height;
  let y = 10;

  const addText = (text, x, y) => {
    // Check if the text exceeds the page height
    if (y > pageHeight - 20) {
      doc.addPage();
      y = 10; // Reset y for the new page
    }
    doc.text(capitalize(text), x, y);
    return y + 10; // Increment y by 10 for the next line
  };

  doc.setFontSize(18);
  y = addText('Basic Details', 10, y);
  doc.setFontSize(12);

  y = addText(`Name: ${userData?.name || 'NIL'}`, 10, y);
  y = addText(`Email: ${userData?.email || 'NIL'}`, 10, y);
  y = addText(`Phone: ${userData?.phone || 'NIL'}`, 10, y);
  y = addText(`Gender: ${userData?.personal?.sex || 'NIL'}`, 10, y);
  y = addText(`Relationship Status: ${userData?.personal?.relationship_status || 'NIL'}`, 10, y);
  y = addText(`Marital Status: ${userData?.personal?.marital_status || 'NIL'}`, 10, y);
  y = addText(`Looking For: ${userData?.personal?.looking_for || 'NIL'}`, 10, y);

  y = addText('Interests:', 10, y);
  if (userData.interests && userData.interests.length > 0) {
    userData.interests.forEach((interest) => {
      y = addText(`- ${interest}`, 20, y);
    });
  } else {
    y = addText('NIL', 20, y);
  }

  y += 10; // Add space between sections
  doc.setFontSize(18);
  y = addText('Physical Details', 10, y);
  doc.setFontSize(12);

  y = addText(`Height: ${userData?.personal?.height || 'NIL'}`, 10, y);
  y = addText(`Weight: ${userData?.personal?.weight || 'NIL'}`, 10, y);
  y = addText(`Physical Status: ${userData?.personal?.physical_status || 'NIL'}`, 10, y);

  y += 10;
  doc.setFontSize(18);
  y = addText('Education Details', 10, y);
  doc.setFontSize(12);

  y = addText(`Education: ${userData?.personal?.education || 'NIL'}`, 10, y);
  y = addText(`College: ${userData?.personal?.college || 'NIL'}`, 10, y);
  y = addText(`Profession: ${userData?.personal?.profession || 'NIL'}`, 10, y);

  y += 10;
  doc.setFontSize(18);
  y = addText('Diet Details', 10, y);
  doc.setFontSize(12);

  y = addText(`Drinking: ${userData?.misc?.drinking || 'NIL'}`, 10, y);
  y = addText(`Smoking: ${userData?.misc?.smoking || 'NIL'}`, 10, y);
  y = addText(`Eating: ${userData?.misc?.eating || 'NIL'}`, 10, y);

  y += 10;
  doc.setFontSize(18);
  y = addText('Family', 10, y);
  doc.setFontSize(12);

  y = addText(`Parent Name: ${userData?.family?.parent_name || 'NIL'}`, 10, y);
  y = addText(`Relationship: ${userData?.family?.relationship || 'NIL'}`, 10, y);
  y = addText(`Contact Number: ${userData?.family?.contact_number || 'NIL'}`, 10, y);

  y += 10;
  doc.setFontSize(18);
  y = addText('Language Details', 10, y);
  doc.setFontSize(12);

  y = addText(`Mother Tongue: ${userData?.languages_known?.mother_tongue || 'NIL'}`, 10, y);
  y = addText('Languages Known:', 10, y);

  if (userData?.languages_known?.others && userData?.languages_known?.others?.length > 0) {
    userData?.languages_known?.others.forEach((language) => {
      y = addText(`- ${language}`, 20, y);
    });
  } else {
    y = addText('NIL', 20, y);
  }

  y += 10;
  doc.setFontSize(18);
  y = addText('Religion and Caste', 10, y);
  doc.setFontSize(12);

  y = addText(`Religion: ${userData?.religion || 'NIL'}`, 10, y);
  y = addText(`Caste: ${userData?.caste || 'NIL'}`, 10, y);

  y += 10;
  doc.setFontSize(18);
  y = addText('Address', 10, y);
  doc.setFontSize(12);

  y = addText(`Country: ${userData?.address?.country || 'NIL'}`, 10, y);
  y = addText(`State: ${userData?.address?.state || 'NIL'}`, 10, y);
  y = addText(`Current City: ${userData?.address?.current_city || 'NIL'}`, 10, y);
y = addText(`Citizenship: ${userData?.address?.citizenship || 'NIL'}`, 10, y);
    

// if (userData?.images && userData?.images.length > 0) {
//     for (const image of userData.images) {
//       if (y > pageHeight - 60) { // Space to accommodate images
//         doc.addPage();
//         y = 10;
//       }

//       // Fetch and convert image to Base64
//       const imgBase64 = await getImageBase64FromURL(image.url);

//       // Add the image to the PDF
//       doc.addImage(imgBase64, 'JPEG', 10, y, 50, 50 * image.aspect_ratio);
//       y += 60; // Spacing between images
//     }
//   } else {
//     y = addText('NIL', 10, y);
    //   }
    

  doc.save(`${userData?.name}.pdf`);
};
