import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { Table } from "components"
import { getReferredUsers } from 'apis/NEW/users'

function Index({ decodedId }) {

    const [users, setUsers] = useState()
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Phone', accessor: 'phone' },
        { Header: 'Category', accessor: 'personal.relationship_status' },
        // { Header: 'Date of join', accessor: 'date_of_join' }, 
        {
            Header: 'Status',
            accessor: 'account_blocked',
            Cell: ({ value }) => (
                <>
                    {value ?
                        <span className='bg-red-100 text-red-500 rounded-3xl p-2'>
                            Inactive
                        </span>
                        :
                        <span className='bg-green-100 text-green-500 rounded-3xl p-2'>
                            Active
                        </span>
                    }
                </>
            )
        },
        {
            Header: 'Verified',
            accessor: 'verification.status',
            Cell: ({ value }) => (
                <>
                    {value === 'verified' ?
                        <span className='bg-green-100 text-green-500 rounded-3xl p-2'>
                            Verified
                        </span>
                        :
                        <span className='bg-red-100 text-red-500 rounded-3xl p-2'>
                            Not Verified
                        </span>
                    }
                </>
            )
        },
    ];


    const fetchReferredUsers = async (page) => {
        const res = await getReferredUsers(decodedId, page)
        setUsers(res?.data?.referredUsers)
        const data = res?.data?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }


    useEffect(() => {
        fetchReferredUsers(pageNation.currentPage)
    }, [])


    return (
        <div>
            {users &&
                <Table columns={columns} data={users} pageNation={pageNation} handlePageNation={(value) => fetchReferredUsers(value)} />

            }

        </div>
    )
}

export default React.memo(Index)