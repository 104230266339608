import React, { useState } from 'react';
import { InputField, DropDown } from "components"

import { toast } from "react-toastify"


function Index({ handleClose, handleSubmit, data }) {
    const [tagData, setTagData] = useState({
        name: data?.name || null,
        status: data?.status ?? true
    });


    const updateState = (key, value) => {
        setTagData(prev => ({ ...prev, [key]: value }))
    }


    const handleCreateBtn = () => {
        if (tagData.name) {
            handleSubmit(tagData)
        } else {
            toast.error("please fill all the Fields")
        }
    }



    return (
        <div className="flex items-center justify-center w-full h-full">
            <div className="relative w-full max-w-md p-6 text-black bg-white rounded-lg shadow-lg">
                <button
                    onClick={handleClose}
                    className="absolute top-0 right-0 p-2 mt-2 mr-2 text-xl leading-none text-black"
                >
                    &#x2715;
                </button>
                <h2 className="mb-6 text-xl font-semibold text-start">Create Tags</h2>
                <div className="space-y-4">
                    <h1 className='text-[16px]'>Tag Name</h1>
                    <InputField value={tagData.name} onChange={(value) => updateState("name", value)} type={'text'} />

                    <h1 className='text-[16px] mt-4 -mb-3'>Status</h1>
                    <div className="h-[56px] w-full my-8 flex flex-col justify-center rounded-3xl">
                        <DropDown
                            selectedValue={tagData.status ? 'yes' : "no"}
                            placeholderValue={""}
                            options={[{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]}
                            handleOnChange={() => updateState("status", !tagData.status)}
                        />
                    </div>


                    <button
                        onClick={handleCreateBtn}
                        type="button"
                        className="w-full px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                    >
                        Create
                    </button>

                </div>
            </div>
        </div>

    );
}

export default React.memo(Index)
