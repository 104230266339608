import { MainNavbar, MainSidebar } from "components"
import React, { Suspense, lazy, useState } from 'react'
import { useParams } from 'react-router-dom'
import { decodeFromBase64 } from 'utils/encodeAndDecode'

const UserBasicInfo = lazy(() => import('components').then(module => ({ default: module.UserBasicInfo })))
const UserPrivacySettings = lazy(() => import('components').then(module => ({ default: module.UserPrivacySettings })))
const UserConnections = lazy(() => import('components').then(module => ({ default: module.UserConnections })))
const UserEvents = lazy(() => import('components').then(module => ({ default: module.UserEvents })))
const UserWallet = lazy(() => import('components').then(module => ({ default: module.UserWallet })))
const UserReffrel = lazy(() => import('components').then(module => ({ default: module.UserReffrel })))
const UserHistory = lazy(() => import('components').then(module => ({ default: module.UserHistory })))


function Index() {
    const { id } = useParams();
    const decodedId = decodeFromBase64(id)

    const [currentTab, setCurrentTab] = useState('Basic Info')
    const [readOnly, setReadOnly] = useState(true)
    const [submit, setSubmit] = useState(false)
    const header = ['Basic Info', 'User Privacy Settings', 'Connections', 'Events', 'Wallet', 'Referel', 'History']



    function getTabComponent(tabName) {
        switch (tabName) {
            case 'Basic Info':
                return <UserBasicInfo decodedId={decodedId} readOnly={readOnly} submit={submit} edit={true} />;
            case 'User Privacy Settings':
                return <UserPrivacySettings decodedId={decodedId} readOnly={readOnly} submit={submit} edit={true} />;
            case 'Connections':
                return <UserConnections decodedId={decodedId} edit={true} />;
            case 'Events':
                return <UserEvents decodedId={decodedId} edit={true} />;
            case 'Wallet':
                return <UserWallet decodedId={decodedId} edit={true} />;
            case 'Referel':
                return <UserReffrel decodedId={decodedId} edit={true} />;
            case 'History':
                return <UserHistory decodedId={decodedId} edit={true} />;
            default:
                return <div>Select a tab</div>;
        }
    }


    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="h-screen w-2/12">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-full w-10/12">
                    {/* Header */}
                    <div className='flex overflow-x-auto m-10 mb-5 h-[60px] bg-white justify-around' style={{ scrollbarWidth: 'none' }}>
                        {header.map((item, index) => (
                            <h1
                                className={`p-3 w-full cursor-pointer ${currentTab === item ? 'text-white bg-brandRed rounded-t-lg' : 'text-black'}`}
                                key={index}
                                onClick={() => {
                                    setSubmit(false)
                                    setReadOnly(true)
                                    setCurrentTab(item)
                                }}
                            >
                                {item}
                            </h1>
                        ))}
                    </div>
                    {/* Edit BTN Section */}
                    <div className="flex items-end justify-end space-x-2 me-10">
                        {/* Cancel */}
                        {!readOnly &&
                            <div
                                className="border-2 border-gray-700 p-1 px-4 h-fit cursor-pointer flex items-center gap-2"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setReadOnly(!readOnly)
                                }}>
                                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.0562 12.4062L6.5 7.85L1.94375 12.4062L0.59375 11.0562L5.15 6.5L0.59375 1.94375L1.94375 0.59375L6.5 5.15L11.0562 0.59375L12.4062 1.94375L7.85 6.5L12.4062 11.0562L11.0562 12.4062Z" fill="black" />
                                </svg>
                                <h1>Cancel</h1>
                            </div>
                        }
                        {/* Edit and Submit BTN */}
                        <div className="border-2 border-gray-700 p-1 px-4 h-fit cursor-pointer">
                            {readOnly ?
                                <div className="flex items-center gap-2" onClick={(e) => {
                                    e.stopPropagation();
                                    setSubmit(false)
                                    setReadOnly(!readOnly);
                                }}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_5791_10051)">
                                            <path d="M13 3C13.2549 3.00028 13.5 3.09788 13.6854 3.27285C13.8707 3.44782 13.9822 3.68695 13.9972 3.94139C14.0121 4.19584 13.9293 4.44638 13.7657 4.64183C13.6021 4.83729 13.3701 4.9629 13.117 4.993L13 5H5V19H19V11C19.0003 10.7451 19.0979 10.5 19.2728 10.3146C19.4478 10.1293 19.687 10.0178 19.9414 10.0028C20.1958 9.98789 20.4464 10.0707 20.6418 10.2343C20.8373 10.3979 20.9629 10.6299 20.993 10.883L21 11V19C21.0002 19.5046 20.8096 19.9906 20.4665 20.3605C20.1234 20.7305 19.6532 20.9572 19.15 20.995L19 21H5C4.49542 21.0002 4.00943 20.8096 3.63945 20.4665C3.26947 20.1234 3.04284 19.6532 3.005 19.15L3 19V5C2.99984 4.49542 3.19041 4.00943 3.5335 3.63945C3.87659 3.26947 4.34684 3.04284 4.85 3.005L5 3H13ZM19.243 3.343C19.423 3.16365 19.6644 3.05953 19.9184 3.05177C20.1723 3.04402 20.4197 3.13322 20.6103 3.30125C20.8008 3.46928 20.9203 3.70355 20.9444 3.95647C20.9685 4.2094 20.8954 4.46201 20.74 4.663L20.657 4.758L10.757 14.657C10.577 14.8363 10.3356 14.9405 10.0816 14.9482C9.82767 14.956 9.58029 14.8668 9.38972 14.6988C9.19916 14.5307 9.07969 14.2964 9.0556 14.0435C9.03151 13.7906 9.10459 13.538 9.26 13.337L9.343 13.243L19.243 3.343Z" fill="black" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_5791_10051">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <p>Edit</p>
                                </div>
                                :
                                <div className="" onClick={() => {
                                    setSubmit(true)
                                    setReadOnly(!readOnly)
                                }}>Submit</div>
                            }
                        </div>
                    </div>
                    {/* Content Section */}
                    <div className="p-8 pt-0">
                        <Suspense fallback={<div>Loading...</div>}>
                            {getTabComponent(currentTab)}
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index