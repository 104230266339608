import React, { useState } from 'react';
import csLogo from "../../../../assets/icons/csLogo.png"
import searchIcon from "../../../../assets/icons/searchRedIcon.svg"
import { Link } from 'react-router-dom';
import { SIDEBAR } from 'common/routes';

function Index() {

    const [selectedOption, setSelectedOption] = useState(null);
    const [dropDown, setDropDown] = useState(false)
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setDropDown(!dropDown)
    };

    const options = [
        { logo: csLogo, mainText: 'Abin', subText: 'super admin' },
        { logo: csLogo, mainText: 'Abin1', subText: 'Admin' },
    ]

    return (
        <nav className="flex items-center justify-between p-4 h-full">
            <div className="flex items-center">
                <div className="mr-4">
                    {/* Insert your logo here */}
                    <Link to={SIDEBAR.HOME_OVERVIEW}>
                        <img src={csLogo} alt="Logo" className="w-auto h-auto ms-4" />
                    </Link>
                </div>
                <div className="h-16 w-[22rem] ms-28 flex items-center justify-between rounded-xl overflow-hidden">
                    <input type="text" className="px-2 py-1 outline-none bg-white" placeholder="Search here" />
                    <img src={searchIcon} alt="" className="h-5 w-5 mx-3" />
                </div>
            </div>
            <div className="flex items-center">
                <div className="flex items-center gap-x-4 me-3">
                    <span className="mr-2">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25.4704 19.1861L23.333 15.9801V11.6666C23.333 9.19123 22.3497 6.81726 20.5993 5.06692C18.849 3.31658 16.475 2.33325 13.9997 2.33325C11.5243 2.33325 9.15036 3.31658 7.40002 5.06692C5.64968 6.81726 4.66635 9.19123 4.66635 11.6666V15.9801L2.52902 19.1861C2.41226 19.3619 2.34532 19.566 2.3353 19.7768C2.32529 19.9876 2.37258 20.1971 2.47214 20.3832C2.57171 20.5692 2.71983 20.7248 2.90076 20.8334C3.08169 20.942 3.28867 20.9996 3.49968 20.9999H24.4997C24.7107 20.9996 24.9177 20.942 25.0986 20.8334C25.2795 20.7248 25.4277 20.5692 25.5272 20.3832C25.6268 20.1971 25.6741 19.9876 25.6641 19.7768C25.6541 19.566 25.5871 19.3619 25.4704 19.1861Z" fill="#5D6577" />
                            <path d="M13.9994 25.6668C15.0338 25.6677 16.0391 25.3242 16.8566 24.6904C17.6741 24.0566 18.2574 23.1687 18.5144 22.1667H9.48438C9.74135 23.1687 10.3246 24.0566 11.1421 24.6904C11.9597 25.3242 12.965 25.6677 13.9994 25.6668Z" fill="#5D6577" />
                        </svg>

                    </span>
                    <span className="mr-2">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.33398 23.3334C2.33675 24.2608 2.70639 25.1495 3.36217 25.8052C4.01795 26.461 4.90658 26.8306 5.83398 26.8334H12.834V15.1667H2.33398V23.3334Z" fill="#5D6577" />
                            <path d="M15.166 26.8334H22.166C23.0934 26.8306 23.9821 26.461 24.6378 25.8052C25.2936 25.1495 25.6632 24.2608 25.666 23.3334V15.1667H15.166V26.8334Z" fill="#5D6577" />
                            <path d="M23.3327 5.83317H19.6227C19.764 5.46054 19.8352 5.06501 19.8327 4.6665C19.8299 3.7391 19.4603 2.85047 18.8045 2.19469C18.1487 1.53891 17.2601 1.16927 16.3327 1.1665C15.4687 1.16876 14.6367 1.49325 13.9993 2.0765C13.362 1.49325 12.53 1.16876 11.666 1.1665C10.7386 1.16927 9.84997 1.53891 9.1942 2.19469C8.53842 2.85047 8.16878 3.7391 8.16601 4.6665C8.16353 5.06501 8.23473 5.46054 8.37601 5.83317H4.66601C3.73861 5.83594 2.84998 6.20558 2.1942 6.86136C1.53842 7.51714 1.16879 8.40577 1.16602 9.33317V11.6665C1.16602 11.9759 1.28893 12.2727 1.50772 12.4915C1.72652 12.7103 2.02326 12.8332 2.33268 12.8332H12.8327V5.83317H11.666C11.4353 5.83317 11.2097 5.76475 11.0178 5.63655C10.826 5.50836 10.6765 5.32615 10.5882 5.11297C10.4998 4.89979 10.4767 4.66521 10.5218 4.4389C10.5668 4.21259 10.6779 4.00471 10.8411 3.84155C11.0042 3.67838 11.2121 3.56727 11.4384 3.52225C11.6647 3.47724 11.8993 3.50034 12.1125 3.58864C12.3257 3.67695 12.5079 3.82648 12.6361 4.01834C12.7643 4.2102 12.8327 4.43576 12.8327 4.6665V5.83317H15.166V4.6665C15.166 4.43576 15.2344 4.2102 15.3626 4.01834C15.4908 3.82648 15.673 3.67695 15.8862 3.58864C16.0994 3.50034 16.334 3.47724 16.5603 3.52225C16.7866 3.56727 16.9945 3.67838 17.1576 3.84155C17.3208 4.00471 17.4319 4.21259 17.4769 4.4389C17.5219 4.66521 17.4988 4.89979 17.4105 5.11297C17.3222 5.32615 17.1727 5.50836 16.9808 5.63655C16.789 5.76475 16.5634 5.83317 16.3327 5.83317H15.166V12.8332H25.666C25.9754 12.8332 26.2722 12.7103 26.491 12.4915C26.7098 12.2727 26.8327 11.9759 26.8327 11.6665V9.33317C26.8299 8.40577 26.4603 7.51714 25.8045 6.86136C25.1487 6.20558 24.2601 5.83594 23.3327 5.83317Z" fill="#5D6577" />
                        </svg>

                    </span>
                </div>
                {/* Dropdown */}
                <div className="relative inline-block text-black">
                    <div className="flex items-center border border-gray-300 rounded-md p-2 px-4 w-[220px] h-[55px] cursor-pointer" onClick={() => setDropDown(!dropDown)}>
                        {selectedOption && (
                            <>
                                <img src={selectedOption.logo} alt="img" className="mr-2 w-[60px] h-[30px]" />
                                <div className='flex flex-col text-start'>
                                    <p>{selectedOption.mainText}</p>
                                    <p className="text-gray-500">{selectedOption.subText}</p>
                                </div>
                            </>
                        )}
                        {!selectedOption && <p>Select an option</p>}
                    </div>
                    {dropDown &&
                        <div className="absolute top-full left-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md overflow-hidden">
                            <div className="flex flex-col">
                                {options.map((option, index) => (
                                    <div key={index} className="p-2 flex px-4 cursor-pointer hover:bg-gray-100 items-center" onClick={() => handleOptionClick(option)}>
                                        <img src={option.logo} alt="img" className="mr-2 w-[60px] h-[30px]" />
                                        <div className='flex flex-col text-start'>
                                            <p>{option.mainText}</p>
                                            <p className="text-gray-500">{option.subText}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>


            </div>
        </nav>
    );
}

export default Index;
