import React, { memo, useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup';
import { DropDown, RadioBtnWithIcon, RangeSliderDoublePointer } from "components"

import { singlesMartialStatusOPtions, couplesMartialStatusOPtions, genderOptions } from "../../../../../shared/constants"
import { pageOneValidation } from '../../../../../utils/validations/event'

// Assets
import singleBlackIcon from "../../../../../assets/createNewEvent/icons/singleBlack.png"
import singleRedIcon from "../../../../../assets/createNewEvent/icons/singleRed.png"
import couplesBlackIcon from "../../../../../assets/createNewEvent/icons/couplesBlack.png"
import couplesRedIcon from "../../../../../assets/createNewEvent/icons/couplesRed.png"


function Index({ prevPage, nextPage, data, edit = false }) {
    const [eventData, setEventData] = useState({
        relationship_status: data?.relationship_status || null,
        gender: data?.gender || null,
        age_from: data?.age_from || 18,
        age_to: data?.age_to || 55,
        marital_status: data?.marital_status || []
    })
    const [readOnly, setReadOnly] = useState(edit)


    const eventType = [
        { icon: singleBlackIcon, isSelectedIcon: singleRedIcon, text: "single" },
        { icon: couplesBlackIcon, isSelectedIcon: couplesRedIcon, text: "couple" },
    ]


    const handleInputChange = (name, valueOrArray) => {
        setEventData(prev => {
            const updatedState = { ...prev, [name]: valueOrArray };
            if (name === "relationship_status") {
                return { ...updatedState, marital_status: [] };
            }
            return updatedState;
        });
    }

    const handleNextPage = async () => {
        try {
            await pageOneValidation.validate(eventData, { abortEarly: false });
            nextPage(eventData, 2);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const handlePrevPage = () => {
        prevPage(eventData, 1);
    }

    const handleEdit = async () => {
        try {
            setReadOnly(!readOnly)
            if (!readOnly) {
                await pageOneValidation.validate(eventData, { abortEarly: false });
                nextPage(eventData)
            }
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                toast.error(error.inner[0].message);
            } else {
                toast.error('Something went wrong');
            }
        }
    }

    const dropdownContent = [
        { key: "marital_status", value: eventData.marital_status, placeHolder: "Select Martial Status", options: eventData.relationship_status === "single" ? singlesMartialStatusOPtions : couplesMartialStatusOPtions, mode: "multiple" },
        { key: "gender", value: eventData.gender, placeHolder: "Gender", options: genderOptions, mode: "" },
    ]

    return (
        <div>
            <div className="flex justify-between mt-16 mb-5">
                <h1 className='my-5 text-lg font-semibold'>What type of event are you creating</h1>
                {edit &&
                    <div className="p-1 px-4 border-2 border-gray-700 cursor-pointer h-fit"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit()
                        }}>
                        {!readOnly ? 'submit' : 'Edit'}
                    </div>
                }
            </div>
            {eventType.map((item, index) => (
                <div key={index} className="p-4 my-4 w-[400px] h-[68px]">
                    <RadioBtnWithIcon
                        readOnly={readOnly}
                        icon={item.icon}
                        isSelectedIcon={item.isSelectedIcon}
                        text={item.text}
                        isSelected={item.text === eventData.relationship_status ? true : false}
                        onChange={() => handleInputChange("relationship_status", item.text)}
                    />
                </div>

            ))}
            <h1 className='mt-16 mb-5 text-lg font-semibold'>What is the <span className='text-brandRed'> Age limit </span> and marital status needed for this event </h1>
            <h1 className='my-5 text-sm'>Age Limit</h1>
            <div className="h-[120px] w-[400px] flex flex-col justify-center border-2 border-gray-300 rounded-3xl">
                <div className="ms-5">
                    <p className='text-sm text-gray-500'>{`Age Between ${eventData.age_from} to ${eventData.age_to}`}</p>
                    <RangeSliderDoublePointer
                        readOnly={readOnly}
                        minMaxRange={[18, 55]}
                        selectedMinMaxValue={[eventData.age_from, eventData.age_to]}
                        onRangeChange={(values) => {
                            handleInputChange('age_from', values[0]);
                            handleInputChange('age_to', values[1]);
                        }}
                    />
                </div>
            </div>

            {dropdownContent.map((item, index) => (
                <div key={index} className="h-[56px] w-[400px] mt-8 flex flex-col justify-center rounded-3xl">
                    <DropDown
                        readOnly={readOnly}
                        selectedValue={item?.value}
                        placeholderValue={item?.placeHolder}
                        options={item?.options}
                        handleOnChange={(value) => handleInputChange(item?.key, value)}
                        mode={item?.mode}
                    />
                </div>
            ))}
            {/* <div className="h-[56px] w-[400px] mt-8 mb-20 flex flex-col justify-center rounded-3xl">
                <DropDown
                    readOnly={readOnly}
                    selectedValue={eventData.marital_status}
                    placeholderValue={"Select Martial Status"}
                    options={eventData.relationship_status === "single" ? singlesMartialStatusOPtions : couplesMartialStatusOPtions}
                    handleOnChange={(value) => handleInputChange("marital_status", value)}
                    mode={"multiple"}
                />
            </div> */}


            {edit === false &&
                <div className="flex items-center justify-center gap-5 mx-auto mb-20 font-bold text-black bg-white rounded-lg shadow-lg w-fit">
                    <button className='p-2 px-4 border-gray-300 border-e-2 hover:text-gray-700 hover:font-medium hover:border-e-0 hover:border-b-4 hover:border-brandRed' onClick={handlePrevPage}>Back</button>
                    <button className='p-2 px-4 hover:text-gray-700 hover:font-medium hover:border-b-4 hover:border-brandRed' onClick={handleNextPage}>Next</button>
                </div>
            }
        </div>
    )
}

export default memo(Index)