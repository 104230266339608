import React from "react";
import Title from '../../shared/Title';

export default function Error404() {
  return (
    <>
      <Title title={"404"} />
    </>
  )
}
