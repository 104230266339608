import React, { useState, useEffect, useCallback, lazy, Suspense } from 'react'
import { MainSidebar, Table, MainNavbar, ManagementSecMainHeader, UserVerification } from "components"

import { getAllUserBasedOnStatus } from 'apis/NEW/verification/index.js'
import { formatDateToDDMMYY } from 'shared/dates.js';
import { capitalize } from 'shared/textConverter.js';
import { USERS } from 'common/routes';
import { encodeToBase64 } from 'utils/encodeAndDecode';

const NameVerification = lazy(() => import('components/NEW/verificationManagement/verificationProcess/name'))
const IdVerification = lazy(() => import('components/NEW/verificationManagement/verificationProcess/id'))
const PhotoVerification = lazy(() => import('components/NEW/verificationManagement/verificationProcess/photo'))
const StatusVerification = lazy(() => import('components/NEW/verificationManagement/verificationProcess/status'))


function Index() {

    const [userData, setUserData] = useState()
    const [selectedUser, setSelectedUser] = useState()
    const [isVisible, setIsVisible] = useState({
        table: true,
        NAME: false,
        ID: false,
        PHOTO: false,
        STATUS: false,
    })
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const headerContent = ['NAME', 'ID', 'PHOTO', 'STATUS']

    const columns = [

        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Id Type', accessor: (row) => capitalize(row.id_type) },
        { Header: 'Date', accessor: (row) => formatDateToDDMMYY(row.createdAt) },
        { Header: 'Status', accessor: (row) => capitalize(row.status) },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleView(row)} className='p-2 px-3 mr-1 text-white bg-green-500 rounded-xl'>View</button>
                </div>
            )
        }
    ];

    const handleView = (row) => {
        setSelectedUser(row.original)
        handleChangeVisibility('NAME')
    }

    const handleChangeVisibility = useCallback((key) => {
        setIsVisible(prev => ({
            ...Object.keys(prev).reduce((acc, currentKey) => {
                acc[currentKey] = false;
                return acc;
            }, {}),
            [key]: true
        }));
    }, []);


    const fetchAllUserBasedOnStatus = async (page, status = '', search = '') => {
        const selectedStatus = localStorage.getItem('userVerificationFilter') || status
        const res = await getAllUserBasedOnStatus(page, selectedStatus, search)
        setUserData(res?.data?.requestes)
        const data = res?.data?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        fetchAllUserBasedOnStatus(1)
    }, [])

    useEffect(() => {
        if (isVisible.table) {
            fetchAllUserBasedOnStatus(1)
        }
    }, [isVisible])


    const handleViewUser = () => {
        const id = encodeToBase64(selectedUser?.userId)
        const url = USERS.VIEW_USER_DETAILS.replace(':id', id);
        window.open(url, '_blank', 'noopener,noreferrer');
    };


    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-screen w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <ManagementSecMainHeader
                            mainHeading={"Verification"}
                            subHeading={"user Verification"}
                            handleSearch={(value) => fetchAllUserBasedOnStatus(1, undefined, value)}
                            FilterComponent={UserVerification}
                            handleFilterData={(value) => fetchAllUserBasedOnStatus(1, value)}
                        />
                    </div>
                    {/* Table */}
                    <div className="p-8">
                        {userData && isVisible.table &&
                            <Table columns={columns} data={userData} pageNation={pageNation} handlePageNation={(value) => fetchAllUserBasedOnStatus(value)} />
                        }
                    </div>

                    {!isVisible.table &&
                        <div className="flex flex-col items-center justify-center">
                            <div className="bg-white h-screen w-[90%]">

                                <div className="flex items-center justify-between">
                                    <div className="flex gap-20 m-12">
                                        {headerContent?.map((item, index) => (
                                            <h1 key={index} onClick={() => handleChangeVisibility(item)} className={`${isVisible[item] ? 'font-semibold' : ''} text-lg text-gray-500 cursor-pointer`}>{item}</h1>
                                        ))}
                                    </div>
                                    <div>
                                        <button onClick={handleViewUser} className='p-2 px-4 text-white bg-brandRed me-3' >View User</button>

                                        <button onClick={() => handleChangeVisibility('table')} className="p-2 mr-2 text-xl leading-none text-black">
                                            &#x2715;
                                        </button>
                                    </div>
                                </div>

                                {isVisible.NAME &&
                                    <Suspense fallback={<div>loading...</div>}>
                                        <NameVerification data={selectedUser} />
                                    </Suspense>
                                }
                                {isVisible.ID &&
                                    <Suspense fallback={<div>loading...</div>}>
                                        <IdVerification data={selectedUser} />
                                    </Suspense>
                                }
                                {isVisible.PHOTO &&
                                    <Suspense fallback={<div>loading...</div>}>
                                        <PhotoVerification data={selectedUser} />
                                    </Suspense>
                                }
                                {isVisible.STATUS &&
                                    <Suspense fallback={<div>loading...</div>}>
                                        <StatusVerification data={selectedUser} changeView={() => handleChangeVisibility('table')} />
                                    </Suspense>
                                }

                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Index