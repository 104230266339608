import React, { useState, useEffect } from 'react'
import { MainSidebar, ManagementSecMainHeader, Table, ToggleBtn, MainNavbar } from "components"
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { toast } from "react-toastify"
import { EVENTS } from "../../../../common/routes.js"
import { getAllEvents, enableEventConnection } from '../../../../apis/NEW/events'
import { formatStringToCapitalist } from '../../../../utils/dataConversion'
import { encodeToBase64 } from 'utils/encodeAndDecode.js'


function Index() {

    const navigate = useNavigate()
    const [eventData, setEventData] = useState()
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Event Name', accessor: 'title' },
        {
            Header: 'Event Category',
            accessor: (data) => data.categories.map(cat => cat.name).join(', ')
        },
        { Header: 'Total Bookings', accessor: 'total_bookings' },
        { Header: 'Total Prebookings', accessor: 'total_prebookings' },
        { Header: 'Event Timing', accessor: 'start_time' },
        { Header: 'Total Slots', accessor: 'total_slots' },
        {
            Header: 'Date',
            accessor: (data) => moment(data.start_date).format('YYYY-MM-DD')
        },
        {
            Header: 'Event Type',
            accessor: 'mode',
            Cell: ({ value }) => (
                <span className={`${value === 'online' ? 'text-green-500' : 'text-black'}`}>
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                </span>
            )
        },
        { Header: 'Event Status', accessor: 'publish_status' },
        {
            Header: 'Participants',
            accessor: 'participantActions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleView(row)} className='p-2 px-3 mr-1 text-white bg-green-500 rounded-3xl'>View</button>
                </div>
            )

        },
        {
            Header: 'Connections Enabled',
            accessor: 'enable_connections',
            Cell: ({ row }) => (
                <ToggleBtn checked={row.original.enable_connections ? true : false} onChange={() => handleConnection(row.original.id)} />
            )
        }
    ];


    const handleConnection = async (id) => {
        const res = await enableEventConnection(id)
        if (res.success) {
            toast.success("Updated Event Connection")
            fetchAllTheEvents(pageNation.currentPage)
        }
    };

    const handleView = (row) => {
        const id = encodeToBase64(row.original.id)
        navigate(`${EVENTS.VIEW_EVENT_PARTICIPANT}/${id}`)
    };


    const fetchAllTheEvents = async (page) => {
        const res = await getAllEvents(page)
        const event = res?.data?.events.map((event, index) => ({
            ...event,
            title: formatStringToCapitalist(event?.title),
            mode: formatStringToCapitalist(event?.mode),
            publish_status: formatStringToCapitalist(event?.publish_status),
            no: index + 1,
            categories: event?.categories?.map(cat => ({
                ...cat,
                name: formatStringToCapitalist(cat.name)
            }))
        }));
        setEventData(event)
        const data = res?.data?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        fetchAllTheEvents(pageNation.currentPage)
    }, [])

    const handleBtn = () => {
        navigate(EVENTS.CREATE_EVENT)
    }

    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-screen w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <ManagementSecMainHeader
                            mainHeading={"Event"}
                            subHeading={"Manage your events effectively"}
                            btnText={"Add Event"}
                            handleBtn={handleBtn} />
                    </div>

                    {/* Table */}
                    <div className="p-8">
                        {eventData &&
                            <Table columns={columns} data={eventData} pageNation={pageNation} handlePageNation={(value) => fetchAllTheEvents(value)} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index