// Events
export const EVENTS = {
    EVENTS:"/events",
    CREATE_EVENT: "/event/create",
    VIEW_EVENT: "/event/view",
    EDIT_EVENT: "/event/edit",
    VIEW_EVENT_PARTICIPANT: "/event/participant",
}

export const USERS = {
    VIEW_USERS: "/users",
    VIEW_USER_DETAILS:'/user/details/:id',
    EDIT_USER_DETAILS:'/user/details/edit/:id',
    DELETED_USERS: "/users/deleted",
    CREATE_USERS: "/users/create",
}

export const AFFILIATE_COUPONS = {
    // Track affiliate coupons
    TRACK_AFFILIATE_COUPON: '/affiliateCoupon/track/:id',
}

export const SIDEBAR = {
    // Home
    HOME_OVERVIEW: '/',

    // EVENT
    EVENTS: EVENTS.EVENTS,
    EVENTS_CONNECTIONS:"/event/connection",
    EVENTS_COMMENTS:"/event/comments",
    EVENTS_CATEGORY:"/event/category",
    EVENTS_LOCATION:"/event/location",
    EVENTS_TAG:"/event/tag",
    EVENTS_HOST: "/event/host",

    // USER
    VIEW_USERS: USERS.VIEW_USERS,
    DELETED_USERS: USERS.DELETED_USERS,

    // PLAN and LIMITATION
    PLANS:'/plan',
    PLAN_CATEGORY: '/plan/category',
    PLAN_LIMITATION: '/plan/limitation',
    PLAN_COIN: '/plan/coin',
    
    // Coupon
    COUPON: '/coupon',

    // Notification
    NOTIFICATION: '/notification',
    
    // Verification
    VERIFICATION:'/verification',
    
    // AppUpdate
    APP_UPDATE:'/appUpdate',

    // Affiliate Coupons
    AFFILIATE_COUPONS:'/affiliateCoupon',
}