
import { auth } from "apis";

export const getDashboardOverview = async (data) => {
    const res = await auth({
        method: "GET",
        url: `/admin/dashboard-counts`,
        data,
    });
    return res;
}
