import React, { useEffect, useState } from 'react'
import { MainNavbar, MainSidebar, ManagementSecMainHeader, Table } from 'components'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { EVENTS } from "../../../../common/routes.js"

import { formatStringToCapitalist } from '../../../../utils/dataConversion'
import { encodeToBase64 } from '../../../../utils/encodeAndDecode'
import { getAllEvents } from 'apis/NEW/events/index.js'

function Index() {

    const navigate = useNavigate()
    const [eventData, setEventData] = useState()
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Event Name', accessor: 'title', maxWidth: 100 },
        {
            Header: 'Event Category',
            accessor: (data) => data.categories.map(cat => cat.name).join(', ')
        },
        { Header: 'Event Timing', accessor: 'start_time' },
        { Header: 'Event Location', accessor: 'location' },
        {
            Header: 'Date',
            accessor: (data) => moment(data.start_date).format('YYYY-MM-DD')
        },
        {
            Header: 'Event Type',
            accessor: 'mode',
            Cell: ({ value }) => (
                <span className={`${value === 'online' ? 'text-green-500' : 'text-black'}`}>
                    {value.charAt(0).toUpperCase() + value.slice(1)}
                </span>
            )
        },
        { Header: 'Event Status', accessor: 'publish_status' },
        { Header: 'Total Comments', accessor: 'total_comments' },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleView(row)} className='bg-[#6161f8] text-white mr-1 p-2 rounded-lg'>View</button>
                </div>
            )
        }
    ];


    const handleView = (row) => {
        const slug = encodeToBase64(row.original.slug)
        const comment = true
        navigate(`${EVENTS.VIEW_EVENT}/${slug}/${comment}`)
    };

    const fetchAllTheEvents = async (page) => {
        const res = await getAllEvents(page)
        const event = res?.data?.events.map((event, index) => ({
            ...event,
            title: formatStringToCapitalist(event?.title),
            mode: formatStringToCapitalist(event?.mode),
            publish_status: formatStringToCapitalist(event?.publish_status),
            no: index + 1,
            // start_time: timeToHrAndMin(event?.start_time),
            categories: event?.categories?.map(cat => ({
                ...cat,
                name: formatStringToCapitalist(cat.name)
            }))
        }));
        setEventData(event)
        const data = res?.data?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        fetchAllTheEvents(pageNation.currentPage)
    }, [])

    const handleBtn = () => {
        navigate(EVENTS.CREATE_EVENT)
    }

    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-screen w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <ManagementSecMainHeader
                            mainHeading={"Event"}
                            subHeading={"Manage your events effectively"}
                            btnText={"Add Event"}
                            handleBtn={handleBtn} />
                    </div>

                    {/* Table */}
                    <div className="p-8">
                        {eventData &&
                            <Table columns={columns} data={eventData} pageNation={pageNation} handlePageNation={(value) => fetchAllTheEvents(value)} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index