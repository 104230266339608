import { deleteUserConnection, getHandPickedUserList, getSkippedUserList, getUserConnectionList, getUserFriendsList, getUserQrConnectionList, removeSkippedUserList, unMatchUserFriendList } from 'apis/NEW/users'
import { ConnectionCard } from "components"
import React, { lazy, Suspense, useEffect, useState } from 'react'

//  Assets
import plusIcon from "assets/header/eventManagement/icons/plus.png";
import { toast } from 'react-toastify';
import { USERS } from 'common/routes';
import { encodeToBase64 } from 'utils/encodeAndDecode';

const AssignPopup = lazy(() => import('components/NEW/popups/handPicked/assignMatches'))

function Index({ decodedId, edit = false }) {

    const [currentTab, setCurrentTab] = useState('Friendlist')
    const [currentType, setCurrentType] = useState('sent')
    const header = ['Friendlist', 'Connections', 'Skip List', 'Qr Connection', 'HandPicked Matches']
    const [userList, setUserList] = useState()
    const [pagination, setPagination] = useState({
        current_page: 1,
        next_page: null
    })
    const [popUps, setPopUps] = useState({
        assignMatches: false,
        temp: false,
    })

    const updatePopupState = (popupName) => {
        setPopUps((prevPopUps) => {
            const newPopUps = {};
            for (const key in prevPopUps) {
                newPopUps[key] = key === popupName;
            }
            return newPopUps;
        });
        setPagination({ current_page: 1, next_page: null })
    };

    const fetchUserConnectionDetails = async (page) => {
        let res
        if (currentTab === 'Friendlist') {
            res = await getUserFriendsList(decodedId, page)
        } else if (currentTab === 'Connections') {
            res = await getUserConnectionList(decodedId, page, currentType)
        } else if (currentTab === 'Qr Connection') {
            res = await getUserQrConnectionList(decodedId, page, currentType)
        } else if (currentTab === 'Skip List') {
            res = await getSkippedUserList(decodedId, page)
        } else if (currentTab === 'HandPicked Matches') {
            res = await getHandPickedUserList(decodedId, page)
        }
        if (res?.data) {
            setPagination(res?.pagination)
            setUserList(prev => (prev ? [...prev, ...res?.data] : res?.data))
        }
    }

    const handleRemove = async (userId, memberId, id) => {
        let res
        if (currentTab === 'Friendlist') {
            res = await unMatchUserFriendList({ userId: userId, shortlistedUser: memberId })
        } else if (currentTab === 'Connections') {
            res = await deleteUserConnection(userId, memberId)
        } else if (currentTab === 'Skip List') {
            res = await removeSkippedUserList(id)
        } else {
            toast('This action is not supported for the current tab')
        }

        if (res?.success) {
            setUserList()
            setCurrentType({
                current_page: 1,
                next_page: null
            })
            fetchUserConnectionDetails(1)
        }
    }

    const handleView = (id) => {
        const url = USERS.VIEW_USER_DETAILS.replace(':id', encodeToBase64(id));
        window.open(url, '_blank');
    };


    useEffect(() => {
        setUserList()
        fetchUserConnectionDetails(1)
    }, [currentTab, currentType])


    return (
        <div className='h-full text-black bg-white'>
            {popUps.assignMatches &&
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <Suspense>
                        <AssignPopup onClose={() => updatePopupState('temp')} decodedId={decodedId} />
                    </Suspense>
                </div>
            }

            <div className="flex items-center justify-between m-5 mb-0">
                <div className="">
                    <h1 className='pt-5 text-xl font-semibold'>Connections</h1>
                    <p className='text-base text-gray-500'>Add time  that highlight what makes it unique <br />Event Time</p>
                </div>
                <div className="flex gap-x-10 me-5">
                    {header.map((item, index) => (
                        <div key={index} className={`cursor-pointer text-lg ${currentTab === item ? 'text-brandRed border-b-2 border-b-brandRed font-bold' : 'text-gray-500'}`} onClick={() => setCurrentTab(item)}>{item}</div>
                    ))}
                </div>

            </div>

            {currentTab == 'Connections' &&
                <div className="flex flex-col items-center w-full">
                    <div className='flex justify-end w-10/12 h-full'>
                        <select onChange={(e) => setCurrentType(e.target.value)} className='border border-gray-300 p-2 w-fit text-black text-lg font-medium bg-[#F9F9F9]'>
                            <option value="sent">Send</option>
                            <option value="received">Received</option>
                        </select>
                    </div>
                </div>
            }
            {currentTab === 'HandPicked Matches' &&
                <div className="flex flex-col items-center w-full">
                    <div className='flex justify-end w-[95%] h-full'>
                        <div onClick={() => updatePopupState('assignMatches')} className="flex items-center h-16 gap-3 px-4 py-2 text-white cursor-pointer w-44 bg-brandRed rounded-xl hover:bg-red-600">
                            <img src={plusIcon} alt="coupleSquad" />
                            Assign Match
                        </div>
                    </div>
                </div>
            }

            <div className="flex flex-wrap pb-20">
                {userList?.map((item, index) => (
                    <div key={index} className="min-w-[200px] max-w-[200px] min-h-[200px] max-h-[200px] m-5 my-20">
                        <ConnectionCard
                            imageUrl={item?.user?.photo}
                            mainText={item?.user?.name}
                            subText1={item?.user?.profession}
                            edit={edit}
                            handleRemove={() => handleRemove(item?.userId, item?.memberId, item?.id)}
                            viewUser={() => handleView(item?.user?.id)}
                        />
                    </div>
                ))}
            </div>
            {pagination.next_page &&
                <div className="w-full my-5 text-base text-center text-blue-500 cursor-pointer" onClick={() => fetchUserConnectionDetails(pagination.next_page)}>see more</div>
            }
        </div>
    )
}

export default React.memo(Index)