import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { InputField, DropDown } from "components";
import { State, City } from 'country-state-city';
import countrys from "../../../../../../assets/json/countryes.json";
import { addLabelAndValueInData } from '../../../../../../utils/dataConversion';

function Index({ data, readOnly = true, handleOnSubmit, submit = false, edit = false }) {
    const [location, setLocation] = useState({
        country: data?.country,
        countryCode: '',
        state: data?.state,
        stateCode: '',
        current_city: data?.current_city,
        citizenship: data?.citizenship,
    });

    const countryOptions = useMemo(() =>
        countrys.map(country => ({
            label: country.name,
            value: country.code.toUpperCase()
        })), []);

    useEffect(() => {
        if (location.country) {
            const country = countrys.find(c => c.name === location.country);
            const countryCode = country ? country.code.toUpperCase() : '';
            setLocation(prev => ({ ...prev, countryCode }));
        }
    }, [location.country]);

    const stateOptions = useMemo(() =>
        State.getStatesOfCountry(location.countryCode), [location.countryCode]);


    useEffect(() => {
        if (location.state) {
            const stateCode = stateOptions.find(state => state.name === location.state)?.isoCode || '';
            setLocation(prev => ({ ...prev, stateCode }));
        }
    }, [location.state, stateOptions]);

    const cityOptions = useMemo(() => {
        if (location.countryCode && location.stateCode) {
            return City.getCitiesOfState(location.countryCode, location.stateCode);
        }
        return [];
    }, [location.countryCode, location.stateCode]);

    const handleCitizenshipChange = useCallback((value) => {
        setLocation(prev => ({ ...prev, citizenship: value }));
    }, []);

    const handleCountryChange = useCallback((value) => {
        const selectedCountry = countrys.find(country => country.code.toUpperCase() === value);
        if (selectedCountry) {
            setLocation(prev => ({
                ...prev,
                country: selectedCountry.name,
                countryCode: selectedCountry.code.toUpperCase()
            }));
        }
    }, []);

    const handleStateChange = useCallback((value) => {
        const selectedState = stateOptions.find(state => state.isoCode === value);
        if (selectedState) {
            setLocation(prev => ({
                ...prev,
                state: selectedState.name,
                stateCode: value
            }));
        }
    }, [stateOptions]);

    const handleCityChange = useCallback((value) => {
        setLocation(prev => ({ ...prev, current_city: value }));
    }, []);



    const content = [
        { label: "Citizenship", value: 'citizenship', options: countryOptions, handleOnChange: handleCitizenshipChange },
        { label: "Country", value: 'country', options: countryOptions, handleOnChange: handleCountryChange },
        { label: "State", value: edit ? 'stateCode' : 'state', options: addLabelAndValueInData(stateOptions, "name", "isoCode"), handleOnChange: handleStateChange },
        { label: "Current City", value: 'current_city', options: addLabelAndValueInData(cityOptions, "name", "name"), handleOnChange: handleCityChange },
    ];

    useEffect(() => {
        if (submit) {
            // eslint-disable-next-line unused-imports/no-unused-vars
            const { countryCode, stateCode, ...data } = location
            handleOnSubmit(data)
        }
    }, [submit])


    return (
        <div className='bg-white text-black -mt-4'>
            <div className="m-5">
                <h1 className='font-semibold text-xl pt-5'>Grooms Location</h1>
                <p className='text-gray-500 text-base'>Add time  that highlight what makes it unique <br />Event Time</p>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-full">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between ">
                                <h1 className='font-semibold text-lg'>{item.label}</h1>
                                <div className="w-[70%]">
                                    {edit ?
                                        <DropDown
                                            selectedValue={location[item.value]}
                                            options={item.options}
                                            readOnly={readOnly}
                                            handleOnChange={item.handleOnChange}
                                        />
                                        :
                                        <InputField value={location[item.value]} readOnly={true} />
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)