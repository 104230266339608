import { auth } from "apis";

export const getAllUserBasedOnStatus = async (page, status, search) => {
    const res = await auth({
      method: "GET",
      url: `/verification/requestes?page=${page}&limit=10&status=${status}&search=${search}`,
    });
    return res
}
  
export const updateUserBasedOnStatus = async (id, data) => {
    const res = await auth({
      method: "PUT",
      url: `/verification/update/${id}`,
      data
    });
    return res
  }