import React, {  useContext,  useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { BiSolidLock } from "react-icons/bi";
import { MdEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { login, status } from "../../apis/auth";
import Logo from "../../assets/images/logo.png";
import UserStore from "../../contexts/UserStore";
import Title from "../../shared/Title";
import { SIDEBAR } from "common/routes";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [isLoading, setIsLoading] = useState(false);
    const { setSession, setUser } = useContext(UserStore);
    const navigate = useNavigate();

    const eyeButton = () => {
        setPasswordType(val => (val === "password" ? "text" : "password"));
    };

    // const userInfo = useCallback(async () => {
    //     const token = localStorage?.getItem('adminSession');
    //     if (user || session || localStorage?.getItem("adminSession")) {
    //         if (!user && token) {
    //             const res = await status();
    //             if (!res) {
    //                 localStorage?.removeItem("adminSession");
    //                 setSession(null);
    //                 return;
    //             }
    //             setUser(res);
    //         } else {
    //             navigate(SIDEBAR.EVENTS, { replace: true });
    //         }
    //     }
    // }, [user, session]);

    // useEffect(() => {
    //     userInfo();
    // }, []);

    // useEffect(() => {
    //     userInfo();
    // }, [userInfo]);

    const handleLogin = async e => {
        e.preventDefault();
        setIsLoading(true);
        const res = await login({ email, password });
        const token = res;
        if (token) {
            localStorage.setItem("adminSession", token);
            setSession(token);
            const user_res = await status();
            setUser(user_res);
            navigate(SIDEBAR.HOME_OVERVIEW, { replace: true });
        }

        setIsLoading(false);
    };

    return (
        <>
            <Title title={"Login"} />
            <div className=" w-[100vw] h-[100vh] bg-[#df76f5] flex justify-center items-center">
                <form
                    onSubmit={handleLogin}
                    className="w-[40rem] h-[40rem] bg-white rounded-[50px] p-12 flex flex-col items-center gap-8"
                >
                    <div className="flex flex-col items-center gap-2 mb-6">
                        <img src={Logo} alt="" className="w-28 m-0" />
                        <h2 className=" text-[#81379c] font-bold text-[30px] font-sans text-center">
                            Welcome back to CoupleSquad
                        </h2>
                    </div>
                    <div className="p-[15px] rounded-[40px] bg-[#a181c2] flex items-center gap-2 ">
                        <MdEmail color="white" size={"20px"} />
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            autoComplete="on"
                            className="bg-[#a181c2] border-none outline-none text-white w-[210px]"
                        />
                    </div>
                    <div className="p-[15px] rounded-[40px] bg-[#a181c2] flex items-center gap-2">
                        <BiSolidLock color="white" size={"20px"} />
                        <input
                            type={passwordType}
                            placeholder="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                            className="bg-[#a181c2] border-none outline-none text-white"
                        />
                        <div onClick={eyeButton}>
                            {passwordType === "password" ? (
                                <AiFillEye color="white" size={"20px"} />
                            ) : (
                                <AiFillEyeInvisible color="white" size={"20px"} />
                            )}
                        </div>
                    </div>
                    <Link to={"/forgot-password"} className=" mt-[-25px] ml-[150px]">
                        <p className="text-[#d98af4]">Forgot Password?</p>
                    </Link>

                    <button
                        type="submit"
                        disabled={isLoading}
                        className="bg-[#ED3E53] py-[15px] px-[70px] rounded-[40px] text-white font-bold text-base hover:bg-[#BC394C] shadow-xl relative w-[200px]"
                    >
                        {isLoading ? (
                            <>
                                <div className="absolute inset-0 flex items-center justify-center">
                                    <div className="w-6 h-6 border-t-2 border-[#FFF] rounded-full animate-spin"></div>
                                </div>
                                <span className="pl-8"></span>
                            </>
                        ) : (
                            "LOGIN"
                        )}
                    </button>
                </form>
            </div>
        </>
    );
}
