import React, { useState, memo, useEffect } from 'react'
import { toast } from 'react-toastify'
import { ChooseOption } from "components"
import { getAllEventCategory } from '../../../../../apis/NEW/events'


function Index({ prevPage, nextPage, data, edit = false }) {
    const [categories, setCategories] = useState(() => {
        if (Array.isArray(data?.categories)) {
            const catData = data?.categories?.map(item => item?._id);
            return catData.includes(undefined) ? data?.categories : catData
        }
        return [];
    });
    const [categoriesOption, setCategoriesOption] = useState([])
    const [readOnly, setReadOnly] = useState(edit)


    const handlePrevPage = () => {
        prevPage({ categories: categories }, 1)
    }

    const handleNextPage = () => {
        if (categories.length > 0) {
            nextPage({ categories: categories }, 3)
        } else {
            toast.error("Please Select at least one Category")
        }
    }

    const updateCategory = (category) => {
        setCategories(currentCategories => {
            if (currentCategories.includes(category)) {
                return currentCategories.filter(cat => cat !== category);
            } else {
                return [...currentCategories, category];
            }
        });
    }

    const fetchAllEventCategory = async () => {
        const res = await getAllEventCategory()
        setCategoriesOption(res?.data)
    }

    useEffect(() => {
        fetchAllEventCategory()
    }, [])


    const handleEdit = () => {
        setReadOnly(!readOnly)
        if (!readOnly) {
            if (categories.length > 0) {
                nextPage({ categories: categories }, 3)
            } else {
                toast.error("Please Select at least one Category")
            }
        }
    }

    return (
        <div>
            <div className="flex justify-between mt-16 mb-5">
                <h1 className='font-semibold text-lg my-5'>What is the Event Category</h1>
                {edit &&
                    <div className="border-2 border-gray-700 p-1 px-4 cursor-pointer h-fit"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEdit()
                        }}>
                        {!readOnly ? 'submit' : 'Edit'}
                    </div>
                }
            </div>
            <div className="flex flex-wrap gap-5 w-full mb-10">
                {categoriesOption.map((item) => (
                    <>
                        {item.status &&
                            <div key={item.id} className="w-[30%]">
                                <ChooseOption
                                    readOnly={readOnly}
                                    text={item.name}
                                    isSelected={categories.includes(item.id) ? true : false}
                                    onChange={() => updateCategory(item.id)}
                                />
                            </div>
                        }
                    </>
                ))}
            </div>
            {edit === false &&
                <div className="w-fit flex items-center justify-center gap-5 bg-white text-black font-bold shadow-lg rounded-lg mb-20 mx-auto">
                    <button className='border-e-2 border-gray-300 p-2 px-4 hover:text-gray-700 hover:font-medium hover:border-e-0 hover:border-b-4 hover:border-brandRed' onClick={handlePrevPage}>Back</button>
                    <button className='p-2 px-4 hover:text-gray-700 hover:font-medium hover:border-b-4 hover:border-brandRed' onClick={handleNextPage}>Next</button>
                </div>
            }
        </div>
    )
}

export default memo(Index)