import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routers from "./common/Routers";
import UserStore from "./contexts/UserStore";
        

function App() {
    const [session, setSession] = useState(localStorage.getItem("adminSession") || null);
    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState({});
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [editUser, setEditUser] = useState({});

    return (
            <UserStore.Provider value={{ session, setSession, user, setUser, errors, setErrors, toggleSidebar, setToggleSidebar, editUser, setEditUser }}>
                <BrowserRouter>
                    {/* <Toolbar /> */}
                    <Routers />

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </BrowserRouter>
            </UserStore.Provider>
    );
}

export default App;
