import { auth } from "apis";

export const getAllAffiliateCoupons = async (page) => {
    const res = await auth({
        method: "GET",
        url: `/referral?page=${page}&limit=10`,
    });
    return res;
};

export const createAffiliateCoupon = async (data) => {
    const res = await auth({
        method: "POST",
        url: `/referral`,
        data,
    });
    return res;
};

export const updateAffiliateCoupon = async (id, data) => {
    const res = await auth({
        method: "PUT",
        url: `/referral/${id}`,
        data,
    });
    return res;
};

export const trackInfluencerReferrals = async (page, id) => {
    const res = await auth({
        method: "GET",
        url: `/referral/track/${id}?page=${page}&limit=10`,
    });
    return res;
}