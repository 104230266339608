import React from 'react'
import logo from "../../../../assets/images/couplesSquardPBLogo.png"

function Index({ bg_color, mainText, isActive, plans, isEdit = false }) {
    return (
        <div className="flex justify-center items-center h-screen">
            <div className='flex flex-col text-white justify-center items-center'>
                <div className={`flex flex-col justify-between w-[270px] h-[380px] ${bg_color} p-4 shadow rounded`}>
                    {/* Header Section */}
                    <div>
                        <div className="flex justify-between items-center mb-4">
                            <h1 className="text-2xl font-semibold">{mainText}</h1>
                            <input type="radio" checked={isActive} name="cardOption" className='w-7 h-7 text-green-500' />
                        </div>
                        <div className={`mb-4 w-[110px] `}>
                            <div className={`w-full p-1 outline-none rounded `} style={{ backgroundColor: 'inherit' }}>
                                {plans?.map((item) => (
                                    <>{item?.isActive &&
                                        <div key={item?.id} className='text-white ' value="option1">{item?.duration} Months</div>
                                    }
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/* Image at the bottom */}
                    <img src={logo} alt="Sample" className="w-[80px] h-[90px] mb-4" />
                </div>
                {isEdit && isActive ?
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-[230px] h-[50px] mt-10">ACTIVED</button>
                    :
                    <button className={`${isActive ? "bg-blue-500 hover:bg-blue-700" : "bg-brandRed hover:bg-red-700"}  text-white font-bold py-2 px-4 rounded w-[230px] h-[50px] mt-10`}>{isActive ? "Actived" : 'Active'}</button>
                }
            </div>
        </div>
    )
}

export default Index