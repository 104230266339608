import React, { useState, useEffect } from 'react'
import { InputField, DropDown } from "components"
import { drinkingOptions, smokingOptions, eatingOptions } from '../../../../../../shared/constants'

function Index({ data, readOnly = true, handleOnSubmit, submit = false, edit = false }) {
    const [userData, setUserData] = useState({
        drinking: data?.drinking,
        smoking: data?.smoking,
        eating: data?.eating,
    })

    const content = [
        { label: "Drinking", value: 'drinking', options: drinkingOptions },
        { label: "Smoking", value: 'smoking', options: smokingOptions },
        { label: "Eating", value: 'eating', options: eatingOptions },
    ]

    const updateState = (key, value) => {
        if (key === 'religion') {
            fetchCasteByReligionId(value)
        }
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (submit) {
            handleOnSubmit(userData)
        }
    }, [submit])

    return (
        <div className='bg-white text-black -mt-4'>
            <div className="m-5">
                <h1 className='font-semibold text-xl pt-5'>Diet Attributes</h1>
                <p className='text-gray-500 text-base'>Add time  that highlight what makes it unique <br />Event Time</p>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-[730px]">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between ">
                                <h1 className='font-semibold text-lg'>{item.label}</h1>
                                <div className="w-[70%]">
                                    {edit ?
                                        <DropDown
                                            selectedValue={userData[item.value]}
                                            options={item.options}
                                            readOnly={readOnly}
                                            handleOnChange={(value) => updateState(item.value, value)}
                                        />
                                        :
                                        <InputField value={userData[item.value]} readOnly={true} />
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)