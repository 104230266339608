import React, { useState, useEffect } from 'react'
import { InputField, DropDown } from "components"
import { GENDER, physicalStatusOptions, relationshipStatusOptions, singlesLookingForOptions, couplesLookingForOptionsCouples, singlesMartialStatusOPtions, couplesMartialStatusOPtions } from '../../../../../../shared/constants';

function Index({ data, readOnly = true, handleOnSubmit, submit = false, edit = false }) {

    const height = Array.from({ length: 250 - 140 + 1 }, (_, index) => 140 + index);
    const weight = Array.from({ length: 150 - 40 + 1 }, (_, index) => 40 + index);
    const [userData, setUserData] = useState({
        height: data?.height,
        weight: data?.weight,
        physical_status: data?.physical_status,
        sex: data?.sex,
        relationship_status: data?.relationship_status,
        looking_for: data?.looking_for,
        marital_status: data?.marital_status,
    })

    const content = [
        { label: "Height", value: 'height', options: height.map(h => ({ label: `${h} cm`, value: h })) },
        { label: "Weight", value: 'weight', options: weight.map(h => ({ label: `${h} kg`, value: h })) },
        { label: "Physical Status", value: 'physical_status', options: physicalStatusOptions },
        { label: "Gender", value: 'sex', options: GENDER },
        { label: "Relationship Status", value: 'relationship_status', options: relationshipStatusOptions },
        { label: "Marital Status", value: 'marital_status', options: userData.relationship_status === 'single' ? singlesMartialStatusOPtions : couplesMartialStatusOPtions },
        { label: "Looking For", value: 'looking_for', options: userData.relationship_status === 'single' ? singlesLookingForOptions : couplesLookingForOptionsCouples },
    ]

    const updateState = (key, value) => {
        if (key === 'religion') {
            fetchCasteByReligionId(value)
        }
        setUserData(prev => ({ ...prev, [key]: value }))
    }

    useEffect(() => {
        if (submit) {
            handleOnSubmit(userData)
        }
    }, [submit])

    return (
        <div className='bg-white text-black -mt-4'>
            <div className="m-5">
                <h1 className='font-semibold text-xl pt-5'>Physical Details</h1>
                <p className='text-gray-500 text-base'>Add time  that highlight what makes it unique <br />Event Time</p>
                <div className="flex justify-between">
                    {/* Content Section */}
                    <div className="w-full h-[730px]">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between ">
                                <h1 className='font-semibold text-lg'>{item.label}</h1>
                                <div className="w-[70%]">
                                    {edit ?
                                        <DropDown
                                            selectedValue={userData[item.value]}
                                            options={item.options}
                                            readOnly={readOnly}
                                            handleOnChange={(value) => updateState(item.value, value)}
                                        />
                                        :
                                        <InputField value={userData[item.value]} readOnly={true} />
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Index)