import React from 'react';
import { DropDown, InputField, ToggleBtn } from 'components';
import { formatDateToDisplayDateField } from 'utils/dataConversion';

const CouponContentRender = ({ content, updateState, couponData }) => {
  return (
    <>
      {content?.map((item, index) => (
        <div className="w-full my-10 flex justify-between" key={index}>
          {/* Conditionally render the entire block for "Limit" */}
          {(item?.value !== 'apply_limit' || couponData['apply_type'] !== 'unlimited') && (
            <div className='w-full flex flex-row lg:flex-col lg:gap-3 xl:flex-row items-center lg:items-start xl:items-center justify-between '>
              <label className="w-full text-base text-gray-700">{item?.label}</label>
              <div className="w-full h-[54px] -mt-4">
                {/* singleInput */}
                {item?.inputType === 'singleInput' && (
                  <InputField 
                    onChange={(value) => updateState(item?.value, value)} 
                    value={couponData[item?.value]} 
                    type={item?.type} 
                  />
                )}

                {/* multipleInput */}
                {item?.inputType === 'multipleInput' && (
                  <div className="flex w-full items-center gap-3">
                    <InputField 
                      onChange={(value) => updateState(item?.value1, value)} 
                      value={item?.type === 'date' ? formatDateToDisplayDateField(couponData[item?.value1]) : couponData[item?.value1]} 
                      type={item?.type} 
                    />
                    <InputField 
                      onChange={(value) => updateState(item?.value2, value)} 
                      value={item?.type === 'date' ? formatDateToDisplayDateField(couponData[item?.value2]) : couponData[item?.value2]} 
                      type={item?.type} 
                    />
                  </div>
                )}

                {/* ratioBtnAndDropdown */}
                {item?.inputType === 'ratioBtnAndDropdown' && (
                  <div className="flex w-full items-center gap-3">
                    <ToggleBtn 
                      onChange={() => updateState(item?.valueToggleBtn, !couponData[item?.valueToggleBtn])} 
                      checked={couponData[item?.valueToggleBtn]} 
                    />
                    <DropDown 
                      selectedValue={couponData[item?.valueDropDown]} 
                      handleOnChange={(value) => updateState(item?.valueDropDown, value)} 
                      options={item?.options} 
                      readOnly={!couponData[item?.valueToggleBtn]} 
                    />
                  </div>
                )}

                {/* Toggle btn */}
                {item?.inputType === 'toggleBtn' && (
                  <div className="flex w-full h-full items-center">
                    <ToggleBtn 
                      onChange={() => updateState(item?.valueToggleBtn, !couponData[item?.valueToggleBtn])} 
                      checked={couponData[item?.valueToggleBtn]} 
                    />
                  </div>
                )}

                {/* dropdown */}
                {item?.inputType === 'dropdown' && (
                  <DropDown 
                    selectedValue={couponData[item?.value]} 
                    handleOnChange={(value) => updateState(item?.value, value)} 
                    options={item?.options} 
                  />
                )}
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default CouponContentRender;
