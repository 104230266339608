import React, { Suspense, useEffect, useState } from 'react'
import { ManagementSecMainHeader, MainNavbar, MainSidebar, ConnectionCard } from 'components'

import totalReferralsIcon from '../../../../assets/icons/share_icon.svg';
import coinsReferralsIcon from '../../../../assets/icons/coins_reward.svg';

import CountsCard from 'components/NEW/affiliateReferralsManagement/CountsCard';
import { trackInfluencerReferrals } from 'apis/NEW/affiliateCoupons';
import { USERS } from 'common/routes';
import { decodeFromBase64, encodeToBase64 } from 'utils/encodeAndDecode';
import { useParams } from 'react-router-dom';


const index = () => {

const { id } = useParams();
const decodedId = decodeFromBase64(id);

  const header = ['Referred Users']
  const [currentTab, setCurrentTab] = useState('Referred Users');
  const [userList, setUserList] = useState([]);
  const [influencerDetails, setInfluencerDetails] = useState({
    influencer_name: '',
    influencer_email: '',
    influencer_phone: '',
    coins_validity: ''
  });
  const [rewardCoins, setRewardCoins] = useState(0);
  const [pagination, setPagination] = useState({
    current_page: 1,
    next_page: null })

  async function getReferralList(page){
    const res = await trackInfluencerReferrals(page ?? 1, decodedId);
    setUserList(prev => (prev ? [...prev, ...res?.data[0]?.users ] : res?.data[0]?.users));
    setInfluencerDetails({
        influencer_name: res?.data[0]?.influencer_name,
        influencer_email: res?.data[0]?.influencer_email,
        influencer_phone: res?.data[0]?.influencer_phone,
        coins_validity: res?.data[0]?.coins_validity
    });
    setRewardCoins(res?.data[0]?.coins);
    setPagination(res?.pagination);
  };

  useEffect(async() => {
    await getReferralList();
  },[])

  const handleView = (id) => {
    const url = USERS.VIEW_USER_DETAILS.replace(':id', encodeToBase64(id));
    window.open(url, '_blank');
};

  function getTabComponent(tabName){
    switch(tabName){
        case 'Referred Users' :
            return <div className="flex flex-wrap pb-20">
                {userList?.map((item, index) => (
                    <div key={index} className="min-w-[200px] max-w-[200px] min-h-[200px] max-h-[200px] m-5 my-20">
                        <ConnectionCard
                            imageUrl={item?.photo}
                            mainText={item?.name}
                            subText1={item?.profession}
                            //edit={edit}
                            //handleRemove={() => handleRemove(item?.userId, item?.memberId, item?.id)}
                            viewUser={() => handleView(item?.id)}
                        />
                    </div>
                ))}
                {pagination.next_page &&
                    <div className="w-full my-5 text-base text-center text-blue-500 cursor-pointer" onClick={() => getReferralList(pagination.next_page ?? 1)}>see more</div>
                }
            </div>
        default:
            return <div>Select a tab</div>
    }
  }

  

  
  return (
    <div className='flex flex-col h-screen'>

    {/* Header */}
    <div className="min-h-[120px] w-full">
        <MainNavbar />
    </div>

    <div className="flex">
        {/* SideBar */}
        <div className="w-2/12 h-screen">
            <MainSidebar />
        </div>
        {/* Main Content Section */}
        <div className="bg-[#f8f8f8] min-h-screen h-full w-10/12">
            {/* Header */}
            <div className="w-[96%] mx-auto my-10">
                <ManagementSecMainHeader
                    mainHeading={"Affiliate Coupons"}
                    subHeading={"Manage your coupons effectively"}
                    btnText={"Add Coupon"}
                />
            </div>

            <div className='w-11/12 mx-auto'> 
        <div className=" w-full p-6 bg-white shadow-md rounded-md">
                <h2 className="text-lg mb-4">Personal Details</h2>
                <div className="flex flex-col md:flex-row p-2">
                      <div className="flex flex-col gap-3 w-1/2 p-2">
                          <div className="flex flex-col gap-5 justify-center items-start">
                             {
                                Object.entries(influencerDetails)?.map(([key, value]) => (
                                <div className='flex flex-col gap-2' key={key}>
                                    <h3 className='text-black opacity-50 font-semibold text-base capitalize'>{key?.replace('_',' ')}</h3>
                                    <p className='text-black text-lg'>{value}</p>
                                </div>
                              ))
                             }
                          </div>
                      </div>
                      <div className="flex w-1/2 px-4 flex-col items-end justify-center gap-5">
                            <CountsCard icon={totalReferralsIcon} bgColor={'bg-lightGreen'} count={pagination?.total} title={'Referrals'}/>
                            <CountsCard icon={coinsReferralsIcon} bgColor={'bg-lightYellow'} count={rewardCoins} title={'Coins rewarded'}/>
                      </div>
                </div>
                <div className='flex overflow-x-auto mx-10 h-[60px] bg-white' style={{ scrollbarWidth: 'none' }}>
                        {header.map((item, index) => (
                            <h1
                                className={`p-3 w-fit cursor-pointer ${currentTab === item ? 'text-brandRed border-b-2 border-brandRed rounded-t-lg' : 'text-black'}`}
                                key={index}
                                onClick={() => setCurrentTab(item)}
                            >
                                {item}
                            </h1>
                        ))}
                </div>
                <div className='p-8 pt-0'>
                    <Suspense fallback={<div>Loading...</div>}>
                        {getTabComponent(currentTab)}
                    </Suspense>
                </div>
        </div>
    
    </div>
            
        </div>
    </div>
    </div>
  )
}

export default index