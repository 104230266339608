export const GENDER = [
    { label: 'Male', value: "male" },
    { label: 'Female', value: "female" }
] 
export const verificationOptions = [
    { label: 'Verified', value: "verified" },
    { label: 'Waitlisted', value: "waitlisted" },
    { label: 'Requested', value: "requested" },
    { label: 'Pending', value: "pending" },
] 

export const relationshipStatusOptions = [
    { value: 'single', label: 'Single' },
    { value: 'couple', label: 'Couple' }
]

export const singlesMartialStatusOPtions = [
    { label: 'Unmarried', value: "unmarried" },
    { label: 'Divorced', value: "divorced" },
    { label: 'Widowed', value: "widowed" }
]

export const couplesMartialStatusOPtions = [
    { label: 'In a relationship', value: "in_a_relationship" },
    { label: 'Engaged', value: "engaged" },
    { label: 'Married', value: "married" }
]


export const singlesLookingForOptions = [
    { value: 'marriage', label: 'Marriage' },
    { value: 'dating', label: 'Dating' },
    { value: 'friendship', label: 'Friendship' },
    { value: 'im_not_sure_yet', label: 'Im not sure yet' },
]
export const couplesLookingForOptionsCouples = [
    { value: 'meetup', label: 'Meetup' },
    { value: 'events', label: 'Events' },
    { value: 'friendship', label: 'Friendship' },
    { value: 'im_not_sure_yet', label: 'Im not sure yet' },
]

export const interests = [
    { value: 'reading', label: 'Reading' },
    { value: 'photography', label: 'Photography' },
    { value: 'gaming', label: 'Gaming' },
    { value: 'music', label: 'Music' },
    { value: 'travel', label: 'Travel' },
    { value: 'painting', label: 'Painting' },
    { value: 'politics', label: 'Politics' },
    { value: 'charity', label: 'Charity' },
    { value: 'cooking', label: 'Cooking' },
    { value: 'pets', label: 'Pets' },
    { value: 'sports', label: 'Sports' },
    { value: 'fashion', label: 'Fashion' },
] 

export const physicalStatusOptions = [
    { value: 'none', label: 'None' },
    { value: 'disabled', label: 'Disabled Person' },
]


// Diet Options
export const drinkingOptions = [
    { value: 'non_drinker', label: 'Non Drinker' },
    { value: 'social_drinker', label: 'Social Drinker' },
]

export const smokingOptions = [
    { value: 'non_smoker', label: 'Non Smoker' },
    { value: 'occasional_smoker', label: 'Occasional Smoker' },
]

export const eatingOptions = [
    { value: 'Omnivore', label: 'Omnivore' },
    { value: 'vegetarian/vegan', label: 'Vegetarian/Vegan' },
    { value: "i'm_flexible", label: "I'm Flexible" },
]

export const workoutOptions = [
    { value: 'regularly', label: 'Regularly' },
    { value: 'weekly', label: 'Weekly' },
    { value: "sometimes", label: "Sometimes" },
    { value: "not_do", label: "Not do" },
]

export const educationOptions = [
    { value: 'under_graduate', label: 'Under Graduate' },
    { value: 'post_graduate', label: 'Post Graduate' },
    { value: "12th", label: "12th" },
    { value: "any", label: "Any" },
]

export const relationWithParent = [
    { value: 'father', label: 'Father' },
    { value: 'mother', label: 'Mother' },
    { value: 'brother', label: 'Brother' },
    { value: 'sister', label: 'Sister' },
]

export const contactModeWithParent = [
    { value: 'phone', label: 'Phone' },
    { value: 'email', label: 'Email' },
    { value: 'in_person', label: 'In Person' },
]

export const timeLineOptions = [
    { value: 'less_than_6_months', label: 'Less than 6 months' },
    { value: '1year', label: '1 Year' },
    { value: '1_2Year', label: '1-2 Year' },
]

export const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
    { label: 'Unisex', value: 'unisex' },
];
  
export const accountStatus = [
    { label: 'Blocked', value: true },
    { label: 'Active', value: false }
]