import React, { useEffect, useState } from 'react';
import { DropDown } from 'components';
import { couplesMartialStatusOPtions, singlesMartialStatusOPtions } from 'shared/constants';
import { capitalize } from 'shared/textConverter';
import { fetchReligions, getCastesByReligion } from 'apis/religionAndCaste';
import { addLabelAndValueInData } from 'utils/dataConversion';
import { resetState } from 'utils/helper';
import { initializeDoubleDropDownContent, initializeDropDownContent, initializeUserFilter, resetDoubleDropDownContent, resetDropDownContent } from './actions';
import { getAllPlansCategory, getPlansByCategoryId } from 'apis/NEW/plans';

function Index({ handleShowFilter, handleFilterData, filterOptions }) {

    const [religionOption, setReligionOption] = useState();
    const [castOption, setCastOption] = useState([]);
    const [planCategories, setPlanCategories] = useState([]);
    const [plans, setPlans] = useState([]);
    const [search, setSearch] = useState(filterOptions?.search || null);
    const [phone, setPhone] = useState(filterOptions?.phone || null);
    const [userFilter, setUserFilter] = useState(initializeUserFilter());
    const [joinedFrom, setJoinedFrom] = useState(filterOptions?.joinedFrom || null);
    const [joinedTo, setJoinedTo] = useState(filterOptions?.joinedTo || null);
    const [dropDownContent, setDropDownContent] = useState(initializeDropDownContent(userFilter, religionOption, planCategories, castOption, plans))
    const [doubleDropDownContent, setDoubleDropDownContent] = useState(initializeDoubleDropDownContent(userFilter))



    const handleDropDownChange = (index, isChecked, selectedValue) => {
        if (index === 1) fetchCasteByReligionId(selectedValue);

        if (index === 7) fetchPlansByCategoryId(selectedValue);

        setDropDownContent(prevState => {
            const updatedDropDownContent = [...prevState];
            updatedDropDownContent[index] = {
                ...updatedDropDownContent[index],
                selectedValue: selectedValue
            };
            return updatedDropDownContent;
        });

    };


    const handleInputChange = (index, type, value, dropdownIndex = null) => {

        setDoubleDropDownContent(prevState => {
            const updatedContent = [...prevState];
            if (type === 'checkbox') {
                updatedContent[index].checkBox = value;
            } else if (type === 'dropdown') {
                updatedContent[index].selectedValues[dropdownIndex] = value;
            }
            return updatedContent;
        });
    };



    const updateUserFilter = () => {
        const newUserFilter = { ...userFilter }
        dropDownContent.forEach(item => {
            if(item?.label !== 'plan_categories'){
                newUserFilter[item.label] = item.selectedValue;
            } 
        });

        doubleDropDownContent.forEach(item => {
            if (item.label === 'age') {
                newUserFilter['age_from'] = item.selectedValues[0];
                newUserFilter['age_to'] = item.selectedValues[1];
            } else if (item.label === 'height') {
                newUserFilter['height_from'] = item.selectedValues[0];
                newUserFilter['height_to'] = item.selectedValues[1];
            }
        });

        setUserFilter(newUserFilter);
        setUserFilter(prev => ({ ...prev, search, phone, joinedFrom, joinedTo }));
        const data = {
            ...newUserFilter,
            search,
            phone,
            joinedFrom,
            joinedTo,
        }
        localStorage.setItem('userFilter', JSON.stringify(data));
        handleFilterData(data);
    };

    const getAllReligions = async () => {
        const res = await fetchReligions();
        if (res) {
            const data = addLabelAndValueInData(res, 'name', 'id');
            setReligionOption(data);
        }
    };


    const fetchCasteByReligionId = async (id) => {
        const res = await getCastesByReligion(id);
        const data = addLabelAndValueInData(res, 'name', 'id');
        setCastOption(data);
    };

    const getAllPlanCategories = async () => {
        const res = await getAllPlansCategory();
        if (res) {
            const data = addLabelAndValueInData(res?.data, 'name', 'id');
            setPlanCategories(data);
        }
    };

    const fetchPlansByCategoryId = async (id) => {
        const res = await getPlansByCategoryId({ id });
        const data = addLabelAndValueInData(res?.data, 'name', 'id');
        setPlans(data);
    }


    //  Update religion options
    useEffect(() => {
        setDropDownContent(prevState => {
            const updatedDropDownContent = [...prevState];
            updatedDropDownContent[1].options = religionOption;
            return updatedDropDownContent;
        });
    }, [religionOption]);

    

    // Update caste options
    useEffect(() => {
        setDropDownContent(prevState => {
            const updatedDropDownContent = [...prevState];
            updatedDropDownContent[2].options = castOption;
            return updatedDropDownContent;
        });
    }, [castOption]);

    //update plans options
    useEffect(()=> {
        setDropDownContent(prevState => {
            const updatedDropDownContent = [...prevState];
            updatedDropDownContent[8].options = plans;
            return updatedDropDownContent;
        });
    },[plans])

    //update plan category options
    useEffect(() => {
        setDropDownContent(prevState => {
            const updateDropDownContent = [...prevState];
            updateDropDownContent[7].options = planCategories || [];
            return updateDropDownContent;
        })
    },[planCategories]);

    useEffect(() => {
        getAllReligions();
        getAllPlanCategories();
        if (userFilter?.religion) {
            fetchCasteByReligionId(userFilter?.religion)
        };

        if(userFilter?.planCategories){
            fetchPlansByCategoryId(userFilter?.planCategories)
        }
    }, []);

    // Update Martial Status OPtions
    useEffect(() => {
        setDropDownContent(prevState => {
            const updatedDropDownContent = [...prevState];
            const relationshipStatus = updatedDropDownContent.find(item => item.label === 'relationship_status').selectedValue;

            updatedDropDownContent[5].options = relationshipStatus === 'single' ? singlesMartialStatusOPtions : couplesMartialStatusOPtions;
            return updatedDropDownContent;
        });
    }, [dropDownContent.find(item => item.label === 'relationship_status').selectedValue]);


    const handleReSetFilter = () => {
        setUserFilter(resetState(userFilter))
        setDropDownContent(resetDropDownContent(religionOption, castOption));
        setDropDownContent(resetDropDownContent(plans));
        setDoubleDropDownContent(resetDoubleDropDownContent())
        setSearch(null)
        setPhone(null)
        localStorage.setItem('userFilter', JSON.stringify({ page: 1 }));
        handleFilterData({});
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            updateUserFilter();
            handleShowFilter()
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [search, phone, dropDownContent, doubleDropDownContent]);

    return (
        <div className="w-[95%] h-full flex flex-col items-center mx-auto mb-4">

            <div className="flex items-center justify-between w-full m-2 text-xl font-semibold">
                <h1>Filter</h1>
                <button onClick={handleShowFilter} className="absolute top-0 right-0 p-2 mt-2 mr-2 text-xl leading-none text-black">
                    &#x2715;
                </button>
            </div>

            <input type="text" placeholder="Search..." className="w-[90%] my-2 mr-2 px-4 py-2 border border-gray-300 rounded-md"
                value={search} onChange={(e) => setSearch(e.target.value)} />

            {dropDownContent.map((item, index) => (
                <div key={index} className="w-[90%] flex  justify-between">
                    <div className="flex items-center my-5 mt-6">
                        <span className='text-base'>{capitalize(item.label)}</span>
                    </div>
                    <div className="w-[50%] h-[40px]">
                        <DropDown
                            options={item.options}
                            placeholderValue={'select'}
                            handleOnChange={(value) => handleDropDownChange(index, item.checkBox, value)}
                            selectedValue={item.selectedValue}
                        />
                    </div>
                </div>
            ))}

            {doubleDropDownContent.map((item, index) => (
                <div key={index} className="w-[90%] flex justify-between">
                    <div className="flex items-center my-5 mt-6">
                        <span className="text-base">{item.label}</span>
                    </div>
                    <div className="w-[50%] h-[40px] flex gap-2">
                        {item.selectedValues.map((value, dropdownIndex) => (
                            <DropDown
                                key={dropdownIndex}
                                options={item.options}
                                placeholderValue={dropdownIndex === 0 ? 'Start' : 'From'}
                                handleOnChange={(value) => handleInputChange(index, 'dropdown', value, dropdownIndex)}
                                selectedValue={value}
                            />
                        ))}
                    </div>
                </div>
            ))}

                <div className="w-[90%] flex justify-between">
                    <div className="flex items-center my-5 mt-6">
                        <span className="text-base">Joined From</span>
                    </div>
                    <div className="w-[50%] h-[40px] flex gap-2">
                        <input type="date" className="w-full h-full px-4 py-2 my-2 border border-gray-300 rounded-md"
                            value={joinedFrom} onChange={(e) => setJoinedFrom(e.target.value)}
                        />
                    </div>
                </div>

                <div className="w-[90%] flex justify-between">
                    <div className="flex items-center my-5 mt-6">
                        <span className="text-base">Joined To</span>
                    </div>
                    <div className="w-[50%] h-[40px] flex gap-2">
                        <input type="date" className="w-full h-full px-4 py-2 my-2 border border-gray-300 rounded-md"
                            value={joinedTo} onChange={(e) => setJoinedTo(e.target.value)}
                        />
                    </div>
                </div>

            <div className="w-[90%] flex  justify-between">
                <div className="flex items-center my-5 mt-6">
                    {/* <input type="checkbox"  className="mr-2 h-[15px] w-[15px]" /> */}
                    <span className='text-base'>Phone Number</span>
                </div>
                <div className="w-[50%] h-[40px] flex gap-2">
                    <input type="text" placeholder="Phone No" className="w-full h-full px-4 py-2 my-2 border border-gray-300 rounded-md"
                        value={phone} onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
            </div>

            <div className="w-full h-2 my-5 bg-gray-500"></div>

            <div className="flex items-center justify-center w-full pb-8">
                <button className='w-[110px] h-[40px] border-2 me-3' onClick={() => {
                    handleReSetFilter()
                    handleShowFilter()
                }}>Reset</button>
                <button className='w-[110px] h-[40px] bg-brandRed text-white' onClick={() => {
                    updateUserFilter()
                    handleShowFilter()
                }}>Filter</button>
            </div>
        </div>
    );
}

export default Index;
