import React, { useState, useRef } from 'react';
import { InputField, DropDown } from "components"
import { toast } from "react-toastify"
import AdvancedImageCropping from 'components/NEW/image/imageCropping'
import img from "../../../../assets/createNewEvent/images/Picture.png"


function Index({ handleClose, handleSubmit, data, isEdit }) {
    const [categoryData, setCategoryData] = useState({
        name: data?.name || null,
        email: data?.email || null,
        phone: data?.phone || null,
        photo: data?.photo || null,
        status: data?.status ?? true
    });
    const [imgUrl, setImgUrl] = useState(data?.photo)
    const [advanceCropping, setAdvanceCropping] = useState()


    const updateState = (key, value) => {
        setCategoryData(prev => ({ ...prev, [key]: value }))
    }

    // Image Section
    const fileInputRef = useRef(null);
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.substr(0, 5) === "image") {
            const reader = new FileReader();
            reader.onloadend = () => {
                updateState("photo", file);
                setImgUrl(reader.result);
                setAdvanceCropping(!advanceCropping)
            };
            reader.readAsDataURL(file);
        } else {
            updateState("photo", null);
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleCropComplete = async (croppedFile) => {
        updateState('photo', croppedFile);
        setImgUrl(URL.createObjectURL(croppedFile));
        setAdvanceCropping(!advanceCropping)
    }

    const handleCreateBtn = () => {
        if (categoryData.name && categoryData.photo && categoryData.email && categoryData.phone) {
            handleSubmit(categoryData)
        } else {
            toast.error("please fill all the Fields")
        }
    }

    return (
        <div className="flex items-center justify-center w-full h-full">
            <div className="relative w-full max-w-md p-6 text-black bg-white rounded-lg shadow-lg">
                <button
                    onClick={handleClose}  // Your method to handle closing the modal
                    className="absolute top-0 right-0 p-2 mt-2 mr-2 text-xl leading-none text-black"
                >
                    &#x2715;  {/* Unicode character for the multiplication sign used as a close icon */}
                </button>
                <h2 className="mb-6 text-xl font-semibold text-start">Create Host</h2>
                <div className="space-y-4">
                    <h1 className='text-[16px]'>Name</h1>
                    <InputField value={categoryData.name} onChange={(value) => updateState("name", value)} type={'text'} />
                    <h1 className='text-[16px]'>Email</h1>
                    <InputField value={categoryData.email} onChange={(value) => updateState("email", value)} type={'email'} />
                    <h1 className='text-[16px]'>Phone</h1>
                    <InputField value={categoryData.phone} onChange={(value) => updateState("phone", value)} type={'number'} />


                    {advanceCropping &&
                        <div className="h-[50vh] w-full flex items-center justify-center">
                            <AdvancedImageCropping image={imgUrl} setPopup={() => setAdvanceCropping(!advanceCropping)} onCropComplete={handleCropComplete} ratio={4 / 5} />  {/* old = 11/6 */}
                        </div>
                    }
                    <h1 className='text-[16px] mt-4 -mb-3'>Host Image</h1>
                    <div onClick={handleButtonClick} className="aspect-ratio-[11/6] w-full my-8 flex flex-col justify-center items-center rounded-2xl bg-white text-black border-2 border-gray-300">
                        {imgUrl ? (
                            <img src={imgUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '280px' }} />
                        ) : (
                            <>
                                <p className='text-[9px] text-gray-500'>supports: PNG, JPG, JPEG</p>
                                <img src={img} alt="" />
                                <p className='text-[9px] text-gray-500'>Click here or drag file to this area to upload</p>
                            </>
                        )}
                        <input type="file" onChange={handleImageChange} style={{ display: 'none' }} ref={fileInputRef} />
                    </div>
                    {isEdit &&
                        <div className="h-[56px] w-full my-8 flex flex-col justify-center rounded-3xl">
                            <h1 className='text-[16px] mt-4 -mb-3'>Status</h1>
                            <DropDown
                                selectedValue={categoryData.status ? 'yes' : "no"}
                                placeholderValue={""}
                                options={[{ label: "Yes", value: "yes" }, { label: "No", value: "no" }]}
                                handleOnChange={() => updateState("status", !categoryData.status)}
                            />
                        </div>
                    }

                    <button
                        onClick={handleCreateBtn}
                        type="button"
                        className="w-full px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-700"
                    >
                        Create
                    </button>

                </div>
            </div>
        </div>

    );
}

export default React.memo(Index)
