import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet/dist/leaflet.css';
import placeholderIcon from "../../../assets/icons/mapMarker.png";

const customIcon = new Icon({
    iconUrl: placeholderIcon,
    iconSize: [38, 38],
});

const SearchControl = ({ setUserLocation, setLocation }) => {
    const map = useMap();

    useEffect(() => {
        const provider = new OpenStreetMapProvider();
        const searchControl = new GeoSearchControl({
            provider,
            style: 'bar',
            autoClose: true,
            searchLabel: 'Enter an address',
            keepResult: true,
            showMarker: false,
        });

        map.on('geosearch/showlocation', (result) => {
            const { x, y } = result.location;
            setUserLocation([y, x]);
            setLocation([y, x])
        });

        map.addControl(searchControl);

        return () => {
            map.removeControl(searchControl);
            map.off('geosearch/showlocation');
        };
    }, [map, setUserLocation, setLocation]);

    return null;
};

const MapComponent = ({ location_latitude, location_longitude, setLocation }) => {
    const [userLocation, setUserLocation] = useState(null);


    useEffect(() => {
        if (location_latitude && location_longitude) {
            setUserLocation([location_latitude, location_longitude]);
        } else {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setUserLocation([latitude, longitude]);
                    setLocation([latitude, longitude]);
                },
                () => {
                    console.error("Geolocation is not supported by this browser.");
                },
                { enableHighAccuracy: true }
            );
        }
    }, [location_latitude, location_longitude, setLocation]);


    const handleDragEnd = (event) => {
        const newLatLng = event.target.getLatLng();
        if (newLatLng) {
            setUserLocation([newLatLng.lat, newLatLng.lng]);
            setLocation([newLatLng.lat, newLatLng.lng]);
        } else {
            console.error("Drag end event did not provide location data.");
        }
    };


    if (!userLocation) {
        return null;
    }

    return (
        <MapContainer center={userLocation} zoom={13} style={{ height: '400px', width: '100%' }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
                position={userLocation}
                icon={customIcon}
                draggable={true}
                eventHandlers={{ dragend: handleDragEnd }}
            />
            <SearchControl setUserLocation={setUserLocation} setLocation={setLocation} />
        </MapContainer>
    );
};

export default React.memo(MapComponent);

