import React, { useState, useEffect, Suspense, lazy } from 'react'
import { useNavigate } from 'react-router-dom'
import { USERS } from "../../../../common/routes.js"
import { MainSidebar, ManagementSecMainHeader, Table, MainNavbar, UserFilter } from "components"

import { getAllUsers } from 'apis/NEW/users/index.js'
import { encodeToBase64 } from '../../../../utils/encodeAndDecode';
import { toast } from 'react-toastify'
import { sendNotifications } from 'apis/NEW/notification/index.js'
const CreateNotification = lazy(() => import("components").then(module => ({ default: module.CreateNotification })))


function Index() {

    const navigate = useNavigate()
    const [userData, setUserData] = useState()
    const [showNotification, setShowNotification] = useState(false)
    const [selectedRows, setSelectedRows] = useState({})
    const [pageNation, setPageNation] = useState({
        currentPage: 1,
        nextPage: null,
        prevPage: null,
    })
    // const userFilter = {
    //     page: null,
    //     search: null,
    //     sex: null,
    //     age_from: null,
    //     age_to: null,
    //     caste: null,
    //     religion: null,
    //     height_from: null,
    //     height_to: null,
    //     phone: null,
    //     account_status: null,
    //     verification: null,
    //     marital_status: null,
    //     relationship_status: null,
    // }

    const columns = [
        {
            Header: () => (
                <input
                    type="checkbox"
                    checked={Object.values(selectedRows).every(Boolean)}
                    onChange={toggleSelectAll}
                />
            ),
            id: 'selection',
            Cell: ({ row }) => (
                <input
                    type="checkbox"
                    checked={!!selectedRows[row.original.id]}
                    onChange={() => toggleRowSelection(row.original.id)}
                />
            ),
        },
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Name', accessor: 'name' },
        { Header: 'Email', accessor: 'email' },
        { Header: 'Phone', accessor: 'phone' },
        { Header: 'Category', accessor: 'personal.relationship_status' },
        { Header: 'Gender', accessor: 'personal.sex' },
        {
            Header: 'Status',
            accessor: 'account_blocked',
            Cell: ({ value }) => (
                <>
                    {value ?
                        <span className='p-2 text-red-500 bg-red-100 rounded-3xl'>
                            Blocked
                        </span>
                        :
                        <span className='p-2 text-green-500 bg-green-100 rounded-3xl'>
                            active
                        </span>

                    }
                </>

            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleView(row)} className='p-2 px-3 mr-1 text-white bg-green-500 rounded-xl'>View</button>
                </div>
            )
        }
    ];

    const toggleRowSelection = (id) => {
        setSelectedRows(prev => ({
            ...prev,
            [id]: !prev[id]
        }))
    }

    const toggleSelectAll = () => {
        const allSelected = Object.values(selectedRows).every(Boolean)
        const newSelectedRows = {}
        userData.forEach(user => {
            newSelectedRows[user.id] = !allSelected
        })
        setSelectedRows(newSelectedRows)
    }

    const handleView = (row) => {
        const id = encodeToBase64(row.original.id)
        navigate(USERS.VIEW_USER_DETAILS.replace(':id', id));
    };

    const handleFilterData = (value) => {
        fetchAllUsers(pageNation.currentPage, value)
    }

    const fetchAllUsers = async (page) => {
        setUserData(null)
        // let filterData = {
        //     ...userFilter,
        //     page: page,
        // }
        // if (value) {
        //     filterData = {
        //         ...userFilter,
        //         ...value
        //     }
        // }
        const existingData = JSON.parse(localStorage.getItem('userFilter')) || {};
        const updatedData = {
            ...existingData,
            page: page,
            limit: 10,
        };
        localStorage.setItem('userFilter', JSON.stringify(updatedData));
        const savedFilters = JSON.parse(localStorage.getItem('userFilter'));
        const res = await getAllUsers(savedFilters)
        setUserData(res?.data)
        const data = res?.pagination
        setPageNation({ currentPage: data?.current_page, nextPage: data?.next_page, prevPage: data?.previous_page })
    }

    useEffect(() => {
        fetchAllUsers(pageNation.currentPage)
    }, [])


    const handleBtn = async () => {
        if (Object.keys(selectedRows).length === 0) {
            toast.error('select at least one user')
        } else {
            setShowNotification(true)
        }
    }

    const handleSubmit = async (value) => {
        const selectedRowIds = Object.keys(selectedRows);
        const data = {
            ...value,
            type: "promotional",
            users: selectedRowIds,
            all_users: false
        }
        const res = await sendNotifications(data)
        if (res?.success) {
            setShowNotification(false)
        }
    }

    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="w-2/12 h-screen">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-screen w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <ManagementSecMainHeader
                            mainHeading={"Create Notification"}
                            subHeading={"Notification Management"}
                            btnText={"Create Notification"}
                            handleBtn={handleBtn}
                            handleFilterData={handleFilterData}
                            FilterComponent={UserFilter}
                        />
                    </div>
                    {/* Table */}
                    {showNotification ?
                        <div className="w-[98%] flex items-center justify-center">
                            <Suspense fallback={<div>loading...</div>} >
                                <CreateNotification handleCancel={() => setShowNotification(false)} handleSubmit={(value) => handleSubmit(value)} />
                            </Suspense>
                        </div>
                        :
                        <div className="p-8">
                            {userData &&
                                <Table columns={columns} data={userData} pageNation={pageNation} handlePageNation={(value) => fetchAllUsers(value)} />
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Index