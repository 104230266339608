import React, { memo, useEffect } from 'react';
import { usePagination, useTable } from 'react-table';
import leftArrow from "../../../../assets/events/icons/leftArrow.png";
import rightArrow from "../../../../assets/events/icons/rightArrow.png";

function Table({ columns, data, pageNation, handlePageNation, selectedRows = false, rowsPerPage = 10 }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        setPageSize,
    } = useTable(
        { columns, data, initialState: { pageIndex: 0, pageSize: rowsPerPage } },
        usePagination
    );

    useEffect(() => {
        setPageSize(rowsPerPage);
    }, [rowsPerPage, setPageSize]);

    return (
        <>
            <div className="overflow-x-auto">
                <table {...getTableProps()} className="w-full divide-y divide-gray-200">
                    <thead className="bg-[#dce2f0] h-20">
                        {headerGroups?.map((headerGroup, index) => (
                            <tr key={index} {...headerGroup?.getHeaderGroupProps()}>
                                {headerGroup?.headers?.map((column, index) => (
                                    <th key={index} {...column?.getHeaderProps()} className="p-2 text-xs font-medium tracking-wider text-left text-black uppercase">
                                        {column?.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                        {selectedRows ?
                            <>
                                {page?.map((row, index) => {
                                    prepareRow(row);
                                    const isChecked = selectedRows[row.original.id];
                                    return (
                                        <tr key={index} {...row?.getRowProps()} className={isChecked ? 'bg-blue-50' : ''}>
                                            {row?.cells?.map((cell, index) => (
                                                <td key={index} {...cell?.getCellProps()} className="p-2 whitespace-nowrap">
                                                    {cell?.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </>
                            :
                            <>
                                {page?.map((row, index) => {
                                    prepareRow(row);
                                    return (
                                        <tr key={index} {...row?.getRowProps()}>
                                            {row?.cells?.map((cell, index) => (
                                                <td key={index} {...cell?.getCellProps()} className="p-2 whitespace-nowrap">
                                                    {cell?.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </>
                        }
                    </tbody>
                </table>
            </div>
            {/* Pagination */}
            {pageNation &&
                <div className="flex justify-end">
                    <div className="flex gap-2 p-3">
                        {pageNation?.prevPage &&
                            <div onClick={() => handlePageNation(pageNation?.prevPage)}
                                className={`p-2 hover:bg-gray-200 cursor-pointer rounded-xl border-2 ${!canPreviousPage ? 'opacity-50 cursor-not-allowed' : 'border-gray-300'}`}>
                                <img src={leftArrow} alt="First page" className='w-4 h-4' />
                            </div>
                        }
                        <div className="flex shadow-xl cursor-pointer rounded-xl">
                            <div className={`p-2 text-sm bg-white text-black rounded-md border-2 border-gray-300`}>
                                {pageNation?.prevPage || 0}
                            </div>
                            <div className={`p-2 text-sm bg-brandRed rounded-md border-2 border-gray-300`}>
                                {pageNation?.currentPage}
                            </div>
                            {pageNation?.nextPage &&
                                <div className={`p-2 text-sm bg-white text-black rounded-md border-2 border-gray-300`}>
                                    {pageNation?.nextPage}
                                </div>
                            }
                        </div>
                        {pageNation?.nextPage &&
                            <div onClick={() => handlePageNation(pageNation?.nextPage)}
                                className={`p-2 hover:bg-gray-200 cursor-pointer rounded-xl border-2 ${!canNextPage ? 'opacity-50 cursor-not-allowed' : 'border-gray-300'}`}>
                                <img src={rightArrow} alt="Last page" className='w-4 h-4' />
                            </div>
                        }
                    </div>
                </div>
            }

        </>
    );
}

export default memo(Table)