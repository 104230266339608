import React, { useState, useEffect, useMemo } from 'react'
import { InputField, DropDown, RangeSliderDoublePointer } from "components"
import { Switch } from 'antd';
import languages from "../../../../../../assets/json/languages.json"
import { interests } from '../../../../../../shared/constants';
import { addLabelAndValueInData } from '../../../../../../utils/dataConversion';
import { getUserBasicFilter, updateBasicFilter } from 'apis/NEW/users';
import { toast } from 'react-toastify';

function Index({ decodedId, readOnly = true, submit = false, edit = false }) {
    const [userData, setUserData] = useState()
    const [filterDetails, setFilterDetails] = useState()

    const languagesOptions = useMemo(() => addLabelAndValueInData(languages, "name", "name"), [languages]);



    const content = [
        // { label: "Looking For", value: 'lookingFor', mode: "", isSlider: false, options: couplesLookingForOptionsCouples }, // data?.gender === 'single' ? singlesLookingForOptions :
        { label: "Age", value1: 'age_from', value2: 'age_to', isSlider: true, checkBox: 'age_expansion', minMaxRange: [18, 50] },
        { label: "Height", value1: 'height_from', value2: 'height_to', isSlider: true, checkBox: 'height_expansion', minMaxRange: [150, 200] },
        { label: "Distance Preferred", value1: 'distance_from', value2: 'distance_to', isSlider: true, checkBox: false, minMaxRange: [2, 200] },
        { label: "Interests", value: 'interests', mode: "multiple", isSlider: false, options: interests },
        { label: "Language Known", value: 'languages_known', mode: "multiple", isSlider: false, options: languagesOptions },
    ];

    const updateState = (key, value) => {
        setUserData(prev => ({ ...prev, [key]: value }));
    }


    const getUserBasicFilterDetails = async () => {
        const res = await getUserBasicFilter(decodedId)
        setFilterDetails(res)
        setUserData(res?.data?.basic)
    }

    const handleUpdateUserBasicFilter = async () => {
        const res = await updateBasicFilter(decodedId, userData)
        if (res?.success) {
            toast.success('updated Successfully')
        }
    }

    useEffect(() => {
        if (submit) {
            handleUpdateUserBasicFilter()
        }
    }, [submit]);
    useEffect(() => {
        getUserBasicFilterDetails()
    }, [])

    return (
        <div className='bg-white text-black -mt-4'>
            <div className="m-5">
                <h1 className='font-semibold text-xl pt-5'>Basic Filter</h1>
                <p className='text-gray-500 text-base'>Add time  that highlight what makes it unique <br />Event Time</p>
                {filterDetails?.basic_preferences_access ?
                    <div className="flex flex-col justify-between">
                        {content.map((item, index) => (
                            <div key={index} className="flex items-center justify-between">
                                <div className='flex flex-col'>
                                    <h1 className='font-semibold text-lg my-6'>{item.label}</h1>
                                    {item.checkBox && !edit &&
                                        <h1 className='font-semibold text-lg my-6'>Expand {item.label}</h1>
                                    }
                                </div>
                                <div className="w-[70%]">
                                    {!edit ? (
                                        <>
                                            {item.isSlider ?
                                                <>
                                                    <InputField readOnly={true} value={`${userData[item.value1]} - ${userData[item.value2]}`} />
                                                    {item.checkBox &&
                                                        <>
                                                            <InputField readOnly={true} value={userData[item.checkBox]} />
                                                        </>
                                                    }
                                                </>
                                                :
                                                <InputField readOnly={true} value={userData[item.value]} />
                                            }
                                        </>
                                    ) : item.isSlider ? (
                                        <div className='w-full h-32 border-2 border-gray-300 rounded cursor-pointer my-2'>
                                            <div className='flex flex-col justify-center mt-4'>
                                                <div className='px-4 flex flex-col justify-center'>
                                                    <p className='text-black text-[15px] font-normal'>{`Selected ${item?.label} Range: ${userData[item?.value1]} to ${userData[item?.value2]}`}</p>
                                                    <RangeSliderDoublePointer
                                                        readOnly={readOnly}
                                                        minMaxRange={item.minMaxRange}
                                                        selectedMinMaxValue={[userData[item.value1], userData[item.value2]]}
                                                        onRangeChange={(value) => {
                                                            updateState(item.value1, value[0])
                                                            updateState(item.value2, value[1])
                                                        }}
                                                    />
                                                </div>
                                                {item.checkBox &&
                                                    <div className='flex px-4 justify-between'>
                                                        <p className='text-[14px] text-gray-500'>
                                                            See people either side if I run out
                                                        </p>
                                                        <Switch checked={userData[item.checkBox]} onChange={() => updateState(item.checkBox, !userData[item.checkBox])} disabled={readOnly} className={true ? 'bg-gray-700' : 'bg-gray-400'} />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <DropDown
                                            selectedValue={userData[item.value]}
                                            mode={item.mode}
                                            options={item.options}
                                            readOnly={readOnly}
                                            handleOnChange={(value) => updateState(item.value, value)}
                                        />
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className="text-4xl font-bold">No Access to Basic Filter</div>
                }
            </div>
        </div>
    );
}

export default React.memo(Index);
