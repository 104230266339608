import React, { useState, useEffect, useCallback } from 'react';
import { Navigate } from 'react-router-dom';

const UnAuthRoute = ({ component }) => {

    const [authStatus, setAuthStatus] = useState(null);

    const authenticate = useCallback(async () => {
        const token = localStorage.getItem('adminSession')
        if (token === null) {
            setAuthStatus(false)
        } else {
            setAuthStatus(true)
        }
    }, []);

    useEffect(() => {
        authenticate();
    }, [authenticate]);

    // Initial check status
    if (authStatus === null) {
        return <div>Checking authentication...</div>;
    }

    if (authStatus) {
        return <Navigate to='/new/events' replace />;
    }

    return component;
};

export default UnAuthRoute;
