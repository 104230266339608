import { status } from 'apis/auth';
import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ component }) => {
    const location = useLocation();
    const [authStatus, setAuthStatus] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const authenticate = async () => {
        const result = await status();
        if (result) {
            setAuthStatus(true);
        } else {
            setAuthStatus(false);
        }
        setIsLoading(false)
    };

    useEffect(() => {
        authenticate();
    }, [location]);

    // Initial check status
    if (isLoading) {
        return <div>Checking authentication...</div>;
    }

    if (!authStatus) {
        localStorage.removeItem('ad\minSession');
        return <Navigate to='/login' replace />;
    }

    return component;
};

export default ProtectedRoute;
