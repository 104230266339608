
import React, { useEffect, useState } from 'react';
import StatsCard from 'components/NEW/cards/homeOverview';
import { MainSidebar, ManagementSecMainHeader, MainNavbar } from "components"
import { getDashboardOverview } from 'apis/NEW/home';


const Index = () => {
    const [overView, setOverView] = useState()
    // const planStats = [
    //     { label: 'Views', value: 7265 },
    //     { label: 'Visits', value: 3671 },
    //     { label: 'New Users', value: 156 },
    //     { label: 'Active Users', value: 2318 },
    //     { label: 'Active Users', value: 2318 },
    //     { label: 'Views', value: 7265 },
    //     { label: 'Visits', value: 3671 },
    //     { label: 'New Users', value: 156 },
    //     { label: 'Active Users', value: 2318 },
    //     { label: 'Active Users', value: 2318 },
    // ];

    // const revenueStats = [
    //     { label: 'Views', value: 7265 },
    //     { label: 'Visits', value: 3671 },
    //     { label: 'New Users', value: 156 },
    //     { label: 'Active Users', value: 2318 },
    //     { label: 'Active Users', value: 2318 },
    // ];

    const fetchOverView = async () => {
        const res = await getDashboardOverview()
        if (res?.data) {
            setOverView(res?.data)
        }
    }

    useEffect(() => {
        fetchOverView()
    }, [])

    return (
        <div className='flex flex-col h-screen'>

            {/* Header */}
            <div className="min-h-[120px] w-full">
                <MainNavbar />
            </div>

            <div className="flex">
                {/* SideBar */}
                <div className="h-screen w-2/12">
                    <MainSidebar />
                </div>
                {/* Main Content Section */}
                <div className="bg-[#f8f8f8] h-screen w-10/12">
                    {/* Header */}
                    <div className="w-[96%] mx-auto my-10">
                        <ManagementSecMainHeader
                            mainHeading={"Overview"}
                            subHeading={"Overview"}
                            btnText={"View"}
                            handleBtn={"handleBtn"}
                        />
                    </div>
                    <div className="min-h-screen bg-gray-100 p-8">
                        <div className="container mx-auto">
                            {overView?.map((item, index) => (
                                <StatsCard key={index} title={item?.label} stats={item?.value} />
                            ))}
                            {/* <StatsCard title="Revenue" stats={revenueStats} /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
