import React from 'react';

const ToggleButton = ({ checked, onChange, readOnly = false }) => {
    const handleChange = (event) => {
        if (readOnly) {
            event.preventDefault();
            return;
        }
        onChange(event);
    }

    return (
        <label className="relative inline-block w-14 h-8">
            <input
                type="checkbox"
                className="opacity-0 w-0 h-0"
                checked={checked}
                onChange={handleChange}
                readOnly={readOnly}
            />
            <span className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 transition-colors duration-200 ease-in-out before:absolute before:top-1 before:left-1 before:bg-white before:rounded-full before:h-6 before:w-6 before:transition-transform before:duration-200 ${checked ? 'bg-red-500 before:translate-x-6' : 'bg-gray-300'} rounded-3xl`}>
            </span>
        </label>
    );
}

export default React.memo(ToggleButton);
