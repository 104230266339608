import { InputField } from 'components';
import React, { useState } from 'react';

function Index({ handleSubmit, handleCancel }) {

    const [notificationData, setNotificationData] = useState({
        title: '',
        body: '',
    })

    const content = [
        { label: 'Title', value: 'title', isTextArea: false },
        { label: 'Body', value: 'body', isTextArea: true },
    ]

    const updateState = (key, value) => {
        setNotificationData(prev => ({ ...prev, [key]: value }))
    }


    return (
        <div className="w-full mx-auto p-6 bg-white shadow-md rounded-md">
            <h2 className="text-xl font-bold mb-4">Create Notification</h2>
            <p className="mb-2">Add time that highlight what makes it unique</p>
            <p className="mb-6">Event Time</p>

            <div className="flex flex-col">
                {content?.map((item, index) => (
                    <div className="w-1/2 px-2 my-6" key={index}>
                        <div className='flex items-center justify-between'>
                            <label className="text-lg text-gray-700">{item?.label}</label>
                            <div className={`w-[450px] h-[56px] -mt-4`}>
                                <InputField onChange={(value) => updateState(item?.value, value)} textArea={item?.isTextArea} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>


            <div className="flex justify-center mt-10">
                <button type="button" className="px-4 py-2 bg-gray-200 text-gray-700 w-[120px] h-[50px]" onClick={handleCancel}>Cancel</button>
                <button type="submit" className="px-4 py-2 bg-brandRed text-white w-[120px] h-[50px]" onClick={() => handleSubmit(notificationData)}>Submit</button>
            </div>
        </div >
    );
};

export default Index;