import React, { useState, useEffect, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SIDEBAR } from 'common/routes';

// Assets
import homeActive from "assets/mainSidebar/icons/homeActive.svg";
import homeInactive from "assets/mainSidebar/icons/homeInactive.svg";
import downArrowIcon from "assets/mainSidebar/icons/downArrow.svg";
import userActiveIcon from "assets/mainSidebar/icons/userActiveIcon.svg";
import userInactiveIcon from "assets/mainSidebar/icons/userInactiveIcon.svg";
import eventActiveIcon from "assets/mainSidebar/icons/eventActiveIcon.svg";
import eventInactiveIcon from "assets/mainSidebar/icons/eventInactiveIcon.svg";
import planActiveIcon from "assets/mainSidebar/icons/planActiveIcon.svg";
import planInacriveIcon from "assets/mainSidebar/icons/planInacriveIcon.svg";
import coupounActiveIcon from "assets/mainSidebar/icons/coupounActiveIcon.svg";
import coupounInactiveIcon from "assets/mainSidebar/icons/coupounInactiveIcon.svg";
import notificationActiveIcon from "assets/mainSidebar/icons/notificationActiveIcon.svg";
import notificationInactiveIcon from "assets/mainSidebar/icons/notificationInactiveIcon.svg";
import verificationActiveIcon from "assets/mainSidebar/icons/verificationActiveIcon.svg";
import verificationInactiveIcon from "assets/mainSidebar/icons/verificationInactiveIcon.svg";
import mobileActiveIcon from "assets/mainSidebar/icons/mobileActive.svg";
import mobileInactiveIcon from "assets/mainSidebar/icons/mobileInactive.svg";
import affiliateReferralIcon from "assets/mainSidebar/icons/referral_icon.svg";
import affiliateReferralInactiveIcon from "assets/mainSidebar/icons/referral_icon_inactive.svg";

const sidebarItems = {
    home: [
        { name: 'OverView', location: SIDEBAR.HOME_OVERVIEW },
    ],
    user: [
        { name: 'View User', location: SIDEBAR.VIEW_USERS },
        { name: 'Deleted Users', location: SIDEBAR.DELETED_USERS },
    ],
    event: [
        { name: 'Events', location: SIDEBAR.EVENTS },
        { name: 'Event Connection', location: SIDEBAR.EVENTS_CONNECTIONS },
        { name: 'Event Comments', location: SIDEBAR.EVENTS_COMMENTS },
        { name: 'Event Category', location: SIDEBAR.EVENTS_CATEGORY },
        { name: 'Event Location', location: SIDEBAR.EVENTS_LOCATION },
        { name: 'Event Tags', location: SIDEBAR.EVENTS_TAG },
        { name: 'Event Host', location: SIDEBAR.EVENTS_HOST },
    ],
    plan: [
        { name: 'Plan', location: SIDEBAR.PLANS },
        { name: 'Plan Category', location: SIDEBAR.PLAN_CATEGORY },
        { name: 'Plan Limitation', location: SIDEBAR.PLAN_LIMITATION },
        { name: 'Plan Coin', location: SIDEBAR.PLAN_COIN },
    ],
    coupon: [
        { name: 'Coupons', location: SIDEBAR.COUPON },
    ],
    notification: [
        { name: 'Notification', location: SIDEBAR.NOTIFICATION },
    ],
    verification: [
        { name: 'Verification', location: SIDEBAR.VERIFICATION },
    ],
    affiliateReferral: [
        { name: 'Affiliate Coupons', location: SIDEBAR.AFFILIATE_COUPONS },
    ],
    appUpdate: [
        { name: 'AppUpdate', location: SIDEBAR.APP_UPDATE },
    ],

};

function Index() {
    const location = useLocation();
    const [currentTab, setCurrentTab] = useState();
    const [dropdownOpen, setDropdownOpen] = useState({
        home: false,
        user: false,
        event: false,
        plan: false,
        coupon: false,
        notification: false,
        verification: false,
        affiliateReferral: true,
        appUpdate: false,
    });

    const toggleDropdown = (key) => setDropdownOpen(prev => ({ ...prev, [key]: !prev[key] }));

    const allItems = useMemo(() => [
        ...sidebarItems.home,
        ...sidebarItems.event,
        ...sidebarItems.user,
        ...sidebarItems.plan,
        ...sidebarItems.coupon,
        ...sidebarItems.notification,
        ...sidebarItems.verification,
        ...sidebarItems.appUpdate,
        ...sidebarItems.affiliateReferral,
    ], []);

    useEffect(() => {
        const currentSidebarItem = allItems.find(item => item.location === location.pathname);
        setCurrentTab(currentSidebarItem ? currentSidebarItem.name : undefined);
    }, [location, allItems]);

    useEffect(() => {
        const activateDropdown = (items) => items.some(item => item.name === currentTab);
        setDropdownOpen({
            user: activateDropdown(sidebarItems.user),
            home: activateDropdown(sidebarItems.home),
            event: activateDropdown(sidebarItems.event),
            plan: activateDropdown(sidebarItems.plan),
            coupon: activateDropdown(sidebarItems.coupon),
            notification: activateDropdown(sidebarItems.notification),
            verification: activateDropdown(sidebarItems.verification),
            affiliateReferral: activateDropdown(sidebarItems.affiliateReferral),
            appUpdate: activateDropdown(sidebarItems.appUpdate),
        });
    }, [currentTab]);

    const sidebarMenus = [
        { title: "Home", icon: [homeActive, homeInactive], items: sidebarItems.home, isOpen: dropdownOpen.home, toggleDropdown: 'home', currentTab: currentTab },
        { title: "User Management", icon: [userActiveIcon, userInactiveIcon], items: sidebarItems.user, isOpen: dropdownOpen.user, toggleDropdown: 'user', currentTab: currentTab },
        { title: "Event Management", icon: [eventActiveIcon, eventInactiveIcon], items: sidebarItems.event, isOpen: dropdownOpen.event, toggleDropdown: 'event', currentTab: currentTab },
        { title: "Plan Management", icon: [planActiveIcon, planInacriveIcon], items: sidebarItems.plan, isOpen: dropdownOpen.plan, toggleDropdown: 'plan', currentTab: currentTab },
        { title: "Coupon Management", icon: [coupounActiveIcon, coupounInactiveIcon], items: sidebarItems.coupon, isOpen: dropdownOpen.coupon, toggleDropdown: 'coupon', currentTab: currentTab },
        { title: "Notification Management", icon: [notificationActiveIcon, notificationInactiveIcon], items: sidebarItems.notification, isOpen: dropdownOpen.notification, toggleDropdown: 'notification', currentTab: currentTab },
        { title: "Verification Management", icon: [verificationActiveIcon, verificationInactiveIcon], items: sidebarItems.verification, isOpen: dropdownOpen.verification, toggleDropdown: 'verification', currentTab: currentTab },
        { title: "Affiliate Coupons", icon: [affiliateReferralIcon, affiliateReferralInactiveIcon], items: sidebarItems.affiliateReferral, isOpen: dropdownOpen.affiliateReferral, toggleDropdown: 'affiliateReferral', currentTab: currentTab },
        { title: "App Update", icon: [mobileActiveIcon, mobileInactiveIcon], items: sidebarItems.appUpdate, isOpen: dropdownOpen.appUpdate, toggleDropdown: 'appUpdate', currentTab: currentTab },
    ]

    return (
        <div className='w-full h-full text-black bg-white'>
            <div className='flex items-center justify-center my-5 text-base font-bold text-gray-500'>
                <h1>Main Menu</h1>
            </div>
            {/* <div className="flex items-center p-4 cursor-pointer hover:bg-gray-100">
                <img src={homeIcon} alt="Home" className="w-6 h-6 mr-2" />
                <span>Home</span>
            </div> */}
            {sidebarMenus?.map((item, index) => (
                <SidebarSection
                    key={index}
                    title={item?.title}
                    icon={item?.icon}
                    items={item?.items}
                    isOpen={item?.isOpen}
                    toggleDropdown={() => toggleDropdown(item?.toggleDropdown)}
                    currentTab={item?.currentTab}
                />
            ))}
        </div>
    );
}

export default Index;

const SidebarSection = ({ title, icon, items, isOpen, toggleDropdown, currentTab }) => (
    <div className="flex flex-col">
        <div className="flex items-center p-4 cursor-pointer hover:bg-gray-100" onClick={toggleDropdown}>
            <img src={items.some(item => item.name === currentTab) ? icon[0] : icon[1]} alt={title} className="w-6 h-6 mr-2" />
            <span className={items.some(item => item.name === currentTab) ? 'text-brandRed' : 'text-black'}>{title}</span>
            <span className="ml-auto">
                {/* <img src={downArrowIcon} alt="Toggle" /> */}
                <img src={downArrowIcon} alt="Toggle" className={`transition-transform duration-300 ${isOpen ? '-rotate-180' : ''}`} />
            </span>
        </div>
        {isOpen && (
            <div className="flex flex-col ml-8">
                {items.map((item, index) => (
                    <Link key={index} to={item.location} className={`p-4 hover:bg-gray-100 cursor-pointer ${currentTab === item.name ? 'text-brandRed' : 'text-black'}`}>{item.name}</Link>
                ))}
            </div>
        )}
    </div>
);
