import React from 'react'

const CountsCard = ({ icon, bgColor, count, title }) => {
  return (
    <div className="bg-white rounded-3xl w-3/6 h-20 shadow-md flex items-center justify-start gap-5 p-2">
        <div className={`rounded-full p-3 w-fit bg-opacity-35 ${bgColor}`}>
            <img src={icon} alt="total_referrals" className='w-full h-full'/>
        </div>
        <div className="flex flex-col items-start justify-center">
            <h3 className='text-black font-semibold text-xl'>{count}</h3>
            <p className='text-black opacity-50 font-semibold text-base capitalize'>{title}</p>
        </div>
    </div>
  )
}

export default CountsCard