import React, { useEffect, useState, lazy, Suspense } from 'react'
import { toast } from "react-toastify"
import { getAllEventCategory, createCategory, updateCategory } from '../../../../apis/NEW/events'
import { ManagementSecMainHeader, MainSidebar, MainNavbar, TableWithoutPagination } from 'components'

const CategoryAddAndEdit = lazy(() => import("components").then(module => ({ default: module.AddAndEditCategory })))


function Index() {

    const [showCategoryModule, setShowCategoryModule] = useState(false)
    const [categoryData, setCategoryData] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const [isEdit, setIsEdit] = useState(false)

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Category  Name', accessor: 'name' },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <>
                    {value ?
                        <span className='p-2 text-green-500 bg-green-100 rounded-3xl'>
                            active
                        </span>
                        :
                        <span className='p-2 text-red-500 bg-red-100 rounded-3xl'>
                            Inactive
                        </span>
                    }
                </>

            )
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 px-3 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                </div>
            )

        },
    ];


    const handleEdit = async (row) => {
        setIsEdit(true)
        setSelectedCategory(row)
        setShowCategoryModule(!showCategoryModule)
    };

    const fetchEventCategory = async () => {
        const res = await getAllEventCategory()
        setCategoryData(res?.data)
    }

    useEffect(() => {
        fetchEventCategory()
    }, [])

    const handleSubmit = async (value) => {
        const operation = isEdit ? 'updating' : 'creating';
        const successMessage = isEdit ? 'Updated category successfully...' : 'Created category successfully...';
        const toastId = toast.loading(`${operation} Category`);
        try {
            const res = isEdit ? await updateCategory(selectedCategory.id, value) : await createCategory(value);
            toast.dismiss(toastId);
            if (res.success) {
                toast.success(successMessage);
                if (isEdit) {
                    setIsEdit(false);
                }
                setShowCategoryModule(false);
                fetchEventCategory();
            }
        } catch (error) {
            toast.error(`Failed to ${operation.toLowerCase()} category.`);
            toast.dismiss(toastId);
        }
    };



    const handleClose = () => {
        setShowCategoryModule(!showCategoryModule)
        setIsEdit(false)
        setSelectedCategory(null)
    }

    return (
        <>
            <div className={`flex flex-col h-full`}>
                {showCategoryModule && (
                    // <div className="absolute inset-0 z-50 flex items-center justify-center h-screen bg-black bg-opacity-50">
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <Suspense fallback={<div>Loading...</div>}>
                            <CategoryAddAndEdit handleClose={handleClose} handleSubmit={handleSubmit} data={selectedCategory} />
                        </Suspense>
                    </div>
                )}

                {/* Header */}
                <div className="min-h-[120px] w-full">
                    <MainNavbar />
                </div>

                <div className="flex">
                    {/* SideBar */}
                    <div className="w-2/12 h-full">
                        <MainSidebar />
                    </div>
                    {/* Main Content Section */}
                    <div className="bg-[#f8f8f8] w-10/12">
                        {/* Header */}
                        <div className="w-[96%] mx-auto my-10">
                            <ManagementSecMainHeader
                                mainHeading={"Event"}
                                subHeading={"Manage your events effectively"}
                                btnText={"Add Category"}
                                handleBtn={() => setShowCategoryModule(!showCategoryModule)} />
                        </div>

                        {/* Table */}
                        <div className="w-full p-8">
                            {categoryData &&
                                <TableWithoutPagination columns={columns} data={categoryData} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index