import React, { useState } from 'react';

function Index({ imageUrl, mainText, subText1, edit = false, handleRemove, viewUser }) {
    const [showDropdown, setShowDropdown] = useState(false);

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const hideDropdown = () => {
        setShowDropdown(false);
    };


    return (
        <div className="w-full overflow-hidden shadow-lg rounded-3xl bg-white p-4 text-center border relative">
            <div className="flex gap-2 mt-4">
                <img onClick={viewUser} className="w-full h-40 object-cover rounded-3xl cursor-pointer" src={imageUrl} alt="Card" />
            </div>

            <div className="mt-4 text-xl font-medium">{mainText}</div>
            <p className="text-gray-600 my-2">{subText1}</p>
            {/* <p className="text-gray-600 my-2">{subText2}</p> */}

            {/* Dropdown toggle button */}
            {edit &&
                <button onClick={toggleDropdown} className="absolute top-3 right-3">
                    <span className="text-3xl leading-none">⋮</span>
                </button>
            }

            {showDropdown && (
                <div className="absolute top-12 right-3 bg-white rounded-md shadow-lg border p-2 w-32">
                    <ul className="text-left">
                        <li className="p-2 hover:bg-brandRed hover:text-white cursor-pointer" onClick={handleRemove}>Remove</li>
                        <li className="p-2 hover:bg-brandRed hover:text-white cursor-pointer" onClick={hideDropdown}>Cancel</li>
                    </ul>
                </div>
            )}
        </div>
    );
}

export default React.memo(Index);
