import React, { useEffect, useState, lazy, Suspense } from 'react'
import { toast } from "react-toastify"
import { getAllLocation, createLocation, updateLocation } from '../../../../apis/NEW/events'
import { ManagementSecMainHeader, MainSidebar, MainNavbar, TableWithoutPagination } from 'components'

const AddAndEditLocation = lazy(() => import("components").then(module => ({ default: module.AddAndEditLocation })))


function Index() {

    const [showLocationModule, setShowLocationModule] = useState(false)
    const [locationData, setLocationData] = useState()
    const [selectedLocation, setSelectedLocation] = useState()
    const [isEdit, setIsEdit] = useState(false)

    const columns = [
        {
            Header: 'No',
            accessor: (row, rowIndex) => rowIndex + 1,
            id: 'no'
        },
        { Header: 'Location Name', accessor: 'name' },
        { Header: 'Country', accessor: 'country' },
        { Header: 'State', accessor: 'state' },
        { Header: 'City', accessor: 'city' },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ value }) => (
                <>
                    {value ?
                        <span className='p-2 text-green-500 bg-green-100 rounded-3xl'>
                            active
                        </span>
                        :
                        <span className='p-2 text-red-500 bg-red-100 rounded-3xl'>
                            Inactive
                        </span>
                    }
                </>

            )
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row }) => (
                <div>
                    <button onClick={() => handleEdit(row)} className='p-2 px-3 mr-1 text-white bg-blue-500 rounded-lg'>Edit</button>
                </div>
            )

        },
    ];


    const handleEdit = async (row) => {
        setIsEdit(true)
        setSelectedLocation(row)
        setShowLocationModule(!showLocationModule)
    };

    const fetchEventLocation = async () => {
        const res = await getAllLocation()
        setLocationData(res?.data)
    }

    useEffect(() => {
        fetchEventLocation()
    }, [])

    const handleSubmit = async (value) => {
        const operation = isEdit ? 'updating' : 'creating';
        const successMessage = isEdit ? 'Updated Location successfully...' : 'Created Location successfully...';
        const toastId = toast.loading(`${operation} Location`);
        try {
            const res = isEdit ? await updateLocation(selectedLocation.id, value) : await createLocation(value);
            toast.dismiss(toastId);
            if (res.success) {
                toast.success(successMessage);
                if (isEdit) {
                    setIsEdit(false);
                }
                setShowLocationModule(false);
                fetchEventLocation();
            }
        } catch (error) {
            toast.error(`Failed to ${operation.toLowerCase()} location.`);
            toast.dismiss(toastId);
        }
    };



    const handleClose = () => {
        setShowLocationModule(!showLocationModule)
        setIsEdit(false)
        setSelectedLocation(null)
    }

    return (
        <>
            <div className={`flex flex-col h-full`}>
                {showLocationModule && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center h-full bg-black bg-opacity-50">
                        <div className="relative w-[34%] max-h-full p-6 overflow-auto bg-white rounded-lg">

                            <Suspense fallback={<div>Loading...</div>}>
                                <AddAndEditLocation handleClose={handleClose} handleSubmit={handleSubmit} data={selectedLocation} />
                            </Suspense>
                        </div>
                    </div>
                )}

                {/* Header */}
                <div className="min-h-[120px] w-full">
                    <MainNavbar />
                </div>

                <div className="flex">
                    {/* SideBar */}
                    <div className="w-2/12 h-full">
                        <MainSidebar />
                    </div>
                    {/* Main Content Section */}
                    <div className="bg-[#f8f8f8] w-10/12">
                        {/* Header */}
                        <div className="w-[96%] mx-auto my-10">
                            <ManagementSecMainHeader
                                mainHeading={"Event"}
                                subHeading={"Manage your events effectively"}
                                btnText={"Add Location"}
                                handleBtn={() => setShowLocationModule(!showLocationModule)} />
                        </div>

                        {/* Table */}
                        <div className="w-full p-8">
                            {locationData &&
                                <TableWithoutPagination columns={columns} data={locationData} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Index