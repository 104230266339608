import React, { memo } from 'react';

function RadioBtnWithIcon({ icon, isSelectedIcon, text, isSelected, onChange, readOnly = false }) {
    const borderColor = isSelected ? 'border-brandRed' : 'border-gray-300';

    const handleClick = (event) => {
        if (readOnly) {
            event.preventDefault();
            return;
        }
        onChange();
    };

    return (
        <div className={`flex items-center justify-between border ${borderColor} p-[1.7rem] rounded-3xl w-full h-full cursor-pointer`}
            onClick={handleClick}>
            <div className="flex items-center ms-2">
                <img src={isSelected ? isSelectedIcon : icon} alt="icon" className="h-6 w-6 mr-2" />
                <span className={`mr-2 ${isSelected ? "text-brandRed" : 'text-black'}`}>{text}</span>
            </div>
            <input
                type="radio"
                checked={isSelected}
                className="form-radio h-4 w-4 text-brandRed me-2"
                onClick={(e) => e.stopPropagation()}
            />
        </div>
    );
}

export default memo(RadioBtnWithIcon);
