import React from 'react';
import { Link } from 'react-router-dom';
import { EVENTS } from '../../../../common/routes';


function Index({ mainHeading, subHeading }) {
    return (
        <div className="bg-[#f8f8f8] w-full  px-4 py-2 flex justify-between items-center">
            {/* Left Section with Event Texts */}
            <div>
                <h1 className="text-xl font-bold text-gray-800">{mainHeading}</h1>
                <p className="text-sm text-gray-600">{subHeading}</p>
            </div>

            {/* Right Section with Search and Icons */}
            <div className="flex items-center space-x-4">
                {/* Action Button */}
                <Link to={EVENTS.EVENTS} className="h-16 w-44 border-2 text-black border-gray-300 px-4 py-2 rounded-xl flex items-center justify-center cursor-pointer">
                    Cancel
                </Link>
            </div>
        </div>
    );
}

export default React.memo(Index);
