import { getAllPlansCategory } from 'apis/NEW/plans';
import { DropDown, InputField } from 'components';
import React, { useEffect, useState } from 'react';
import { genderOptions } from 'shared/constants';
import { addLabelAndValueInData } from 'utils/dataConversion';

function Index({ data, handleSubmit, handleCancel }) {

  const getDynamicKeyValue = (type, data) => {
    switch (type) {
      case 'access':
        return { key: 'access', value: data?.access || '' };
      case 'unlimited':
        return { key: 'unlimited', value: data?.unlimited || '' };
      case 'limit':
        return { key: 'limit', value: data?.limit || '' };
      default:
        return { key: '', value: '' };
    }
  };

  const initialDynamic = getDynamicKeyValue(data?.limitation_type, data);

  const [planCategory, setPlanCategory] = useState([]);
  const [planLimitation, setPlanLimitation] = useState({
    limitation_name: data?.limitation_name,
    gender: data?.gender,
    limitation_type: data?.limitation_type,
    status: data?.status,
    plan_category: data?.plan_category?.id,
    id: data?.id,
    [initialDynamic.key]: initialDynamic.value
  });

  const limitationTypeOptions = [
    { label: 'Access', value: 'access' },
    { label: 'Limit', value: 'limit' },
    { label: 'Unlimited', value: 'unlimited' },
  ];

  const accessOptions = [
    { label: 'Access', value: true },
    { label: 'NoAccess', value: false }
  ]

  const unLimitedOptions = [
    { label: 'Unlimited', value: true },
    { label: 'limited', value: false }
  ]

  const content = [
    { label: 'Limitation Name', value: 'limitation_name', isDropDown: false },
    { label: 'Gender', value: 'gender', isDropDown: true, options: genderOptions },
    { label: 'Limitation Type', value: 'limitation_type', isDropDown: true, options: limitationTypeOptions },
    { label: 'Status', value: 'status', isDropDown: true, options: [{ label: 'Active', value: true }, { label: 'Inactive', value: false }] },
    { label: 'Value', value: planLimitation.limitation_type, isDropDown: planLimitation.limitation_type === 'limit' ? false : true, options: planLimitation.limitation_type === 'access' ? accessOptions : unLimitedOptions },
    { label: 'Plan Category', value: 'plan_category', isDropDown: true, options: planCategory },
  ];

  const updateState = (key, value) => {
    setPlanLimitation(prev => ({ ...prev, [key]: value }));
  };

  const fetchAllPlansCategory = async () => {
    const res = await getAllPlansCategory();
    const categories = addLabelAndValueInData(res?.data, 'name', 'id');
    setPlanCategory(categories);
  };

  useEffect(() => {
    fetchAllPlansCategory();
  }, []);

  useEffect(() => {
    const dynamic = getDynamicKeyValue(planLimitation.limitation_type, data);
    setPlanLimitation(prev => ({ ...prev, [dynamic.key]: dynamic.value }));
  }, [planLimitation.limitation_type, data]);


  const handleOnClickSubmit = () => {
    const data = {
      limitation_name: planLimitation?.limitation_name,
      gender: planLimitation?.gender,
      limitation_type: planLimitation?.limitation_type,
      status: planLimitation?.status,
      plan_category: planLimitation?.plan_category,
      id: planLimitation?.id || 123456789,
      [planLimitation.limitation_type]: planLimitation[planLimitation.limitation_type]
    }
    handleSubmit(data)
  }

  return (
    <div className="w-full mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-bold mb-4">Create Plan name</h2>
      <p className="mb-2">Add time that highlight what makes it unique</p>
      <p className="mb-6">Event Time</p>

      <div className="flex flex-wrap -mx-2">
        {content?.map((item, index) => (
          <div className="w-1/2 px-2 my-6" key={index}>
            <div className='flex items-center justify-between'>
              <label className="text-lg text-gray-700">{item?.label}</label>
              <div className="w-[450px] h-[56px] -mt-4">
                {item?.isDropDown ?
                  <DropDown selectedValue={planLimitation[item?.value]} handleOnChange={(value) => updateState(item?.value, value)} options={item?.options} />
                  :
                  <InputField value={planLimitation[item?.value]} onChange={(value) => updateState(item?.value, value)} />
                }
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-10">
        <button type="button" className="px-4 py-2 bg-gray-200 text-gray-700 w-[120px] h-[50px]" onClick={handleCancel}>Cancel</button>
        <button type="submit" className="px-4 py-2 bg-brandRed text-white w-[120px] h-[50px]" onClick={handleOnClickSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default Index;
