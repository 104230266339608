import React, { useRef } from 'react';
import left_arrow from 'assets/icons/left_arrow.svg'
import right_arrow from 'assets/icons/right_arrow.svg'
import { smoothScrollXAxis } from 'utils/scroller';

const Index = ({ title, stats }) => {

    const scrollContainerRef = useRef(null);

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            smoothScrollXAxis(scrollContainerRef.current, -300, 500);
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            smoothScrollXAxis(scrollContainerRef.current, 300, 500);
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-lg font-semibold">{title}</h2>
            <div className="flex items-center justify-between">
                <img onClick={scrollLeft} src={left_arrow} alt="left_arrow" className='cursor-pointer' />
                <img onClick={scrollRight} src={right_arrow} alt="right_arrow" className='cursor-pointer' />
            </div>
            <div ref={scrollContainerRef} className="flex overflow-x-auto space-x-4 hide-scrollbar">
                {stats.map((stat, index) => (
                    <div key={index} className={`${index % 2 === 0 ? 'bg-[#e5ecf6]' : 'bg-[#e3f5ff]'} p-4 rounded-md w-[210px] h-[112px] shrink-0`}>
                        <div className="">{stat?.label}</div>
                        <div className="text-2xl font-bold">{stat?.count?.toLocaleString()}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Index;
