import { GENDER, accountStatus, relationshipStatusOptions, verificationOptions } from 'shared/constants';
import { generateNumberOptions } from 'utils/helper';


// SET
export const initializeUserFilter = () => {
    const savedFilters = JSON.parse(localStorage.getItem('userFilter'));
    return savedFilters || {
        search: null,
        sex: null,
        age_from: null,
        age_to: null,
        caste: null,
        religion: null,
        height_from: null,
        height_to: null,
        phone: null,
        account_status: null,
        verification: null,
        marital_status: null,
        relationship_status: null,
        joinedFrom: null,
        joinedTo: null,
    };
};

export const initializeDropDownContent = (userFilter,religionOption, planCategories, castOption, plans) => {
    return ([
        { selectedValue: userFilter?.sex || null, label: 'sex', options: GENDER },
            { selectedValue: userFilter?.religion || null, label: 'religion', options: religionOption },
            { selectedValue: userFilter?.caste || null, label: 'caste', options: castOption },
            { selectedValue: userFilter?.verification || null, label: 'verification', options: verificationOptions },
            { selectedValue: userFilter?.relationship_status || null, label: 'relationship_status', options: relationshipStatusOptions },
            { selectedValue: userFilter?.marital_status || null, label: 'marital_status', options: [] },
            { selectedValue: userFilter?.account_status === true ? 'Blocked' : (userFilter?.account_status === false ?'Active' : null), label: 'account_status', options: accountStatus },
            { selectedValue: userFilter?.plan_categories || null, label: 'plan_categories', options: planCategories },
            { selectedValue: userFilter?.plans || null, label: 'planId', options: plans }, 
    ])
}

export const initializeDoubleDropDownContent = (userFilter)=>[
    { selectedValues: [userFilter?.age_from || null, userFilter?.age_to || null], label: 'age', options: generateNumberOptions(18, 55) },
    { selectedValues: [userFilter?.height_from || null, userFilter?.height_to || null], label: 'height', options: generateNumberOptions(140, 250) },
]


//  REST
export const resetDropDownContent = (religionOption,castOption, plans) => {
    return ([
        { selectedValue: null, label: 'sex', options: GENDER },
            { selectedValue: null, label: 'religion', options: religionOption },
            { selectedValue: null, label: 'caste', options: castOption },
            { selectedValue: null, label: 'verification', options: verificationOptions },
            { selectedValue: null, label: 'relationship_status', options: relationshipStatusOptions },
            { selectedValue: null, label: 'marital_status', options: [] },
            { selectedValue: null, label: 'account_status', options: accountStatus },
            //{ selectedValue: null, label: 'plan_categories', options: planCategories },
            { selectedValue: null, label: 'planId', options: plans },
    ])
}

export const resetDoubleDropDownContent = () => [
    { selectedValues: [null, null], label: 'age', options: generateNumberOptions(18, 55) },
    { selectedValues: [null, null], label: 'height', options: generateNumberOptions(140, 240) },
]