import React from "react";
import Error404 from "../pages/404";
import Login from "../pages/Login";

// NEW VERSION
// Home
import OverView from '../pages/NEW/home/overView';

// Event Management
import CreateEvent from '../pages/NEW/eventManagement/createEvent';
import Events from '../pages/NEW/eventManagement/events';
import EventComment from '../pages/NEW/eventManagement/eventComment';
import EventPreview from '../pages/NEW/eventManagement/eventPreview'
import EditEvent from '../pages/NEW/eventManagement/editEvent';
import EventConnection from '../pages/NEW/eventManagement/eventConnection';
import EventParticipants from '../pages/NEW/eventManagement/eventParticipants';
import EventCategory from '../pages/NEW/eventManagement/eventCategory';
import EventLocation from '../pages/NEW/eventManagement/eventLocation';
import EventTag from '../pages/NEW/eventManagement/eventTag';
import EventHosts from '../pages/NEW/eventManagement/eventHost';

// User Management 
import ViewAllUsers from '../pages/NEW/userManagement/users';
import ViewUserDetails from '../pages/NEW/userManagement/userDetails';
import EditUserDetails from '../pages/NEW/userManagement/editUser';
import DeletedUsers from '../pages/NEW/userManagement/deletedUser';
import CreateUser from '../pages/NEW/userManagement/createUser';

//  Plan and Limitation Management
import Plans from '../pages/NEW/planManagement/plans';
import PlanCategory from '../pages/NEW/planManagement/planCategory';
import PlanCoins from '../pages/NEW/planManagement/planCoins';
import PlanLimitation from '../pages/NEW/planManagement/planLimitation';

//  Coupons Management
import Coupons from '../pages/NEW/couponsManagement/coupons';

// Notification Management
import Notification from '../pages/NEW/notificationManagement/notifications';

// Verification Management
import Verification from '../pages/NEW/verificationManagement/verification';

// AppUpdateManagement
import AppUpdate from '../pages/NEW/appUpdateManagement/appUpdate';

// Affiliate Coupons Management
import AffiliateCoupons from '../pages/NEW/affiliateReferralsManagement';
import TrackAffiliateCoupons from '../pages/NEW/affiliateReferralsManagement/trackReferral/index';

// Auth and UnAuth
import UnAuthRoute from "components/NEW/isAuth/unAuth";
import ProtectedRoute from "components/NEW/isAuth/auth";

import { AFFILIATE_COUPONS, EVENTS, SIDEBAR, USERS } from "./routes";

export const Links = [    
    // ============ NEW VERSION  ============
    {
        name: "Login",
        path: "/login",
        element: <UnAuthRoute component={<Login />} />,
        showInNavigation: true,
    },
    //  ========== Home ==========
    {
        name: "OverView",
        path: SIDEBAR.HOME_OVERVIEW,
        element: <ProtectedRoute component={<OverView />} />,
        showInNavigation: true,
    },


    // Event Management
    {
        name: "Display all the Events",
        path: SIDEBAR.EVENTS,
        element: <ProtectedRoute component={<Events/>} />,
        showInNavigation: false,
    },
    {
        name: "Display all the Events for Comment Management",
        path: SIDEBAR.EVENTS_COMMENTS,
        element: <ProtectedRoute component={<EventComment/>} />,
        showInNavigation: false,
    },
    {
        name: "Create New Event",
        path: EVENTS.CREATE_EVENT,
        element: <ProtectedRoute component={<CreateEvent />} />,
        showInNavigation: false,
    },
    {
        name: "Create New Event",
        path: `${EVENTS.VIEW_EVENT}/:slug/:comment`,
        element: <EventPreview/>,
        showInNavigation: false,
    },
    {
        name: "Edit Event",
        path: `${EVENTS.EDIT_EVENT}/:id`,
        element: <ProtectedRoute component={<EditEvent />}/>,
        showInNavigation: false,
    },
    {
        name: "Enable Event Connection",
        path: SIDEBAR.EVENTS_CONNECTIONS,
        element: <ProtectedRoute component={<EventConnection />}/>,
        showInNavigation: false,
    },
    {
        name: "View Event Participants",
        path: `${EVENTS.VIEW_EVENT_PARTICIPANT}/:id`,
        element: <ProtectedRoute component={<EventParticipants />}/>,
        showInNavigation: false,
    },
    {
        name: "Create Event Category",
        path: SIDEBAR.EVENTS_CATEGORY,
        element: <ProtectedRoute component={<EventCategory />}/>,
        showInNavigation: false,
    },
    {
        name: "Create Event Location",
        path: SIDEBAR.EVENTS_LOCATION,
        element: <ProtectedRoute component={<EventLocation />}/>,
        showInNavigation: false,
    },
    {
        name: "Create Event Tags",
        path: SIDEBAR.EVENTS_TAG,
        element: <ProtectedRoute component={<EventTag />}/>,
        showInNavigation: false,
    },
    {
        name: "Create Event Hosts",
        path: SIDEBAR.EVENTS_HOST,
        element: <ProtectedRoute component={<EventHosts />}/>,
        showInNavigation: false,
    },
    //  ========== USER ==========
    {
        name: "View All Users",
        path: SIDEBAR.VIEW_USERS,
        element: <ProtectedRoute component={<ViewAllUsers />}/>,
        showInNavigation: false,
    },
    {
        name: "View Users Details",
        path: USERS.VIEW_USER_DETAILS,
        element: <ProtectedRoute component={<ViewUserDetails />}/>,
        showInNavigation: false,
    },
    {
        name: "Edit Users Details",
        path: USERS.EDIT_USER_DETAILS,
        element: <ProtectedRoute component={<EditUserDetails />}/>,
        showInNavigation: false,
    },
    {
        name: "Deleted Users Details",
        path: USERS.DELETED_USERS,
        element: <ProtectedRoute component={<DeletedUsers />}/>,
        showInNavigation: false,
    },
    {
        name: "Deleted Users Details",
        path: USERS.CREATE_USERS,
        element: <ProtectedRoute component={<CreateUser />}/>,
        showInNavigation: false,
    },
    
    //  ========== PLAN AND LIMITATION ==========
    {
        name: "View and Edit Plans",
        path: SIDEBAR.PLANS,
        element: <ProtectedRoute component={<Plans/>}/>,
        showInNavigation: false,
    },
    {
        name: "View and Edit Plan Category",
        path: SIDEBAR.PLAN_CATEGORY,
        element: <ProtectedRoute component={<PlanCategory/>}/>,
        showInNavigation: false,
    },
    {
        name: "View and Edit Plan Coins",
        path: SIDEBAR.PLAN_COIN,
        element: <ProtectedRoute component={<PlanCoins />}/>,
        showInNavigation: false,
    },
    {
        name: "View and Edit Plan Limitations",
        path: SIDEBAR.PLAN_LIMITATION,
        element: <ProtectedRoute component={<PlanLimitation />}/>,
        showInNavigation: false,
    },    
    //  ========== COUPONS MANAGEMENT ==========
    {
        name: "View and Edit Coupons",
        path: SIDEBAR.COUPON,
        element: <ProtectedRoute component={<Coupons />}/>,
        showInNavigation: false,
    },    
    //  ========== Notification MANAGEMENT ==========
    {
        name: "Send Notification",
        path: SIDEBAR.NOTIFICATION,
        element: <ProtectedRoute component={<Notification />}/>,
        showInNavigation: false,
    },    
    //  ========== Notification MANAGEMENT ==========
    {
        name: "User Account Verification",
        path: SIDEBAR.VERIFICATION,
        element: <ProtectedRoute component={<Verification />}/>,
        showInNavigation: false,
    },    
    //  ========== AppUpdate MANAGEMENT ==========
    {
        name: "AppUpdate version update",
        path: SIDEBAR.APP_UPDATE,
        element: <ProtectedRoute component={<AppUpdate />}/>,
        showInNavigation: false,
    },
    //  ========== AFFILIATE REFERRAL MANAGEMENT ==========  
    {
        name: "View  and edit affliate coupons",
        path: SIDEBAR.AFFILIATE_COUPONS,
        element: <ProtectedRoute component={<AffiliateCoupons />}/>,
        showInNavigation: false,
    }, 
    {
        name: "View and track affliate coupons",
        path: AFFILIATE_COUPONS.TRACK_AFFILIATE_COUPON,
        element: <ProtectedRoute component={<TrackAffiliateCoupons />}/>,
        showInNavigation: false,
    },
    {
        name: "Error404",
        path: "*",
        element: <Error404 />,
        showInNavigation: false,
    },
];